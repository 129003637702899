import React from 'react'
import './style.scss'
import { useBonus } from '../../../hooks/useBonus'
import { BONUS_DROPDOWN_OPTIONS, GAME_OPTION_TABS } from '../../../constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { useGames } from '../../../hooks/useGame'
import { useSelector } from 'react-redux'

const BonusDetails = ({closeBonusDropdown}) => {
    const {setBonusObject} = useBonus()
    const {setGameObject } = useGames()
    const navigate = useNavigate()
  const [query, setQuery] = useSearchParams()
  const userBalance = useSelector(state => state.userBalance);



    const handleOpenSpinGames = ()=> {
        setBonusObject(prev=>({
            ...prev,
            selectedTab : BONUS_DROPDOWN_OPTIONS.FREE_SPIN_GAMES
        }))
    }
    return (
        <>
            <div className="bonusDropDownCloseLayer" onClick={closeBonusDropdown}></div>

            <div className="bonusDetailWrapper bonusDropModal h-100">
                <div className="row mx-0 h-100 bonusDropModalInner flex-nowrap flex-column">
                    <div className="col-12 bonusDropModalHeader">
                        <div className="row mx-0 pb-2">     
                            <div className="col px-0"><div className="heading">Bonus Details</div></div>
                            <div className="col-auto pe-0">
                                <div className="close cursor-pointer" onClick={closeBonusDropdown}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                    <path d="M9.22987 1.13297C9.48903 0.873792 9.48903 0.45357 9.22987 0.19439C8.9707 -0.0647967 8.55046 -0.0647967 8.29129 0.19439L4.71213 3.77356L1.13297 0.19439C0.873792 -0.0647967 0.45357 -0.0647967 0.19439 0.19439C-0.0647967 0.45357 -0.0647967 0.873792 0.19439 1.13297L3.77356 4.71213L0.19439 8.29129C-0.0647967 8.55046 -0.0647967 8.9707 0.19439 9.22987C0.45357 9.48903 0.873792 9.48903 1.13297 9.22987L4.71213 5.65071L8.29129 9.22987C8.55046 9.48903 8.9707 9.48903 9.22987 9.22987C9.48903 8.9707 9.48903 8.55046 9.22987 8.29129L5.65071 4.71213L9.22987 1.13297Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-12 px-0"><div className="subHeading">Special Bonus <span>100 Free Spins</span></div></div>
                        </div>
                    </div>
                    <div className="col flex-fill px-0 bonusDropModalBody">
                        <div className="row mx-0 px-2">
                            <div className="col-12 px-0">
                                <div className="textHead mt-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M4.72 6.48L3.6 7.6L7.2 11.2L15.2 3.2L14.08 2.08L7.2 8.96L4.72 6.48ZM14.4 8C14.4 11.52 11.52 14.4 8 14.4C4.48 14.4 1.6 11.52 1.6 8C1.6 4.48 4.48 1.6 8 1.6C8.64 1.6 9.2 1.68 9.76 1.84L11.04 0.56C10.08 0.24 9.04 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8H14.4Z" fill="#B2B6C5"/>
                                    </svg>Activation
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="textWrapper">
                                    <div className="row borderBottom">
                                        <div className="col"><div className="dollarTxt">Min. Deposit</div></div>
                                        <div className="col-auto"><div className="dollar">{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} 40</div></div>
                                        <div className="col-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                    <div className="row borderBottom">
                                        <div className="col"><div className="dollarTxt">Max. Bonus</div></div>
                                        <div className="col-auto"><div className="dollar">{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} 400</div></div>
                                        <div className="col-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                    <div className="row borderBottom">
                                        <div className="col"><div className="dollarTxt">Max. Claim</div></div>
                                        <div className="col-auto"><div className="dollar smaillDoller">x1.5 frome the <br/>primer bonus</div></div>
                                        <div className="col-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                    <div className="row borderBottom mb-2">
                                        <div className="col"><div className="dollarTxt">Currency</div></div>
                                        <div className="col-auto"><div className="dollar text-uppercase">{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} </div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="textHead mt-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                    <path d="M18.2118 2.34281C15.5702 -1.99724 11.5908 1.02219 11.5908 1.02219C11.3327 1.21724 10.8568 1.37761 10.5335 1.37836L9.46758 1.3791C9.14423 1.37948 8.66834 1.21911 8.41063 1.02405C8.41063 1.02405 4.43084 -1.99612 1.78885 2.34393C-0.852408 6.6825 0.212 11.4074 0.212 11.4074C0.398476 12.5643 1.0131 13.3364 2.17299 13.2394C3.32951 13.1428 5.83986 10.1237 5.83986 10.1237C6.04722 9.87497 6.48171 9.67171 6.80432 9.67171L13.1945 9.6706C13.5175 9.6706 13.9516 9.87386 14.1586 10.1226C14.1586 10.1226 16.6693 13.1417 17.8277 13.2383C18.9861 13.3352 19.6022 12.5625 19.7876 11.4063C19.7876 11.4063 20.8535 6.68212 18.2118 2.34281ZM7.59871 5.87543H6.22848V7.19605C6.22848 7.19605 5.93832 7.41759 5.48929 7.41199C5.041 7.40528 4.83811 7.17032 4.83811 7.17032V5.8758H3.54396C3.54396 5.8758 3.38247 5.71021 3.33921 5.27423C3.29669 4.83824 3.51786 4.4858 3.51786 4.4858H4.88846V3.11557C4.88846 3.11557 5.17041 2.96453 5.57581 2.97609C5.98158 2.98877 6.27883 3.14131 6.27883 3.14131L6.27323 4.48543H7.56738C7.56738 4.48543 7.79339 4.77559 7.81204 5.12616C7.83068 5.47711 7.59871 5.87543 7.59871 5.87543ZM13.8837 7.38066C13.2836 7.38066 12.7999 6.89545 12.7999 6.29612C12.7999 5.69566 13.2836 5.21157 13.8837 5.21157C14.4812 5.21157 14.9686 5.69566 14.9686 6.29612C14.9686 6.89583 14.4812 7.38066 13.8837 7.38066ZM13.8837 4.39667C13.2836 4.39667 12.7999 3.91183 12.7999 3.31249C12.7999 2.71204 13.2836 2.22794 13.8837 2.22794C14.4812 2.22794 14.9686 2.71204 14.9686 3.31249C14.9686 3.9122 14.4812 4.39667 13.8837 4.39667ZM16.5846 5.9985C15.9846 5.9985 15.5008 5.51366 15.5008 4.91395C15.5008 4.31387 15.9846 3.82941 16.5846 3.82941C17.1821 3.82941 17.6696 4.31387 17.6696 4.91395C17.6696 5.51366 17.1821 5.9985 16.5846 5.9985Z" fill="#B2B6C5"/>
                                    </svg>Games
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="textWrapper">
                                    <div className="row borderBottom">
                                        <div className="col"><div className="dollarTxt">Free Spin Games</div></div>
                                        <div className="col-auto"><div onClick={handleOpenSpinGames} className="dollar yellow cursor-pointer">Check list</div></div>
                                        <div className="col-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                    <div className="row borderBottom">
                                        <div className="col"><div className="dollarTxt">Bet for one Free Spin</div></div>
                                        <div className="col-auto"><div className="dollar">{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} 0.2</div></div>
                                        <div className="col-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="textHead mt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path d="M14.1563 6.00183H7.48299C6.46631 6.00183 5.63965 6.83183 5.63965 7.84849V14.5185C5.63965 15.5352 6.46631 16.3652 7.48299 16.3652H14.1563C15.173 16.3652 15.9997 15.5352 15.9997 14.5185V7.84849C15.9997 6.83183 15.173 6.00183 14.1563 6.00183ZM8.85632 10.4285C8.18632 10.4285 7.64298 9.88515 7.64298 9.21849C7.64298 8.5485 8.18632 8.00517 8.85632 8.00517C9.52299 8.00517 10.0663 8.5485 10.0663 9.21849C10.0663 9.88515 9.52299 10.4285 8.85632 10.4285ZM12.783 14.3585C12.1163 14.3585 11.573 13.8152 11.573 13.1485C11.573 12.4785 12.1163 11.9352 12.783 11.9352C13.453 11.9352 13.993 12.4785 13.993 13.1485C13.993 13.8152 13.453 14.3585 12.783 14.3585Z" fill="#B2B6C5"/>
                                    <path d="M7.48334 5.3352H10.36V2.33187C10.36 1.31521 9.53332 0.488525 8.51666 0.488525H1.84332C0.82666 0.488525 0 1.31521 0 2.33187V9.00521C0 10.0219 0.82666 10.8485 1.84332 10.8485H4.97333V7.84853C4.97333 6.46187 6.1 5.3352 7.48334 5.3352ZM7.14667 2.49186C7.81333 2.49186 8.35667 3.0352 8.35667 3.70186C8.35667 4.37187 7.81333 4.91186 7.14667 4.91186C6.47666 4.91186 5.93333 4.37187 5.93333 3.70186C5.93333 3.0352 6.47666 2.49186 7.14667 2.49186ZM3.21665 8.84188C2.54667 8.84188 2.00334 8.30186 2.00334 7.63188C2.00334 6.96187 2.54667 6.42187 3.21665 6.42187C3.88332 6.42187 4.42666 6.96187 4.42666 7.63188C4.42666 8.30186 3.88332 8.84188 3.21665 8.84188ZM3.21665 4.91186C2.54667 4.91186 2.00334 4.37187 2.00334 3.70186C2.00334 3.0352 2.54667 2.49186 3.21665 2.49186C3.88332 2.49186 4.42666 3.0352 4.42666 3.70186C4.42666 4.37187 3.88332 4.91186 3.21665 4.91186Z" fill="#B2B6C5"/>
                                    </svg>Wagering
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="textWrapper">
                                    <div className="row borderBottom">
                                        <div className="col"><div className="dollarTxt">wager</div></div>
                                        <div className="col-auto"><div className="dollar">x40</div></div>
                                        <div className="col-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                    <div className="row borderBottom">
                                        <div className="col"><div className="dollarTxt">Min.bet for wager</div></div>
                                        <div className="col-auto"><div className="dollar">{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} 0.1</div></div>
                                        <div className="col-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                    <div className="row borderBottom mb-2">
                                        <div className="col"><div className="dollarTxt">Max.bet for wager</div></div>
                                        <div className="col-auto"><div className="dollar">{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} 10</div></div>
                                        <div className="col-12">
                                            <div className="divider"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 bonusDropModalFooter d-flex justify-content-center">
                        <button className='GoBtn'
                            onClick={() => {
                                closeBonusDropdown()
                                setGameObject((prev) => ({
                                    selectedTab: GAME_OPTION_TABS?.FREE_SPIN
                                }))
                                // navigate(ROUTES_CONST.GAME_PAGE)
                                setQuery({ q: "freeSpin" })
                                navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('freeSpin')}`)
                            }}>Go to all games 
                            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                <path d="M0.447007 6.29212C0.358734 6.29264 0.272308 6.26657 0.198747 6.21724C0.125186 6.1679 0.067824 6.09754 0.0339738 6.01512C0.000123601 5.93269 -0.00868107 5.84195 0.00868236 5.75445C0.0260458 5.66694 0.0687906 5.58665 0.131467 5.52381L2.4869 3.14698L0.131467 0.770152C0.0477807 0.685546 0.000766196 0.570795 0.000766196 0.451144C0.000766196 0.391899 0.0123085 0.333234 0.0347342 0.278499C0.0571598 0.223764 0.0900298 0.17403 0.131467 0.132137C0.172904 0.0902445 0.222097 0.0570136 0.276238 0.0343415C0.330378 0.0116693 0.388406 0 0.447007 0C0.565357 0 0.67886 0.0475312 0.762547 0.132137L3.42908 2.82797C3.51185 2.91216 3.55831 3.02603 3.55831 3.14473C3.55831 3.26343 3.51185 3.37731 3.42908 3.46149L0.762547 6.15733C0.721387 6.19978 0.672302 6.23356 0.618138 6.25669C0.563974 6.27983 0.505807 6.29187 0.447007 6.29212Z" fill="black"/>
                                <path d="M3.44701 6.29212C3.35873 6.29264 3.27231 6.26657 3.19875 6.21724C3.12519 6.1679 3.06782 6.09754 3.03397 6.01512C3.00012 5.93269 2.99132 5.84195 3.00868 5.75445C3.02605 5.66694 3.06879 5.58665 3.13147 5.52381L5.4869 3.14698L3.13147 0.770152C3.04778 0.685546 3.00077 0.570795 3.00077 0.451144C3.00077 0.391899 3.01231 0.333234 3.03473 0.278499C3.05716 0.223764 3.09003 0.17403 3.13147 0.132137C3.1729 0.0902445 3.2221 0.0570136 3.27624 0.0343415C3.33038 0.0116693 3.38841 0 3.44701 0C3.56536 0 3.67886 0.0475312 3.76255 0.132137L6.42908 2.82797C6.51185 2.91216 6.55831 3.02603 6.55831 3.14473C6.55831 3.26343 6.51185 3.37731 6.42908 3.46149L3.76255 6.15733C3.72139 6.19978 3.6723 6.23356 3.61814 6.25669C3.56397 6.27983 3.50581 6.29187 3.44701 6.29212Z" fill="black"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default BonusDetails