import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../../../../hooks/useAuth";
import { useAuthModal } from "../../../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../config/firebase";
import { errorToaster, succesToaster } from "../../../../utils/toaster";
import { AUTH_MODAL_TABS, USER_LANG } from "../../../../constants";
import { postAPI } from "../../../../service/apiInstance";
import { useSearchParams } from "react-router-dom";
import { t } from "i18next";
import ErrorMessage from "../../../common/ErrorMessage/ErrorMessage";
import { getCustomAuthError } from "../../../../utils/customFirebaseError";

const OtpSignIn = ({
    select,
    number,
    setIsOTP,
    browserName
}) => {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef([]);
    const { setToken } = useAuth();
    const { authModalObject, setAuthModalObject } = useAuthModal();
    const ipAddress = useSelector((state) => state.ipAddress);
    const country = useSelector((state) => state.country);
    const country_id = localStorage.getItem("country_Id");
    const [isLoading, setIsLoading] = useState(false)
    const [isMetamask, setIsMetamask] = useState(false);
    const [isTelegram, setIsTelegram] = useState(false);
    const [userObj, setUserObj] = useState({});
    const [otpError, setOtpError] = useState(false)
    const [timer, setTimer] = useState(60);
    const [resendLoading, setResendLoading] = useState(false);
    const [reset, setReset] = useState(false);
    const [otpLoader, setOtpLoader] = useState(false);
    const [query, setQuery] = useSearchParams()
    const [isResetPassword, setIsResetPassword] = useState(false)
  const [isPasswordHidden, setIsPasswordHidden] = useState(false)
  const authLabels = t("AuthLabels", { returnObjects: true })

  const [resetPassword, setResetPassword] = useState({
    newPassword : '',
    confirmNewPassword : ''
  })

  const [resetPasswordError, setResetPasswordError] = useState({
    newPassword : '',
    confirmNewPassword : '',
    matchPassword: ''
  })


  const handleUpdatePassword = async ()=> {
    if(resetPassword?.newPassword?.length < 8) {
        setResetPasswordError(prev=>({
            ...prev,
            newPassword: 'Password min length should be 8'
        }))
        return;
    }
    if(resetPassword?.confirmNewPassword !== resetPassword?.newPassword) {
        setResetPasswordError(prev=>({
            ...prev,
            confirmNewPassword: 'Password should match'
        }))
        return;
    } else {
        setResetPasswordError(prev=>({
            newPassword: '',
            confirmNewPassword: ''
        }))
        const body = {
            phone : `${select?.phoneCode ? select.phoneCode.substring(1) : "254"}${number}`,
            new_password: resetPassword?.newPassword
        }
        try {
            setIsLoading(true)
            const res = await postAPI('user/forget-password', body)
            console.log('forget body', res)
            if(res?.data?.success) {
                succesToaster(res?.data?.message)
                setResetPassword(prev=>({
                    newPassword: '',
                    confirmNewPassword: ''
                }))
                setAuthModalObject(pre => ({
                    ...pre,
                    selectedTab: AUTH_MODAL_TABS.LOG_IN,
                }))
                setQuery({ action: "login" })
            } else {
                errorToaster(res?.data?.message)
            }
        } catch (error) {
            
        } finally {
            setIsLoading(false)
        }
    }
  }



    const generateRecaptcha = (random) => {
        const recaptchaParent = document.getElementById("recaptcha-parent");
        if (recaptchaParent) {
            recaptchaParent.innerHTML = `<span id=${random} />`
        }
        window.recaptchaVerifier = new RecaptchaVerifier(
            `${random}`,
            {
                size: "invisible",
                callback: (response) => { },
            },
            auth
        );
        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });
    };

    const handleSend = (values) => {
        setResendLoading(true)
        const random = Math.random() + 1000000
        generateRecaptcha(random);
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(
            auth,
            `${select?.phoneCode ? select.phoneCode : "+254"} ${number}`,
            appVerifier
        )
            .then((confirmationResult) => {
                succesToaster("OTP SENT!");
                window.confirmationResult = confirmationResult;
                // setLogOn(false);
                // setPhoneOtp(true);
                setTimer(60)
            })
            .catch((error) => {
                // errorToaster(error.message.split('(')[1].split(')')[0].split('/')[1]);
                errorToaster(getCustomAuthError(error));
                // errorToaster(error.message);
                const recaptchaContainer = document.getElementById(`${random}`)
                if (recaptchaContainer) { recaptchaContainer.remove(); }

                // errorToaster(getFriendlyErrorMessage(error.code))
                // errorToaster(error.message)

            })
            .finally(() => {
                setResendLoading(false);
                // setReset(true)
            });
    };

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    useEffect(() => {
        inputRefs?.current?.[0]?.focus()
    }, [])

    const handleOtpChange = (e, index) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = e.target.value;
        setOtp(updatedOtp);

        if (e.target.value !== "" && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && index > 0 && event.target.value === "") {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleVerifyOtp = (event) => {
        if (timer === 0 || !timer) {
            errorToaster('OTP Expired')
            return
        }
        const otpValue = otp.join("");

        if (otpValue.length === 6) {
            setOtpError(false)
            setIsLoading(true)
            let confirmationResult = window.confirmationResult;
            confirmationResult
                ?.confirm(otpValue)
                .then((result) => {
                    // const lang = JSON.parse(localStorage.getItem(USER_LANG));
                    const lang = localStorage.getItem(USER_LANG)
                    const body = {
                        phone: `${select?.phoneCode ? select.phoneCode.substring(1) : "254"}${number}`,
                        ipAddress: ipAddress ? ipAddress : "",
                        country: country ? country : "",
                        // language: lang?.symbol,
                        language: lang,
                        phoneCodeId: country_id,
                        browser: browserName

                    };
                    setIsResetPassword(true)
                //     postAPI("user/login-user", body)
                //         .then((res) => {
                //             if (res?.data?.success) {
                //                 succesToaster(res.data.message);
                //                 setToken(res?.data.data?.auth_token);
                //                 // setLogOn(true);
                //                 // setPhoneOtp(false);
                //                 setOtpLoader(false);
                //                 setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
                //                 setQuery({ action: "" })
                // localStorage.removeItem('clickid')
                //             } else {
                //                 errorToaster(res.data.message);
                //                 setOtpLoader(false);
                //             }
                //         })
                //         .catch((err) => {
                //             errorToaster("something went wrong");
                //             setOtpLoader(false);
                //         });
                    setOtp(["", "", "", "", "", ""]);
                    setIsLoading(false);
                })
                .catch((error) => {
                    // setLogOn(true);
                    // setPhoneOtp(false);
                    setOtp(["", "", "", "", "", ""]);
                    // errorToaster(error.message.split('(')[1].split(')')[0].split('/')[1]);
                    // errorToaster(error.message);
                    errorToaster(getCustomAuthError(error));
                    setIsLoading(false);
                })

        } else {
            setOtpError(true)
        }

    };
    return (
        <>
            {
                !isResetPassword ? <>
                    <form>
                        <div className="col-12 mt-2">
                            <div className="inpWrapper position-relative">
                                <div className='d-flex justify-content-between w-100'>
                                    {
                                        otp.map((digit, index) => (
                                            <input
                                                className='otpField mx-1 px-1'
                                                 type="tel"
                                                inputmode="numeric" 
                                                pattern="[0-9]*"
                                                name=""
                                                id=""
                                                key={index}
                                                maxLength={1}
                                                value={digit}
                                                onChange={(e) => handleOtpChange(e, index)}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        const otpValue = otp.join("");
                                                        if (otpValue.length !== 6) {
                                                            errorToaster("OTP Field can't be empty")
                                                            return
                                                        }
                                                        setIsLoading(true)
                                                        handleVerifyOtp(event)
                                                    }
                                                    handleKeyDown(event, index)
                                                }}
                                                ref={(ref) => (inputRefs.current[index] = ref)}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <button
                                className='emailBtn active'
                                disabled={isLoading}
                                type="button"
                                onClick={(e) => {
                                    handleVerifyOtp(e)
                                }}
                            > {isLoading ? <Spinner size="sm" color="dark" /> : 'Verify OTP'}
                            </button>
                        </div>
                        {
                            timer ? (
                                <div className="cursor-pointer text-light py-2">
                                    Didn't received OTP, resend in {timer}s
                                </div>
                            ) : (
                                <button
                                    className="playBtn border-0 position-relative d-flex align-items-center justify-content-start cursor-pointer"
                                    style={{ width: '150px' }}
                                    onClick={handleSend}
                                    type="button"
                                >
                                    <span className="playBtnInner d-flex align-items-center justify-content-center px-4 w-100">
                                        {
                                            resendLoading ? <Spinner size="sm" color="dark" /> : "Resend OTP"
                                        }
                                    </span>
                                </button>
                            )
                        }
                        <div id="recaptcha-parent" />
                        {/* <div className="col-12 mt-3">
                Didn't 
                </div> */}
                    </form>
                </> : <>
                
                <form>
                <div className="col-12 mt-2">
                        <div className="inpWrapper position-relative">
                            <input
                                className='inp'
                                type={`${isPasswordHidden ? "password" : "text"}`}
                                placeholder='Enter New Password'
                                name="new password"
                                value={resetPassword.newPassword}
                                onChange={(e)=> setResetPassword(prev=>({
                                    ...prev,
                                    newPassword: e.target.value
                                }))}
                            />
                            {
                                isPasswordHidden ? (
                                    <span
                                        onClick={() => setIsPasswordHidden(false)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34444 4.41401C4.34444 2.6747 5.75918 1.25937 7.50037 1.25937C9.23972 1.25937 10.6544 2.67466 10.6544 4.41401C10.6544 6.15372 9.23972 7.56843 7.50037 7.56843C5.75915 7.56843 4.34444 6.15372 4.34444 4.41401ZM0.507401 4.11304C0.398575 4.30988 0.398575 4.51813 0.507401 4.71515C1.92027 7.25228 4.5996 8.82838 7.50037 8.82838C10.3992 8.82838 13.0786 7.25231 14.4915 4.71515C14.6022 4.51813 14.6022 4.30988 14.4915 4.11304C13.0786 1.57592 10.3993 0 7.50037 0C4.5996 0 1.92027 1.57592 0.507401 4.11304ZM7.50037 2.29322C6.32957 2.29322 5.37826 3.2445 5.37826 4.41401C5.37826 5.58349 6.32954 6.53479 7.50037 6.53479C8.6693 6.53479 9.62058 5.58352 9.62058 4.41401C9.62058 3.2445 8.6693 2.29322 7.50037 2.29322Z" fill="#B2B6C5" />
                                        </svg>
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => setIsPasswordHidden(true)}
                                    >
                                        <svg stroke="currentColor" fill="#B2B6C5" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448zM248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69 64.11 64.11 0 0 0 53.49 53.49 2 2 0 0 0 1.69-3.39zm16-119.7L315.87 248a2 2 0 0 0 3.4-1.69 64.13 64.13 0 0 0-53.55-53.55 2 2 0 0 0-1.72 3.39z"></path><path d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 0 0-71.82 11.79 4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05 96 96 0 0 1 116 116 4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24 343.81 343.81 0 0 0 67.24-77.4zM256 352a96 96 0 0 1-93.3-118.63 4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0 0 72.64-11.55 4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352z"></path></svg>
                                    </span>
                                )
                            }
                        </div>
                        <ErrorMessage>
                            {resetPasswordError?.newPassword}
                        </ErrorMessage>
                    </div>
                <div className="col-12 mt-2">
                        <div className="inpWrapper position-relative">
                            <input
                                className='inp'
                                type={`${isPasswordHidden ? "password" : "text"}`}
                                placeholder='Confirm Password'
                                name="confirm password"
                                value={resetPassword.confirmNewPassword}
                                onChange={(e)=> setResetPassword(prev=>({
                                    ...prev,
                                    confirmNewPassword: e.target.value
                                }))}
                            />
                            {
                                isPasswordHidden ? (
                                    <span
                                        onClick={() => setIsPasswordHidden(false)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34444 4.41401C4.34444 2.6747 5.75918 1.25937 7.50037 1.25937C9.23972 1.25937 10.6544 2.67466 10.6544 4.41401C10.6544 6.15372 9.23972 7.56843 7.50037 7.56843C5.75915 7.56843 4.34444 6.15372 4.34444 4.41401ZM0.507401 4.11304C0.398575 4.30988 0.398575 4.51813 0.507401 4.71515C1.92027 7.25228 4.5996 8.82838 7.50037 8.82838C10.3992 8.82838 13.0786 7.25231 14.4915 4.71515C14.6022 4.51813 14.6022 4.30988 14.4915 4.11304C13.0786 1.57592 10.3993 0 7.50037 0C4.5996 0 1.92027 1.57592 0.507401 4.11304ZM7.50037 2.29322C6.32957 2.29322 5.37826 3.2445 5.37826 4.41401C5.37826 5.58349 6.32954 6.53479 7.50037 6.53479C8.6693 6.53479 9.62058 5.58352 9.62058 4.41401C9.62058 3.2445 8.6693 2.29322 7.50037 2.29322Z" fill="#B2B6C5" />
                                        </svg>
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => setIsPasswordHidden(true)}
                                    >
                                        <svg stroke="currentColor" fill="#B2B6C5" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448zM248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69 64.11 64.11 0 0 0 53.49 53.49 2 2 0 0 0 1.69-3.39zm16-119.7L315.87 248a2 2 0 0 0 3.4-1.69 64.13 64.13 0 0 0-53.55-53.55 2 2 0 0 0-1.72 3.39z"></path><path d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 0 0-71.82 11.79 4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05 96 96 0 0 1 116 116 4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24 343.81 343.81 0 0 0 67.24-77.4zM256 352a96 96 0 0 1-93.3-118.63 4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0 0 72.64-11.55 4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352z"></path></svg>
                                    </span>
                                )
                            }
                        </div>
                        <ErrorMessage>
                            {resetPasswordError?.confirmNewPassword}
                        </ErrorMessage>
                    </div>
                    <div className="col-12 mt-3">
                    <button
                      className='emailBtn active'
                      type='button'
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault()
                        handleUpdatePassword()
                      }}
                    >
                      {isLoading ? <Spinner size="sm" color="dark" /> : 'Update Password'}
                      
                    </button>
                  </div>
                </form>
                </> 
            }
        </>
    );
};

export default OtpSignIn;
