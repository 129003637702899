import React, { useEffect, useRef, useState } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import { useLocation, useParams } from "react-router-dom";
import { getAPIAuth, postAPIAuth } from "../../service/apiInstance";
import { useAuth } from "../../hooks/useAuth";
import { errorToaster } from "../../utils/toaster";
import PlayingGame from "./components/PlayingGame";
// import BetLeaderBoard from "./components/BetLeaderBoard";
// import Sharing from "./components/Sharing";
import BetLeaderBoard from "./components/BetLeaderBoard";
import { useSelector } from "react-redux";
import RelatedGames from "./components/RelatedGames";
import MoreFrom from "./components/MoreFrom";
import ProvidersListComp from "./components/ProvidersListComp";
import { MOBILE_SCREEN_WIDTH, SM_SCREEN_WIDTH } from "../../constants";
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from "react-i18next";
import ClaimBonusModal from './../../components/bonusDropdown/claimBonusModal/ClaimBonusModal'

const PlaySlot = () => {
  const userDetails = useSelector(state => state.userDetails)
  const { gameId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [game, setGame] = useState({})
  const [isOpen, setIsOpen] = useState(true)
  const [data, setData] = useState()
  const { token } = useAuth()
  const location = useLocation()
  const isMobile = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })
  const [isValid, setIsValid] = useState(true)
  const intervalRef = useRef(null)
  const [demo, setDemo] = useState(false)
  const userBonusDetail = useSelector(state => state.userBonusDetail)
  const userBalance = useSelector(state => state.userBalance)
  const [showError, setShowError] = useState({
    show: false,
    msg: ''
  })

  const checkSelfValid = async () => {
    try {
      const res = await getAPIAuth('self-validate', token)
      if (res?.data?.success) {
        setIsValid(res?.data?.success?.data?.success)
      }
    } catch (error) {

    }
  }

  // to show updated user balance 
  // useEffect(() => {
  //   checkSelfValid()

  //   let interval = ''
  //   if (userDetails?.id && token) {
  //     console.log('userDetails', userDetails)
  //     // polling

  //     if (intervalRef.current) {//if previously any interval is running ,cancel it 
  //       clearTimeout(intervalRef.current)
  //     }
  //     // start a new interval
  //     interval = setInterval(() => checkSelfValid(), 60000)
  //   }
  //   return () => clearTimeout(interval)// clean up , when component unmounts

  // }, [token, userDetails?.id, location.pathname])


  // Function for generating url
  const generateUrl = async () => {
    setIsLoading(true)
    try {
      if (token) {
        const resp = await getAPIAuth(`games/url-generate?game_uuid=${gameId}&type=${demo ? "demo" : "real"}`, token)
        if (resp?.data.success) {
          // handleNewLink(resp.data.data.url)
          setShowError(prev=>({
            ...prev,
            show: false,
            msg: ''
          }))
          if (location.search?.includes('freeSpinGame')) {
            setTimeout(() => {
              setGame(resp?.data?.data)
            }, 2000)
          } else {
            setGame(resp?.data?.data)
          }
        } else {
          // errorToaster("Something went wrong while playing game")
          setGame(false)
          setShowError(prev=>({
            ...prev,
            show: true,
            msg: resp?.data?.message
          }))
        }
      }
    } catch (error) {
      errorToaster("Something went wrong while playing game")
    } finally {
      setIsLoading(false)
    }
  }

  // Function for getting gamedata
  const getGameData = async () => {
    let params = {
      userId: userDetails?.id,
      gameUuid: gameId,
      is_mobile: isMobile ? "true" : "false"
    }
    const result = await postAPIAuth(`crypto/get-games`, params)
    if (result.data.success) {
      setData(result.data.data[0])
    }
  }

  useEffect(() => {
    if (token && userDetails?.id) {
      getGameData()
      // generateUrl()
    }
  }, [location?.pathname, token, userDetails?.id])

  useEffect(() => {
    if (token && userDetails?.id) {
      // getGameData()
      generateUrl()
    }
  }, [location?.pathname, token, userDetails?.id, demo, userBalance.setCurrencyData?.code, userDetails?.fiatStatus])


  return (
    <main className="main vh-100" id="main">
      <div className="mainContaint">
        <div className=" holdwinSection homepage">
          <div className="gamesPage pt-3 pt-lg-4">

            <PlayingGame getGameData={getGameData} demo={demo} setDemo={setDemo} data={data} isLoading={isLoading} game={game} setIsOpen={setIsOpen} showError={showError}/>

            {
              !isMobile ? <>
                <BetLeaderBoard />

                <RelatedGames data={data} />

                <MoreFrom data={data} />

                <ProvidersListComp data={data} />
              </> : ''
            }
            {
              location.search?.includes('freeSpinGame') && userBonusDetail?.freeSpinPopupStatus && userBonusDetail?.lockedStatus?.freeSpinStatus === "claim" ? <ClaimBonusModal /> : ''
            }

          </div>
        </div>
      </div>
      <AppFooter />
    </main>

  )
}
export default PlaySlot;
