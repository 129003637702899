import React, { useEffect, useState } from "react";
import { getAPIAuth, postAPIAuth } from "../../../service/apiInstance";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Fiat from "../../../components/newWallet/components/Fiat";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { useAuth } from "../../../hooks/useAuth";

function Deposits({ tabopen, openaccordian }) {
  const [cryptoList, setCryptoList] = useState([])
  const [activeCrypto, setActiveCrypto] = useState('ALL')
  const [deposits, setDeposits] = useState([])
  const [transactionId, setTransactionId] = useState('')
  const [loader, setLoader] = useState(false)
  const [listLoader, setListLoader] = useState(true)
  const [showTransaction, setShowTransaction] = useState(false)
  const userDetails = useSelector((state) => state.userDetails);
  const userId = userDetails?.id;
  const userBalance = userDetails?.totalbalance;
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const [pagination, setPagination] = useState({
    btnOne: 1,
    btnTwo: 2,
    btnThree: 3,
    btnFour: "...",
    btnFive: 0
  })
  const [pageNo, setPageNo] = useState(1)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [totalPages, setTotalPage] = useState()  
  const [amount, setAmount] = useState('')
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false)
  const [withdrawError, setWithdrawError] = useState('')
  const {token} = useAuth()

  const handleWithdraw = async ()=> {
    if(!amount?.length) {
      setWithdrawError('Enter valid deposit amount')
      return
    } else {
      setWithdrawError('')
        try {
          setIsWithdrawLoading(true)
          const payload = {
            amount : Number(amount)
          }
          const res = await postAPIAuth('paymentProcessRequest', payload)
          if(res?.data?.success) {
            if(res?.data?.data?.ResponseDescription) {
              succesToaster(res?.data?.data?.ResponseDescription)
            } else if (res?.data?.data?.errorMessage) {
              errorToaster(res?.data?.data?.errorMessage)
              setWithdrawError(res?.data?.data?.errorMessage)
            }
          }
        } catch (error) {
          
        } finally {
          setIsWithdrawLoading(false)
          setAmount('')
        }
    }
  }

  const paginationHandler = (count) => {
    if (pagination.btnOne != pageNo) {
      if (pageNo - 1 == pagination.btnThree) {
        setPagination(
          prev => ({
            ...prev,
            btnOne: pagination.btnOne + 1,
            btnTwo: pagination.btnTwo + 1,
            btnThree: pagination.btnThree + 1
          })
        )
      } else if (pageNo + 1 == pagination.btnOne) {
        setPagination(
          prev => ({ ...prev, btnOne: pagination.btnOne - 1, btnTwo: pagination.btnTwo - 1, btnThree: pagination.btnThree - 1 })
        )
      } else if (pageNo == totalPages) {
        setPagination(
          prev => ({ ...prev, btnOne: totalPages - 2, btnTwo: totalPages - 1, btnThree: totalPages })
        )
      }
    }
  }


  const loadMore = async () => {
    try {
      const res = await getAPIAuth(`user/deposit-transaction-details?userId=${userId}&transactionId=${transactionId}&chain=${activeCrypto == 'ALL' ? "" : activeCrypto}&page=${pageNo}`, token)

      // const res = await getAPIAuth(`user/userTransactions/${"64be2d4fcb1b31b159cc6378"}`)
      if (res.data.success) {
        // setTransactionData(res.data.data)
        setDeposits(res?.data?.data)
        setTotalPage(res.data.pagination?.[0]?.totalPages)
      }
    } catch (error) {
      //error handling
    }

  }

  useEffect(() => {
    if (userDetails?.id && (openaccordian === "Deposits" || tabopen === "Deposits")) {
      if (pageNo >= 1 && isLoadMore) {
        paginationHandler()
        loadMore()
        setIsLoadMore(false)
      } else if (pageNo >= 1) {
        paginationHandler()
        loadMore()
      }
    }
  }, [pageNo, userDetails?.id, openaccordian])

  // getting all crypto id
  const getCryptoList = async () => {
    setListLoader(true)
    try {
      const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
      if (res?.data?.success) {
        setCryptoList(res?.data?.data)
        setActiveCrypto("ALL")
      }
    } catch (error) {

    } finally {
      setListLoader(false)
    }
  }

  useEffect(() => {
    // getCryptoList()
  }, [])


  const getDeposits = async () => {
    setLoader(true)
    try {
      if (userId) {
        const res = await getAPIAuth(`user/find-transaction?userId=${userId}&chain=eth&type=deposit&transactionId=${transactionId}`, token)
        if (res?.data?.success) {
          setDeposits(res?.data?.data)
        } else {
          setDeposits([])
        }
      }
    } catch (error) {

    } finally {
      setLoader(false)
    }
  }

  const newDeposit = async () => {
    setLoader(true)

    try {
      if (userId) {
        const res = await getAPIAuth(`user/deposit-transaction-details?userId=${userId}&transactionId=${transactionId}&chain=${activeCrypto == 'ALL' ? "" : activeCrypto}&page=${pageNo}`, token)
        if (res?.data?.success) {
          setDeposits(res?.data?.data)
          setTransactionId("")
          setTotalPage(res.data.pagination?.[0]?.totalPages)
          setPagination({
            btnOne: 1,
            btnTwo: 2,
            btnThree: 3,
            btnFour: "...",
            btnFive: res.data.pagination?.[0]?.totalPages
          })
        } else {
          setDeposits([])
        }
      }
    } catch (error) {

    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {
    // getDeposits()
    if (tabopen === "Deposits" || openaccordian === "Deposits") {
      if (activeCrypto) {
        newDeposit()
      }
    }
  }, [userId, activeCrypto, tabopen, openaccordian])

  // getting data from transaction id
  const getDataFromTransactionId = () => {
    newDeposit()
  }

  // for set  active crypto
  const handleActiveCrypto = (cryptoName) => {
    setActiveCrypto(cryptoName)
  }

  return (
    <>
      <div className="row pb-2">
        <div className="col-12 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/deposits.png"
                alt="Deposits"
                className="h-100 w-100 img-fluid "
              />
            </div>
            {accountPageLabels.DEPOSITS}
          </div>
        </div>
        
        <div class="col-12 pt-2 pt-sm-3">
          <div class="d-flex align-items-center flex-column flex-sm-row gap-3 gap-sm-3">
            <div class="inputGroup w-100 position-relative d-flex flex-column">
              <input class="EmailInput form-control w-100 shadow-none" 
                type="text" 
                placeholder="Enter deposit amount"
                value={amount}
                onChange={(e)=>{
                  if (!isNaN(Number(e.target.value))) {
                    setAmount(e.target.value)
                    setWithdrawError('')
                }
                }}
                />
                        <div className="d-sm-none">
            {withdrawError ? <div className="text-danger errorTxt mt-1" style={{fontSize: 14}}>{withdrawError}</div> : ''}
          </div>
            </div>
            <button onClick={handleWithdraw} class="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center ">
              {
                isWithdrawLoading ? <Spinner variant="dark" size="sm"/> : 'DEPOSIT'
              }
              
            </button>
          </div>
          <div className="d-none d-sm-block">
            {withdrawError ? <div className="text-danger errorTxt">{withdrawError}</div> : ''}
          </div>
        </div>
        <nav className="col-12">
          <div
            className="nav nav-tabs gap-2 pt-md-5 pb-2 flex-nowrap overflow-x-auto text-nowrap reffralsTab border-0"
          >
            {!listLoader ?
              cryptoList?.length > 0 ?
                <>
                  <button
                    className={`nav-link refferalBtn ${"ALL" === activeCrypto ? 'active' : ''}`}
                    onClick={() => handleActiveCrypto("ALL")}
                  >
                    {accountPageLabels.ALL}
                  </button>
                  {
                    cryptoList?.map((item) => (
                      <button
                        key={item?.code}
                        className={`nav-link refferalBtn ${item?.code === activeCrypto ? 'active' : ''}`}
                        onClick={() => handleActiveCrypto(item?.code)}
                      >
                        {item?.code}
                      </button>
                    ))

                  }
                </>
                : ''
              : (
                <>
                  {/* <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="light" size="lg" />
                </div> */}
                </>
              )
            }
          </div>
        </nav>
        <div className="tab-content col-12 pt-2 pt-md-4 pb-4 pb-md-5">
          <div
            className="tab-pane fade show active"
            id="nav-allChains"
            role="tabpanel"
            aria-labelledby="nav-allChains-tab"
            tabIndex={0}
          >
            {!loader ?
              deposits?.length > 0 ?
                <>
                  <div className="col-12 pt-3">
                    <div className="table-responsive">
                      <table className="table affiliateTable mb-2 align-middle">
                        <thead>
                          <tr className="tableHead text-nowrap">
                            <th scope="col" className="border-0 px-4 rounded-start-2">
                              {accountPageLabels.AMOUNT}
                            </th>
                            <th scope="col" className="border-0 px-4 texter">
                              {accountPageLabels.DATE_TIME}
                            </th>
                            {/* <th
                              scope="col"
                              className="border-0 px-4 text-er"
                            >
                              {accountPageLabels.CREDIT_AMOUNT}
                            </th> */}
                            <th
                              scope="col"
                              className="border-0 px-4 text-er rounded-end-2"
                            >
                              STATUS
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tablebody">
                          {deposits?.map((item) => (
                            <tr className="text-nowrap" key={item?._id}>
                              <td className="border-0 px-4 rounded-start-2">
                                {item?.amount ? Number(item.amount).toFixed(2) : '0.00'} {' '} KSH
                              </td>
                              <td className="border-0 px-4">{moment(item.createdAt).format("MMM DD YYYY , HH:mm")}</td>
                              {/* <td className="border-0 px-4">{item?.amountInUsdt ? Number(item?.amountInUsdt).toFixed(4) : item?.amount ? Number(item?.amount).toFixed(4) : '0.00'} {" "} KSH</td> */}
                              <td className={`border-0 px-4 text-capitalize  ${item?.status === 'success' ? 'text-' : ''}`}>
                                {
                                  item?.status === 'success' ? <div style={{width: 'max-content'}} className="bg-success py-1 px-3 rounded-pill d-flex align-items-center justify-content-center">{item?.status}</div> : 
                                  item?.status === 'Pending' ? <div style={{width: 'max-content'}} className="bg-warning text-dark py-1 px-3 rounded-pill d-flex align-items-center justify-content-center">{item?.status}</div> : 
                                  <div style={{width: 'max-content'}} className="bg-danger py-1 px-3 rounded-pill d-flex align-items-center justify-content-center">{item?.status}</div> 

                                }
                                
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 pt-3">
                    <div className="row gap-4 mt-4">
                      {/* pagination */}
                      <div className="col-12">
                        <ul className="pageNavigationSection mb-0 d-flex gap-2 list-unstyled align-items-center justify-content-center">
                          <li
                            className=" cursor-pointer bg-dark p-2"
                            onClick={() => {
                              if (pageNo < 2) return
                              setPageNo(pre => pre - 1)
                            }}
                          >
                            <span
                              className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation"
                            >
                              <img
                                src="assets/img/newsdApp/leftarro.svg"
                                alt="leftarro"
                                className="img-fluid w-100 h-100"
                              />
                            </span>
                          </li>
                          {
                            pagination.btnOne > 1 ? <li className="bg-dark p-2 text-light cursor-pointer" onClick={() => {
                              setIsLoadMore(true)
                              setPageNo(1)
                            }}>
                              <span className={`text-decoration-none d-flex align-items-center justify-content-center pageNavgation`}>
                                1
                              </span>
                            </li> : ''
                          }
                          {
                            pagination.btnOne > 2 ?
                              <li className=" cursor-pointer bg-dark p-2 text-light">
                                <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation ">
                                  <img src="assets/img/newsdApp/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                                </span>
                              </li> : ''
                          }
                          <li className=" cursor-pointer bg-dark p-2 text-light" onClick={() => setPageNo(pagination.btnOne)} >
                            <span className={`text-decoration-none ${pageNo == pagination.btnOne ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                              {pagination.btnOne}
                            </span>
                          </li>
                          {
                            totalPages <= pagination.btnTwo - 1 ? '' : <li onClick={() => setPageNo(pagination.btnTwo)} className=" bg-dark p-2 text-light cursor-pointer">
                              <span className={`text-decoration-none ${pageNo == pagination.btnTwo ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                {pagination.btnTwo}
                              </span>
                            </li>
                          }
                          {
                            totalPages <= pagination.btnThree - 1 ? '' : <li onClick={() => setPageNo(pagination.btnThree)} className="bg-dark p-2 text-light cursor-pointer ">
                              <span className={`text-decoration-none ${pageNo == pagination.btnThree ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                {pagination.btnThree}
                              </span>
                            </li>
                          }
                          {
                            totalPages >= pagination.btnThree + 2 ? <li className="bg-dark p-2 text-light cursor-pointer">
                              <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation bg-transparent">
                                <img src="assets/img/newsdApp/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                              </span>
                            </li> : ''
                          }
                          {
                            pagination.btnThree == totalPages || pagination.btnTwo == totalPages || pagination.btnOne == totalPages ? '' :
                              <li className=" bg-dark p-2 text-light cursor-pointer" onClick={() => setPageNo(pagination.btnFive)}>
                                <span className={`text-decoration-none text-light ${pageNo == pagination.btnFive ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                  {pagination.btnFive}
                                </span>
                              </li>
                          }
                          <li
                            className="bg-dark p-2 text-light cursor-pointer bg-dark p-2"
                            onClick={() => {
                              if (pageNo > totalPages - 1) return
                              setPageNo(pre => pre + 1)
                            }}
                          >
                            <span
                              className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation">
                              <img src="assets/img/newsdApp/rightarro.svg" alt="leftarro" className="img-fluid w-100 h-100" />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
                : (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="NoEntry">{accountPageLabels.NO_ENTRIES}</div>
                  </div>
                )
              : (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="light" size="lg" />
              </div>)
            }
          </div>
        </div>
        {/* <div className="col-12 affiliateCardTitle">
          {accountPageLabels.Missing_contract}
          <span
            className="affliateProgram text-decoration-none cursor-pointer ps-1"
            onClick={() => setShowTransaction(true)}
          >
            {accountPageLabels.Request_deposit}
          </span>
        </div> */}
        {/* <div className="col-12 pt-3 affiliateCardTitle">
          {accountPageLabels.Missing_SOL}
          <span
            className="affliateProgram text-decoration-none cursor-pointer ps-1"
            onClick={() => setShowTransaction(true)}
          >
            {accountPageLabels.Request_deposit}
          </span>
        </div> */}
        {showTransaction && (
          <div className="col-12 pt-5">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="transactionCard">
                  <div className="card-header">
                    <label htmlFor="transactionid" className="transactionLabel">
                      {accountPageLabels.ENTER_TRANSACTION_ID}
                    </label>
                    <input
                      type="text"
                      id="transactionid"
                      value={transactionId}
                      onChange={(e) => setTransactionId(e.target.value)}
                      placeholder="Transaction Id"
                      className="transactionInput form-control"
                    />
                  </div>
                  <div className="cardBody">
                    <div className="d-flex align-items-center gap-2">
                      <button
                        className="cancelBtn text-decoration-none border-0"
                        onClick={() => {
                          setTransactionId('')
                          setShowTransaction(false)
                        }}
                      >
                        {accountPageLabels.CANCEL}
                      </button>
                      <button
                        onClick={getDataFromTransactionId}
                        className="customBtn shadow-none customBtntwo d-flex align-items-center justify-content-center"
                      >
                        {accountPageLabels.SUBMIT}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

    </>
  );
}

export default Deposits;
