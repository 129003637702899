import React, { useEffect, useState } from "react";
import { getAPIAuth } from "../../../../service/apiInstance";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../hooks/useAuth";
import moment from "moment";

const SportsTransaction = ({tabopen, openaccordian}) => {
  const [transactionData, setTransactionData] = useState()
  const userDetails = useSelector(state => state?.userDetails)
  const { token } = useAuth()
  const [pageNo, setPageNo] = useState(1)
  const [totalPages, setTotalPage] = useState()
  const [pagination, setPagination] = useState({
      btnOne: 1,
      btnTwo: 2,
      btnThree: 3,
      btnFour: "...",
      btnFive: 0
  })
  const [isLoadMore, setIsLoadMore] = useState(false)
  const paginationHandler = (count) => {
    if (pagination.btnOne != pageNo) {
        if (pageNo - 1 == pagination.btnThree) {
            setPagination(
                prev => ({
                    ...prev,
                    btnOne: pagination.btnOne + 1,
                    btnTwo: pagination.btnTwo + 1,
                    btnThree: pagination.btnThree + 1
                })
            )
        } else if (pageNo + 1 == pagination.btnOne) {
            setPagination(
                prev => ({ ...prev, btnOne: pagination.btnOne - 1, btnTwo: pagination.btnTwo - 1, btnThree: pagination.btnThree - 1 })
            )
        } else if (pageNo == totalPages) {
            setPagination(
                prev => ({ ...prev, btnOne: totalPages - 2, btnTwo: totalPages - 1, btnThree: totalPages })
            )
        }
    }
}
const getData = async () => {
    try {
        const res = await getAPIAuth(`user/userTransactions/${userDetails?.id}?page=${pageNo}&gameType=sport`)
        // const res = await getAPIAuth(`user/userSportsTransactions/${userDetails?.id}?page=${pageNo}`)
        if (res.data.success) {
            setTransactionData(res.data.data)
            setTotalPage(res.data.totalPages)
            setPagination({
                btnOne: 1,
                btnTwo: 2,
                btnThree: 3,
                btnFour: "...",
                btnFive: res.data.totalPages
            })
        }
    } catch (error) {
        //error handling
    }

}

const loadMore = async () => {
    try {
        const res = await getAPIAuth(`user/userTransactions/${userDetails?.id}?page=${pageNo}&gameType=sport`)
        // const res = await getAPIAuth(`user/userSportsTransactions/${userDetails?.id}?page=${pageNo}`)
        if (res.data.success) {
            setTransactionData(res.data.data)
            setTotalPage(res.data.totalPages)
        }
    } catch (error) {
        //error handling
    }

}


useEffect(() => {
    if (userDetails?.id && (openaccordian === "Transactions" || tabopen === "Transactions")) {
        getData()
    }
}, [userDetails?.id, openaccordian, token])

useEffect(() => {
    if (pageNo >= 1 && isLoadMore) {
        paginationHandler()
        loadMore()
        setIsLoadMore(false)
    } else if (pageNo >= 1) {
        paginationHandler()
        loadMore()
    }
}, [pageNo])
  return (
    <>
    <div className="tableCard">
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="table-responsive transaction-table scrollTable">
                    <table className="table align-middle text-white text-nowrap">
                        <thead>
                            <tr>
                                {/* <th className="" scope="col">{"NAME"}</th> */}
                                <th className="ps-0" scope="col">{"AMOUNT"}</th>
                                <th className="" scope="col">{"TIME"}</th>
                                <th className="" scope="col">{"STATUS"}</th>
                                <th className="" scope="col">{"TYPE"}</th>
                                <th className="" scope="col">{"CREDIT/WITHDRAW"}</th>
                                {/* <th className="pe-0" scope="col">{"PAYOUT"}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {transactionData?.length > 0 ?
                                transactionData?.map((item) => (
                                    <tr key={item?.NAME}>
                                        {/* <td>
                                            <div className="d-flex align-items-center ">
                                                <div className="gameImg overflow-hidden me-2 " style={{ height: '25px', width: '25px', borderRadius: '3px' }}>
                                                    <img src={item?.NAME} className="h-100 w-100 object-fit-cover" alt="game" />
                                                </div>
                                                {item?.NAME}
                                            </div>
                                        </td> */}
                                        <td className="ps-0">
                                            <div className="d-flex align-items-center ">
                                                {/* <div className="gameImg overflow-hidden me-2 " style={{ height: '25px', width: '25px', borderRadius: '3px' }}>
                                                    <img src={item?.NAME} className="h-100 w-100 object-fit-cover" alt="game" />
                                                </div> */}
                                                {item?.amount ? Number(item?.amount).toFixed(2) : '0.00'}
                                                <span className="text-uppercase"> {item?.coin}</span>
                                                {/* $ {item?.amount ? Math.floor(item?.amount) : '0'} */}
                                            </div>
                                        </td>
                                        <td className="">
                                            {
                                                // "calculateDays(item?.createdAt)"
                                                moment(item.createdAt).format("DD/MM/YY , HH:mm")
                                            }
                                        </td>
                                        <td className="text-capitalize">
                                            {item?.paymentstatus?.toLowerCase()}
                                        </td>
                                        <td>
                                            <span className="text-capitalize">{item?.type?.toLowerCase()}</span>
                                        </td>
                                        <td className="text-center">
                                            <span className="text-capitalize text-center">{item?.transactionType ? item?.transactionType?.toLowerCase():"---"}</span>
                                        </td>
                                        {/* <td className="pe-0">
                                            <span className="d-flex   align-items-center text-decoration-none" >
                                                <span className="d-flex align-items-center amount me-1">
                                                </span>
                                                $0
                                            </span>
                                        </td> */}
                                    </tr>
                                ))
                                : (
                                    <tr>
                                        <td colSpan={6} className="px-0"> No data available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        {transactionData?.length > 0 ?
        <>
          <div className="row gap-4 mt-4">
            {/* pagination */}
            <div className="col-12">
                <ul className="pageNavigationSection mb-0 d-flex gap-2 list-unstyled align-items-center justify-content-center">
                    <li
                        className=" cursor-pointer bg-dark p-2"
                        onClick={() => {
                            if (pageNo < 2) return
                            setPageNo(pre => pre - 1)
                        }}
                    >
                        <span
                            className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation"
                        >
                            <img
                                src="assets/img/newsdApp/leftarro.svg"
                                alt="leftarro"
                                className="img-fluid w-100 h-100"
                            />
                        </span>
                    </li>
                    {
                        pagination.btnOne > 1 ? <li className="bg-dark p-2 text-light cursor-pointer" onClick={() => {
                            setIsLoadMore(true)
                            setPageNo(1)
                        }}>
                            <span className={`text-decoration-none d-flex align-items-center justify-content-center pageNavgation`}>
                                1
                            </span>
                        </li> : ''
                    }
                    {
                        pagination.btnOne > 2 ?
                            <li className=" cursor-pointer bg-dark p-2 text-light">
                                <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation ">
                                    <img src="assets/img/newsdApp/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                                </span>
                            </li> : ''
                    }
                    <li className=" cursor-pointer bg-dark p-2 text-light" onClick={() => setPageNo(pagination.btnOne)} >
                        <span className={`text-decoration-none ${pageNo == pagination.btnOne ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                            {pagination.btnOne}
                        </span>
                    </li>
                    {
                        totalPages <= pagination.btnTwo - 1 ? '' : <li onClick={() => setPageNo(pagination.btnTwo)} className=" bg-dark p-2 text-light cursor-pointer">
                            <span className={`text-decoration-none ${pageNo == pagination.btnTwo ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                {pagination.btnTwo}
                            </span>
                        </li>
                    }
                    {
                        totalPages <= pagination.btnThree - 1 ? '' : <li onClick={() => setPageNo(pagination.btnThree)} className="bg-dark p-2 text-light cursor-pointer ">
                            <span className={`text-decoration-none ${pageNo == pagination.btnThree ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                {pagination.btnThree}
                            </span>
                        </li>
                    }
                    {
                        totalPages >= pagination.btnThree + 2 ? <li className="bg-dark p-2 text-light cursor-pointer">
                            <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation bg-transparent">
                                <img src="assets/img/newsdApp/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                            </span>
                        </li> : ''
                    }
                    {
                        pagination.btnThree == totalPages || pagination.btnTwo == totalPages || pagination.btnOne == totalPages ? '' :
                            <li className=" bg-dark p-2 text-light cursor-pointer" onClick={() => setPageNo(pagination.btnFive)}>
                                <span className={`text-decoration-none text-light ${pageNo == pagination.btnFive ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                    {pagination.btnFive}
                                </span>
                            </li>
                    }
                    <li
                        className="bg-dark p-2 text-light cursor-pointer bg-dark p-2"
                        onClick={() => {
                            if (pageNo > totalPages - 1) return
                            setPageNo(pre => pre + 1)
                        }}
                    >
                        <span
                            className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation">
                            <img src="assets/img/newsdApp/rightarro.svg" alt="leftarro" className="img-fluid w-100 h-100" />
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        </>
        : ''}
        
    </div>
    </>
  )
}

export default SportsTransaction