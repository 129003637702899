import React, { useState } from 'react'
import Crypto from './components/Crypto'
import Fiat from './components/Fiat'

const NewWithdraw = ({activeTab, setActiveTab}) => {
  // const [activeTab, setActiveTab] = useState('crypto')
  return (
    <>
      <div className="newWithdrawModal">
        <div className="withOptionTabs">
          <div onClick={()=>setActiveTab('crypto')} className={`withTab ${activeTab === 'crypto' ? 'active' : ''}`}>Crypto</div>
          <div onClick={()=>setActiveTab('fiat')} className={`withTab ${activeTab === 'fiat' ? 'active' : ''}`}>Fiat</div>
        </div>
        {
          activeTab === 'crypto' ? <Crypto/> : <Fiat/>
        }
        
      </div>
    </>
  )
}

export default NewWithdraw