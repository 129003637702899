import { reducerConst } from "../constants/storeConstants";
const initialState = {
  userDetails: {},
  // userLoader: false if required
  favCrypto: [],
  favFiat: [],
  favIsLoading: false,
  newsCategories: [],
  accounts: [],
  cryptoList: [],
  ipAddress: '',
  country: "",
  rendererInstance: {},
  btUrl: "",
  kadoBuy: {
    currency: {

    },
    network: {

    },
    asset: {

    }
  },
  kadoSell: {
    currency: {

    },
    network: {

    },
    asset: {

    }
  },
  kadoSwap: {
    networkFrom: {

    },
    assetFrom: {

    },
    networkTo: {

    },
    assetTo: {

    }
  },
  currencyData: [],
  networkData: [],
  assetData: [],
  metamaskAcc: "",
  metamaskAccBuy: '',
  metamaskAccSell: '',
  walletAccBuy: '',
  walletAccSell: '',
  userBalance: "",
  selectedWallet: "",
  userBonusDetail: [],
  sportsBook: [],
  topRatedGames: [],
  categoryPillOptions: [],
  currencyExchangeData: [],
  selectedCurrency: {
    fiat: {},
    crypto: {}
  },
  rubleAmount: ''
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case reducerConst.USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case reducerConst.FAV_CRYPTO:
      return {
        ...state,
        favCrypto: action.payload,
      };
    case reducerConst.FAV_FIAT:
      return {
        ...state,
        favFiat: action.payload,
      };
    case reducerConst.Fav_LOADING:
      return {
        ...state,
        favIsLoading: action.payload,
      };
    case reducerConst.NEWS_CATEGORIES:
      return {
        ...state,
        newsCategories: action.payload,
      };
    case reducerConst.PROFILE:
      return { ...state, accounts: action.payload };
    case reducerConst.WALLET:
      return { ...state, accounts: action.payload };
    case reducerConst.WITHDRAW:
      return { ...state, accounts: action.payload };
    case reducerConst.TRANSACTIONS:
      return { ...state, accounts: action.payload };
    case reducerConst.SETTING:
      return { ...state, accounts: action.payload };
    case reducerConst.DEPOSITS:
      return { ...state, accounts: action.payload };
    case reducerConst.AFFILIATE:
      return { ...state, accounts: action.payload };
    case reducerConst.CRYPTO_LIST:
      return { ...state, cryptoList: action.payload };
    case reducerConst.IP_ADDRESS:
      return { ...state, ipAddress: action.payload };
    case reducerConst.COUNTRY_NAME:
      return { ...state, country: action.payload };
    case reducerConst.BT_RENDERER:
      return { ...state, rendererInstance: action.payload };
    case reducerConst.BT_URL:
      return { ...state, btUrl: action.payload };
    // kado buy
    case reducerConst.BUY_CURRENCY:
      return { ...state, kadoBuy: { ...state.kadoBuy, currency: action.payload } };
    case reducerConst.BUY_NETWORK:
      return { ...state, kadoBuy: { ...state.kadoBuy, network: action.payload } };
    case reducerConst.BUY_ASSET:
      return { ...state, kadoBuy: { ...state.kadoBuy, asset: action.payload } };

    // kado sell
    case reducerConst.SELL_CURRENCY:
      return { ...state, kadoSell: { ...state.kadoSell, currency: action.payload } };
    case reducerConst.SELL_NETWORK:
      return { ...state, kadoSell: { ...state.kadoSell, network: action.payload } };
    case reducerConst.SELL_ASSET:
      return { ...state, kadoSell: { ...state.kadoSell, asset: action.payload } };

    // kado swap
    case reducerConst.SWAP_NETWORK_FROM:
      return { ...state, kadoSwap: { ...state.kadoSwap, networkFrom: action.payload } };
    case reducerConst.SWAP_ASSET_FROM:
      return { ...state, kadoSwap: { ...state.kadoSwap, assetFrom: action.payload } };
    case reducerConst.SWAP_NETWORK_TO:
      return { ...state, kadoSwap: { ...state.kadoSwap, networkTo: action.payload } };
    case reducerConst.SWAP_ASSET_TO:
      return { ...state, kadoSwap: { ...state.kadoSwap, assetTo: action.payload } };

    // ------------//

    case reducerConst.CURRENCY_DATA:
      return { ...state, currencyData: action.payload };
    case reducerConst.NETWORK_DATA:
      return { ...state, networkData: action.payload };
    case reducerConst.ASSET_DATA:
      return { ...state, assetData: action.payload };

    case reducerConst.METAMASK_ACC:
      return { ...state, metamaskAcc: action.payload };
    case reducerConst.METAMASK_ACC_BUY:
      return { ...state, metamaskAccBuy: action.payload };
    case reducerConst.METAMASK_ACC_SELL:
      return { ...state, metamaskAccSell: action.payload };

    case reducerConst.WALLET_ACC_BUY:
      return { ...state, walletAccBuy: action.payload };
    case reducerConst.WALLET_ACC_SELL:
      return { ...state, walletAccSell: action.payload };

    case reducerConst.USER_BALANCE:
      return { ...state, userBalance: action.payload };
    case reducerConst.USER_BONUS_DETAIL:
      return { ...state, userBonusDetail: action.payload };
    case reducerConst.SPORT_BOOK:
      return { ...state, sportsBook: action.payload };
    case reducerConst.TOP_RATED_GAMES:
      return { ...state, topRatedGames: action.payload };
    case reducerConst.CATEGORY_PILL_OPTIONS:
      return { ...state, categoryPillOptions: action.payload };
    case reducerConst.CURRENCY_EXCHANGE:
      return { ...state, currencyExchangeData: action.payload }

    case reducerConst.SELECTED_CRYPTO:
      return { ...state, selectedCurrency: { ...state.selectedCurrency, crypto: action.payload } }
    case reducerConst.SELECTED_FIAT:
      // return { ...state, selectedCurrency: action.payload }
      return { ...state, selectedCurrency: { ...state.selectedCurrency, fiat: action.payload } }

    case reducerConst.RUBLE_AMOUNT:
      console.log({ aactionaction: action.payload })
      // return { ...state, selectedCurrency: action.payload }
      return { ...state, rubleAmount: action.payload }

    default:
      return state;
  }
};

export default Reducer;
