import React from "react";
import { useTranslation } from "react-i18next";

const ChatRulesModal = () => {
    const { t } = useTranslation()
    const chatOffcanvas = t("chatOffcanvas", { returnObjects: true })

    return (
        <div
            className="modal fade rulesmodal"
            id="chatrules"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"

        >
            <div className="modal-dialog rulesDialog modal-dialog-scrollable modal-dialog-centered">
                <div
                    className="modal-content rulescontent chatrulescard p-0"
                    style={{ backgroundColor: '#212632' }}
                >
                    <div className="modal-header border border-0 rulesheader">
                        <div className="modal-title chatHeading " id="exampleModalLabel">{chatOffcanvas.Chat_Rules}</div>
                    </div>
                    <div className="modal-body rulebody">
                        <ul className="ruleMenu m-0 px-3">
                            <li className="rules">{chatOffcanvas.Do_not_harass}</li>
                            <li className="rules">{chatOffcanvas.Do_not_beg}</li>
                            <li className="rules">{chatOffcanvas.Do_not_insinuate}</li>
                            <li className="rules">{chatOffcanvas.Do_not_spam}</li>
                            <li className="rules">{chatOffcanvas.Do_not_advertise}</li>
                            <li className="rules">{chatOffcanvas.Do_not_share}</li>
                            <li className="rules">{chatOffcanvas.Do_not_ask}</li>
                            <li className="rules">{chatOffcanvas.English_only}</li>
                            <li className="rules">{chatOffcanvas.Respect_mods_admins}</li>
                            <li className="rules">{chatOffcanvas.Enter_to_see}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ChatRulesModal;
