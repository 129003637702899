import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import './style.scss'
import { useTranslation } from 'react-i18next'

const Lottery = () => {
    const {t} = useTranslation()
    const Lottery = t("Lottery",{returnObjects:true})
    return (
        <>
            <main className="main vh-100" id="main">
                <div className="mainContaint">
                    <div className="homepage dgnTokenWrapper">
                        <div className="d-flex align-items-center justify-content-center" style={{height: 250}}>
                        <div className="comingSoon" style={{color: '#EFB90B', fontSize: '24px'}}>{Lottery.Coming_Soon}</div>
                        </div>
                    </div>
                </div>
                <AppFooter/>
            </main>
        </>
    )
}

export default Lottery