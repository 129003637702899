import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { DOMAIN_NAME, INFO_EMAIL } from '../../constants'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
    return (
        <>
        <main className="main vh-100" id="main">
                <div className="mainContaint">
                        <div className="policypage">
                            <div className="row policymain">
                                <div className="col-12">
                                    <div className="policamlHeading policyHeading">Privacy Policy
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">

                                        <div className="policysubHeading col-12">
                                        Introduction 
                                                </div>
                                                <div className="col-12">
                                                    <div className="row gap-1">

                                                        <div className="col-12 policyDetail">
                                                        Shinda Ki Mfalme ("we", "our", or "us") values your privacy and is committed to protecting your
personal information. This Privacy Policy explains how we collect, use, and safeguard your
information when you visit our website, www.shindakimfalme.com ("Website"), and use our
services ("Services"). By accessing our Website and Services, you agree to the terms of this
Privacy Policy.
                                                        </div>
                                                    </div>
                                                </div>
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">
                                        1. Information We Collect
                                        </div>
                                        <div className="col-12 ps-4">
                                            <div className="policyDetail policySubDetail"> 1.1 Personal Information
                                            </div>
                                            <div className="policyDetail mb-3">When you create an account or use our Services, we may collect personal information, including
                                            but not limited to:
                                            <ul className='mt-2 d-flex flex-column gap-1'>
                                                        <li>Name</li>
                                                        <li>Date of birth</li>
                                                        <li>Email address</li>
                                                        <li>Phone number</li>
                                                        <li>Physical address</li>
                                                        <li>Payment information</li>
                                                    </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 ps-4">
                                            <div className="policyDetail policySubDetail"> 1.2 Usage Information
                                            </div>
                                            <div className="policyDetail mb-3">
                                            We may also collect non-personal information about how you use our Website and Services.
This may include:
                                                <ul className="mt-2 d-flex flex-column gap-1">
                                                    <li>IP address
                                                    </li>
                                                    <li>Browser type</li>
                                                    <li>Operating system</li>
                                                    <li>Pages visited</li>
                                                    <li>Time and date of visits</li>
                                                    <li>Referring website</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 ps-4">
                                            <div className="policyDetail policySubDetail">1.3 Cookies and Tracking Technologies
                                            </div>
                                            <div className="policyDetail mb-3">We use cookies and similar tracking technologies to enhance your experience on our Website.
Cookies are small data files stored on your device that help us remember your preferences and
understand how you interact with our Website.

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">2. How We Use Your Information</div>
                                        <div className="col-12 policyDetail">We use the information we collect for various purposes, including:
                                        </div>
                                        <div className="col-12 policyDetail"> 
                                            <ul className='d-flex flex-column gap-1'>
                                                <li>To create and manage your account
                                                </li>
                                                <li>To process your transactions
                                                </li>
                                                <li>To provide and improve our Services</li>
                                                <li>To communicate with you about your account and our Services</li>
                                                <li>To personalize your experience on our Website</li>
                                                <li>To monitor and analyze usage and trends</li>
                                                <li>To prevent fraudulent activity and enhance security</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">3. How We Share Your Information
                                        </div>
                                        <div className="col-12">
                                            <div className="policyDetail">
                                            We do not sell, trade, or rent your personal information to third parties. We may share your
information in the following circumstances:

                                            <ul className='mt-2 d-flex flex-column gap-1'>
                                                <li>With service providers who assist us in operating our Website and providing our Services
                                                </li>
                                                <li>With regulatory bodies, including the Betting Control and Licensing Board (BCLB), as
                                                required by law</li>
                                                <li>When we believe disclosure is necessary to protect our rights, enforce our Terms and
Conditions, or comply with legal proceedings
</li>
                                                <li>In connection with a merger, acquisition, or sale of all or a portion of our assets</li>
                                            </ul>
 </div>
                                        
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="row policyInner">
                                                <div className="policysubHeading">
                                                4. Data Security
                                                </div>
                                                <div className="policyDetail">
                                                    We implement a variety of security measures to protect your personal information from
                                                    unauthorized access, use, or disclosure. These measures include encryption, firewalls, and secure
                                                    server environments. However, no method of transmission over the internet or electronic storage
                                                    is completely secure, and we cannot guarantee absolute security.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="row policyInner">
                                                <div className="policysubHeading">
                                                5. Your Rights
                                                </div>
                                                <div className="policyDetail">
                                                    You have the right to:
                                                    <ul className="my-2 d-flex flex-column gap-1">
                                                        <li>Access the personal information we hold about you</li>
                                                        <li>Request correction of inaccurate or incomplete information</li>
                                                        <li>Request deletion of your personal information, subject to legal obligations</li>
                                                        <li>Opt-out of receiving marketing communications from us</li>
                                                    </ul>
                                                    To exercise these rights, please contact us using the information provided below.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="row policyInner">
                                                <div className="policysubHeading">
                                                6. Third-Party Links
                                                </div>
                                                <div className="policyDetail">
                                                Our Website may contain links to third-party websites. We are not responsible for the privacy
practices or content of these websites. We encourage you to review the privacy policies of any
third-party sites you visit.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="row policyInner">
                                                <div className="policysubHeading">
                                                7. Changes to This Privacy Policy
                                                </div>
                                                <div className="policyDetail">
                                                We may update this Privacy Policy from time to time. The most current version will always be
posted on our Website. We encourage you to review this Privacy Policy periodically to stay
informed about how we are protecting your information.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">6. Contact Us
                                         </div>
                                        <div className="col-12 policyDetail">If you have any questions or concerns about this Privacy Policy, please contact us at:                                        </div>
                                        <div className="col-12 policyDetail">
                                            Email: <Link className='policyLink' to={'mailto:support@shindakimfalme.com'}>support@shindakimfalme.com</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <AppFooter/>
            </main>
        </>
    )
}

export default PrivacyPolicy