import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { auth } from '../../../../config/firebase';
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import { getAPI, postAPI } from '../../../../service/apiInstance';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useAuth } from '../../../../hooks/useAuth';
import ErrorMessage from '../../../common/ErrorMessage/ErrorMessage';
import DownArrow from '../../../common/icons/DownArrow';
import SearchIcon from '../../../common/icons/SearchIcon';
import OtpSignIn from './OtpSignIn';
import { Spinner } from 'react-bootstrap';
import { t } from 'i18next';
import { USER_LANG } from '../../../../constants';
import { useAuthModal } from '../../../../hooks/useAuthModal';
import { useSearchParams } from 'react-router-dom';



const validationSchema = Yup.object({
  password: Yup.string().required("This field is required"),
  number: Yup.string()
    .required("Phone Number is required")
    // .matches(
    //   /^[0-9]{5}$/,
    //   "Invalid mobile number"
    // ),
});

const SignInNumber = ({
  setEmailPassword,
  setLogOn,
  setPhoneOtp,
  setEmailOrNumber,
  setForgetpass,
  logOn,
  browserName,
  setIsForgotPassword
  // select,
  // setSelect
}) => {
  const [showCountry, setShowCountry] = useState(false);
  const [select, setSelect] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [countrycodedata, setCountryCode] = useState([]);
  const countryName = useSelector((state) => state.country);
  const [matchedItem, setmatchedItem] = useState([]);
  const { token, setToken } = useAuth()
  const [isOTP,setIsOTP] = useState(false)
  const [number ,setNumber] = useState("")
  const [password, setPassword] = useState('')
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);
  const country_id = localStorage.getItem("country_Id");
  const authLabels = t("AuthLabels", { returnObjects: true })
  const [query, setQuery] = useSearchParams()
  const { authModalObject, setAuthModalObject } = useAuthModal();//string has to be same as language json key 


  console.log('country_id', country_id)
  console.log('country_id select', select)
  console.log('country_id countryName', countrycodedata)




  // const generateRecaptcha = (random) => {
  //   const recaptchaParent = document.getElementById("recaptcha-parent");
  //   if (recaptchaParent) {
  //     recaptchaParent.innerHTML = `<span id=${random} />`
  //   }
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     `${random}`,
  //     {
  //       size: "invisible",
  //       callback: (response) => { },
  //     },
  //     auth
  //   );
  //   window.recaptchaVerifier.render().then((widgetId) => {
  //     window.recaptchaWidgetId = widgetId;
  //   });
  // };

  // const handleSend = (values) => {
  //   const random = Math.random() + 1000000
  //   generateRecaptcha(random);
  //   let appVerifier = window.recaptchaVerifier;
  //   signInWithPhoneNumber(
  //     auth,
  //     `${select?.phoneCode ? select.phoneCode : "+254"} ${values.number}`,
  //     appVerifier
  //   )
  //     .then((confirmationResult) => {
  //       succesToaster("OTP SENT!");
  //       window.confirmationResult = confirmationResult;
  //       // setLogOn(false);
  //       // setPhoneOtp(true);
  //       setNumber(values.number)
  //       setIsOTP(true)
  //     })
  //     .catch((error) => {
  //       // errorToaster(error.message.split('(')[1].split(')')[0].split('/')[1]);
  //       errorToaster(error.message);

  //       const recaptchaContainer = document.getElementById(`${random}`)
  //       if (recaptchaContainer) { recaptchaContainer.remove(); }

  //       // errorToaster(getFriendlyErrorMessage(error.code))
  //       // errorToaster(error.message)

  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };
  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
    setmatchedItem(res.data.data)
  };
  
  useEffect(() => {

    console.log('countryName', countryName)
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    console.log('countryName && codedata[0]', countryName && codedata[0])
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  useEffect(() => {
    if (!token) {
      CountryCodeFun();
    }
  }, [token]);
  // useEffect(() => {
  //   if (!logOn) {
  //     setIsLoading(false);
  //   }
  // }, [logOn]);
  const handlesearch = (val) => {
    const valdata = val.trim();
    if (valdata.length > 0) {
      const filterdata = countrycodedata?.filter(
        (item) => item.country.toLowerCase().includes(val.toLowerCase()) || item.phoneCode.toLowerCase().includes(val.toLowerCase())
      )
      setmatchedItem(filterdata)
    } else {
      setmatchedItem(countrycodedata)
    }
  }

  const handleSend = async (values)=> {
    try {
      const lang = localStorage.getItem(USER_LANG)
      const body = {
          phone: `${select?.phoneCode ? select.phoneCode.substring(1) : "254"}${values?.number}`,
          password: values?.password,
          ipAddress: ipAddress ? ipAddress : "",
          country: country ? country : "",
          // language: lang?.symbol,
          language: lang,
          phoneCodeId: country_id === "undefined" ? '654ca95ca2a15f1ce43978a6' : country_id,
          browser: browserName
      };
      const res = await postAPI('user/login-user', body)
      if (res?.data?.success) {
        succesToaster(res.data.message);
        setToken(res?.data.data?.auth_token);
        // setOtpLoader(false);
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
        setQuery({ action: "" })
        localStorage.removeItem('clickid')
        } else {
        errorToaster(res.data.message);
        // setOtpLoader(false);
        }
    } catch (error) {
      
    } finally {
      setIsLoading(false)
    }
  }


  console.log('select', select)

  return (
    <>
          <Formik
            initialValues={{
              number: "",
              password: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              setIsLoading(true);
              handleSend(values, resetForm);
            }}
          >
            {
              ({
                values,
                handleBlur,
                handleChange,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form>
                  <div className="col-12">
                    <div className="mobileInputWrapper position-relative">
                      <input
                        className='inp'
                        type="text"
                        placeholder='Phone number'
                        name="number"
                        id="number"
                        value={values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div onClick={() => setShowCountry(!showCountry)} className="mobileNoTxt">
                        {select?.phoneCode ? select?.phoneCode : "+254"}
                        <DownArrow />
                      </div>
                      {
                        showCountry ?
                          <div className="phoneNumberWrapper">
                            <div className="row">
                              <div className="col-12">
                                <div className="inputWrapper position-relative">
                                  <input
                                    type="text"
                                    className='inp'
                                    placeholder='Search'
                                    name="Search1"
                                    id="Search1"
                                    onChange={(e) => handlesearch(e.target.value)}
                                  />
                                  <div className='searchImg'>
                                    <SearchIcon />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 listParent">
                                {
                                  matchedItem.map((item, index) => (
                                    <div
                                      className="row mx-0 list my-2"
                                      key={item?._id}
                                      onClick={() => {
                                        setSelect(item);
                                        localStorage.setItem("country_Id", item?._id)
                                        setShowCountry(false)
                                      }}
                                    >
                                      <div className="col-auto ps-0">
                                        <div className="number">
                                          {item.phoneCode}
                                        </div>
                                      </div>
                                      <div className="col pe-0"><div className="country">{item.country}</div></div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                          : ''
                      }
                    </div>
                    <ErrorMessage>
                      {
                        errors.number &&
                        touched.number &&
                        errors.number
                      }
                    </ErrorMessage>
                  </div>
                  <div className="col-12 mt-2">
                        <div className="inpWrapper position-relative">
                            <input
                                className='inp'
                                type={`${isPasswordHidden ? "password" : "text"}`}
                                // placeholder='Login Password'
                                placeholder={authLabels.Password}
                                name="password"
                                id="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {
                                isPasswordHidden ? (
                                    <span
                                        onClick={() => setIsPasswordHidden(false)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34444 4.41401C4.34444 2.6747 5.75918 1.25937 7.50037 1.25937C9.23972 1.25937 10.6544 2.67466 10.6544 4.41401C10.6544 6.15372 9.23972 7.56843 7.50037 7.56843C5.75915 7.56843 4.34444 6.15372 4.34444 4.41401ZM0.507401 4.11304C0.398575 4.30988 0.398575 4.51813 0.507401 4.71515C1.92027 7.25228 4.5996 8.82838 7.50037 8.82838C10.3992 8.82838 13.0786 7.25231 14.4915 4.71515C14.6022 4.51813 14.6022 4.30988 14.4915 4.11304C13.0786 1.57592 10.3993 0 7.50037 0C4.5996 0 1.92027 1.57592 0.507401 4.11304ZM7.50037 2.29322C6.32957 2.29322 5.37826 3.2445 5.37826 4.41401C5.37826 5.58349 6.32954 6.53479 7.50037 6.53479C8.6693 6.53479 9.62058 5.58352 9.62058 4.41401C9.62058 3.2445 8.6693 2.29322 7.50037 2.29322Z" fill="#B2B6C5" />
                                        </svg>
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => setIsPasswordHidden(true)}
                                    >
                                        <svg stroke="currentColor" fill="#B2B6C5" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448zM248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69 64.11 64.11 0 0 0 53.49 53.49 2 2 0 0 0 1.69-3.39zm16-119.7L315.87 248a2 2 0 0 0 3.4-1.69 64.13 64.13 0 0 0-53.55-53.55 2 2 0 0 0-1.72 3.39z"></path><path d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 0 0-71.82 11.79 4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05 96 96 0 0 1 116 116 4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24 343.81 343.81 0 0 0 67.24-77.4zM256 352a96 96 0 0 1-93.3-118.63 4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0 0 72.64-11.55 4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352z"></path></svg>
                                    </span>
                                )
                            }
                        </div>
                        <ErrorMessage>
                            {errors.password && touched.password && errors.password}
                        </ErrorMessage>
                    </div>
                    <div className="col-12 d-flex align-items-center mt-2">
                      <div class="form-check formCheck mt-0">
                        <input class="form-check-input formInp" type="checkbox" id="flexCheckDefault"/>
                        <label class="form-check-label formLbl" for="flexCheckDefault">Remember me</label></div>
                  <div
                    className='text-light cursor-pointer forgetPasswordTxt mt-0'
                    onClick={() => {
                      setIsForgotPassword(true)
                      setQuery({ action: "forgotPassword" })
                    }}
                  >
                    {authLabels.Forgot_your_password}
                  </div>
                </div>
                  <div className="col-12 mt-3">
                    <button
                      className='emailBtn active'
                      type='button'
                      disabled={isLoading}
                      onClick={(e) => {
                        // handleSubmit()
                        e.preventDefault()
                        handleSubmit()
                      }}
                    >
                      {isLoading ? <Spinner size="sm" color="dark" /> : 'Sign In'}
                      
                    </button>
                  </div>
                  <div id="recaptcha-parent" />
                </form>
              )
            }
          </Formik>


    </>
  )
}

export default SignInNumber





