import React, { useEffect, useState } from 'react'
import './style.scss'
import { useKado } from '../../hooks/useKado';
import { APP_NAME, KADO_WALLET_TABS } from '../../constants';
import BuyTab from './components/BuyTab';
import SellTab from './components/SellTab';
import SwapTab from './components/SwapTab';
// import SelectCurrency from './components/SelectCurrency';
// import SelectNetwork from './components/SelectNetwork'
import SelectWallet from './components/SelectWallet';
// import { useDispatch } from 'react-redux';
import { getAPIAuth } from '../../service/apiInstance';
import KadoIframe from './components/KadoIframe';
import { MetaMaskProvider } from '@metamask/sdk-react';
import { useTranslation } from 'react-i18next';

const CadoWallet = () => {
    const { kadoWalletObject, setKadoWalletObject } = useKado()
    // const [currency, setCurrency] = useState({})
    // const [network, setNetwork] = useState({})
    // const [showCurrency, setShowCurrency] = useState(false)
    const [showKado, setShowKado] = useState(false)
    // const dispatch = useDispatch()
    const { t } = useTranslation()
    const kadoMoney = t("kadoMoney", { returnObjects: true })
    useEffect(() => {
        window?.dataLayer?.push({
            'event': 'GA4_event',
            'event_name': 'game_click_buy_crypto'
        });
    }, [])
    return (
        <>
            <MetaMaskProvider
                debug={false}
                sdkOptions={{
                    logging: {
                        developerMode: false,
                    },
                    // communicationServerUrl: process.env.REACT_APP_COMM_SERVER_URL,
                    checkInstallationImmediately: false, // This will automatically connect to MetaMask on page load
                    dappMetadata: {
                        name:APP_NAME,
                        url: window.location.host,
                    }
                }}>
                <div
                    className={`modal cadoWalletModal fade ${kadoWalletObject?.isBuySellOpen ? 'show d-block' : ''}`}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered position-relative">
                        <div className="modal-content overflow-hidden">
                            <div className="modal-body  overflow-hidden">
                                <div className="row navBottomBar">
                                    <div className="col">
                                        <ul
                                            className="nav nav-pills mb-3 align-items-center"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    onClick={() => setKadoWalletObject((prev) => ({
                                                        ...prev,
                                                        selectedTab: KADO_WALLET_TABS.BUY
                                                    }))}
                                                    className={`nav-link ${kadoWalletObject?.selectedTab === KADO_WALLET_TABS.BUY ? 'active' : ''}`}
                                                    id="pills-home-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-home"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-home"
                                                    aria-selected="true"
                                                >
                                                    {kadoMoney.Buy}
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    onClick={() => setKadoWalletObject((prev) => ({
                                                        ...prev,
                                                        selectedTab: KADO_WALLET_TABS.SELL
                                                    }))}
                                                    className={`nav-link ${kadoWalletObject?.selectedTab === KADO_WALLET_TABS.SELL ? 'active' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">{kadoMoney.Sell}</button>
                                            </li>
                                            {/* <li className="nav-item" role="presentation">
                                            <button
                                                onClick={() => setKadoWalletObject((prev) => ({
                                                    ...prev,
                                                    selectedTab: KADO_WALLET_TABS.SWAP
                                                }))}
                                                className={`nav-link ${kadoWalletObject?.selectedTab === KADO_WALLET_TABS.SWAP ? 'active' : ''}`} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Swap</button>
                                        </li> */}
                                        </ul>
                                    </div>
                                    <div className="col-auto">
                                        <div className='d-flex  justify-content-end align-items-center w-100'>
                                            <div className="close cursor-pointer"
                                                onClick={() => setKadoWalletObject((prev) => ({
                                                    isBuySellOpen: false
                                                }))}
                                            ><img src="assets/img/heroBanner/closee.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (
                                        kadoWalletObject?.selectedTab === KADO_WALLET_TABS.BUY
                                    ) ? (
                                        <BuyTab
                                            setShowKado={setShowKado}
                                        />
                                    ) : (
                                        kadoWalletObject?.selectedTab === KADO_WALLET_TABS.SELL
                                    ) ? (
                                        <SellTab
                                            setShowKado={setShowKado}
                                        //  setShowCurrency={setShowCurrency} setShowNetwork={setShowNetwork}
                                        />
                                    ) : (
                                        kadoWalletObject?.selectedTab === KADO_WALLET_TABS.SWAP
                                    ) ? (
                                        <SwapTab
                                        //  setShowNetwork={setShowNetwork}
                                        />
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            {/* <SelectCurrency setShowCurrency={setShowCurrency} showCurrency={showCurrency} />
                        <SelectNetwork setShowNetwork={setShowNetwork} showNetwork={showNetwork} /> */}
                            {/* <KadoIframe
                            showKado={showKado}
                            setShowKado={setShowKado}
                        /> */}
                        </div>
                    </div>
                </div>

            </MetaMaskProvider>
        </>
    )
}

export default CadoWallet




