import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPI, postAPIAuth } from "../../../service/apiInstance";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { fetchUserDetails } from "../../../service/fetchUserDetails";
import { reducerConst } from "../../../constants/storeConstants";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../config/firebase";
import { getCustomAuthError } from "../../../utils/customFirebaseError";
import ChangePhoneNumber from "./changePhoneNumber";


function Profile() {
  const userDetails = useSelector((state) => state.userDetails);
  const [data, setData] = useState('')
  const [userName, setUserName] = useState('')
  const [error, setError] = useState(false)
  const dispatch = useDispatch();
  const { token } = useAuth();
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const commonText = t('commonText', { returnObjects: true })










  const userId = userDetails?.id;
  // const PrevioususerName = userDetails?.username;

  console.log('userDetails', userDetails)

  useEffect(() => {
    setUserName(userDetails?.username)
  }, [userDetails])

  const handleChangeUser = async () => {
    if (userName?.length >= 3) {
      setError(false)
      try {
        const res = await postAPIAuth('user/update-username', {
          userId,
          userName
        })
        if (res?.data?.success) {
          succesToaster('Name Changed Successfully')
          setData(res)
          // setUserName('')
          
        } else {
          errorToaster(res?.data?.message)
        }
      } catch (error) {
        errorToaster(error?.message)
      }
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (token) {
      const getData = async () => {
        const res = await fetchUserDetails(token);
        dispatch({
          type: reducerConst.USER_DETAILS,
          payload: res?.data?.data ? res.data.data : {},
        });
      };
      getData();
    }
  }, [data, token]);







  return (
    <>
      <div className="row">
        <div className="col-12 d-md-block d-none">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/profile.png"
                alt="profile"
                className="h-100 w-100 img-fluid "
              />
            </div>
            {accountPageLabels.PROFILE}
          </div>
        </div>
        <div className="col-12 cardsubHeading">{accountPageLabels.CHANGE_USERNAME}</div>
        <div className="col-12">
          <div className="accountInput">
            <label htmlFor="username" className="accountLabel">
              {accountPageLabels.NEW_USERNAME}  <span>*</span>
            </label>
            <div className="inputmain d-flex align-items-center ">
              <input
                type="text"
                className="userNameInput form-control shadow-none border-0"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <button
                className="customBtn d-flex border-0 align-items-center justify-content-center "
                onClick={handleChangeUser}
              >
                {accountPageLabels.CHANGE}
              </button>
            </div>
            {error ? <div className="text-danger errorTxt">{accountPageLabels.Invalid_name}</div> : ''}
          </div>
        </div>

        {/* <ChangePhoneNumber/> */}
        {/* {
          !isOtp ? <>
            <div className="col-12 cardsubHeading text-uppercase">Change Phone Number</div>
            <div className="col-12">
              <div className="accountInput">
                <label htmlFor="username" className="accountLabel">
                  New Phone Number<span>*</span>
                </label>
                <div className="inputmain d-flex align-items-center ">
                  <div className="position-relative mobileInputWrapper flex-fill" >
                    
                  <input
                    type="text"
                    className="userNameInput form-control shadow-none border-0"
                    style={{paddingLeft: 80}}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                      <div onClick={() => setShowCountry(!showCountry)} className="mobileNoTxt">
                            {select?.phoneCode ? select?.phoneCode : "+254"}
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                              <path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="#ffffff" />
                            </svg>
                          </div>
                  {
                          showCountry ?
                              <div className="phoneNumberWrapper">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="inputWrapper position-relative">
                                      <input
                                        type="text"
                                        className='inp'
                                        placeholder='Search'
                                        name="Search"
                                        id="Search"
                                        onChange={(e) => handlesearch(e.target.value)}

                                      />
                                      <SearchIcon />

                                    </div>
                                  </div>
                                  <div className="col-12 listParent">
                                    {
                                      matchedItem.map((item, index) => (
                                        <div className="row mx-0 list my-2"
                                          key={index}
                                          onClick={() => {
                                            setSelect(item);
                                            localStorage.setItem("country_Id", item?._id)
                                            setIsOpen(false)
                                            setShowCountry(false)
                                          }}
                                        >
                                          <div className="col-auto ps-0">
                                            <div className="number">
                                              {item.phoneCode}
                                            </div>
                                          </div>
                                          <div className="col pe-0">
                                            <div className="country">
                                              {item.country}
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </div>
                                </div>
                              </div>
                              : ''
                          }
                  </div>
                  <button
                    className="customBtn d-flex border-0 align-items-center justify-content-center "
                    onClick={handleChangePhone}
                  >
                    {accountPageLabels.CHANGE}
                  </button>
                </div>
                {error ? <div className="text-danger errorTxt">{accountPageLabels.Invalid_name}</div> : ''}
              </div>
            </div>
            <div id="recaptcha-parent" />
          </> : <>
              <VerifyOldOtp
              select={select}
              number={phoneNumber}
              verificationId={verificationId}
              />
          </>
        } */}
        {/* will be used in future */}
        {/* <div className="col-12 pt-md-4 pt-3">
          <div className="privateProfile d-flex align-items-center">
            <div className="form-check form-switch mb-0">
              <input
                className="form-check-input border-0 shadow-none"
                type="checkbox"
                role="switch"
                id="private"
              />
            </div>
            <label htmlFor="private" className="checkLabel">
              Private profile
            </label>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Profile;
