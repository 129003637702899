import React, { useEffect, useRef, useState } from 'react'
import { useKado } from '../../../hooks/useKado'
import SelectCurrency from './SelectCurrency'
import SelectNetwork from './SelectNetwork'
import { useSelector } from 'react-redux'
import { buyAssetAction, buyCurrencyAction, buyNetworkAction, metamaskBuyAction, walletBuyAction } from '../../../store/action'
import { getAPIAuth } from '../../../service/apiInstance'
import { useAuth } from '../../../hooks/useAuth'
import SelectWallet from './SelectWallet'
import KadoIframe from './KadoIframe'
import KadoIframeComp from './KadoIframee'
import { useTranslation } from 'react-i18next'

const BuyTab = ({
    //  setShowKado 
}) => {
    const [showFees, setShowFees] = useState(false)
    const { kadoWalletObject } = useKado()
    const [showCurrency, setShowCurrency] = useState(false)
    const [showNetwork, setShowNetwork] = useState(false)
    const currency = useSelector(state => state?.kadoBuy?.currency)
    const network = useSelector(state => state?.kadoBuy?.network)
    const asset = useSelector(state => state?.kadoBuy?.asset)
    const { token } = useAuth()
    const [priceLoader, setPriceLoader] = useState(true)
    const [amount, setAmount] = useState("200")
    const [priceObj, setPriceObj] = useState({})
    const [error, setError] = useState(false)
    const throttlingRef = useRef()
    const metamask = useSelector(state => state.metamaskAccBuy)
    const account = useSelector(state => state.walletAccBuy)
    const [selectWalletOpen, setSelectWalletOpen] = useState(false)
    // const { kadoWalletObject, setKadoWalletObject } = useKado()
    const [showKado, setShowKado] = useState(false)
    const userDetails = useSelector(state => state.userDetails)
    const [url, setUrl] = useState('')
    const { t } = useTranslation()
    const kadoMoney = t("kadoMoney", { returnObjects: true })


    const getPriceAndNetworkFee = async () => {
        setPriceLoader(true)
        try {
            const res = await getAPIAuth(`kado/Quote?transactionType=buy&fiatMethod=ach&partner=fortress&amount=${amount}&asset=${asset?.symbol}&blockchain=${asset?.blockChainId?.origin}&currency=${currency.code}`, token)

            if (res?.data?.success) {
                setPriceObj(res.data?.data?.data)
                setError(false)
            } else {
                setPriceObj({})
                setError(true)
            }
        } catch (error) {
            setPriceObj({})
            setError(true)
        }
    }
    useEffect(() => {

        if (token && kadoWalletObject.isBuySellOpen) {
            if (currency?.code && asset?.blockChainId?.origin && amount) {
                if (throttlingRef.current) {
                    clearTimeout(throttlingRef.current)
                }
                throttlingRef.current = setTimeout(() => {
                    getPriceAndNetworkFee()
                }, 500)
            }
            setPriceLoader(false)
        }
        return () => {
            if (token) {
                if (currency?.code && asset?.blockChainId?.origin && amount) {
                    clearTimeout(throttlingRef.current)
                }
            }
        }

    }, [amount, token, currency, asset, kadoWalletObject.isBuySellOpen])
    // useEffect(() => {

    // }, [])
    return (
        <>
            <div className="row payCard mt-4 mx-0">
                <div className="col ps-0">
                    <div className="usdTxt">{kadoMoney.Pay}</div>
                    {/* <div className="usdAmount">200</div> */}
                    <input
                        type="text"
                        className='usdAmount bg-transparent border-0 p-2'
                        placeholder='Enter amount here'
                        value={amount}
                        onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                                setAmount(e.target.value)
                            }
                        }}
                    />

                </div>
                <div className="col-auto pe-0">
                    <div className="usdCard" onClick={() => setShowCurrency(true)}>
                        <div className="row align-items-center w-100 mx-0 pe-2">
                            <div className="col-auto ps-0 d-flex justify-content-center align-items-center">
                                <div className="flag d-flex justify-content-center align-items-center">
                                    <img src={currency?.icon} alt="" />
                                </div>
                            </div>
                            <div className="col ps-0">
                                <div className='ethTxt'>{currency?.code}</div>
                            </div>
                            <div className="col-auto d-flex align-items-center justify-content-center pe-0">
                                <div className="down d-flex align-items-center justify-content-center">
                                    <img src="assets/img/heroBanner/down.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-0 py-4 my-1">
                <div className="col-12 d-flex justify-content-center"><div className="divider position-relative"><div className="bg"><img src="assets/img/heroBanner/backgroundDrop.png" alt="" /></div></div></div>
            </div>
            <div className="row payCard mx-0">
                <div className="col ps-0">
                    <div className="usdTxt">{kadoMoney.Receive}</div>
                    <div className="usdAmount">
                        {
                            priceObj?.quote?.receive?.unitCount || priceObj?.quote?.receive?.unitCount == 0 ? Number(priceObj?.quote?.receive?.unitCount).toFixed(8) : "------"
                        }
                    </div>
                </div>
                <div className="col-auto pe-0">
                    <div className="usdCard" 
                    // onClick={() => setShowNetwork(true)}
                    
                    >
                        <div className="row w-100 mx-0 pe-2">
                            <div className="col-auto position-relative ps-0 d-flex justify-content-center align-items-center">
                                <div className="flag d-flex justify-content-center align-items-center">
                                    <img src={asset?.image} alt="" />
                                </div>
                                <div className="flagSub">
                                    <img src={asset?.blockChainId?.image} alt="" />
                                </div>
                                {/* <div className='col-12'>Ethereum</div> */}
                            </div>
                            <div className="col ps-0">
                                <div className='ethTxt'>{asset?.name}</div>
                                <div className='ethTxt1'>{asset?.blockChainId?.network}</div>
                            </div>
                            <div className="col-auto d-flex align-items-center justify-content-center pe-0">
                                <div className="down d-flex align-items-center justify-content-center">
                                    <img src="assets/img/heroBanner/down.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider my-3"></div>
                <div className="col-12 d-flex align-item-center justify-content-between px-0">
                    <div className="to">{kadoMoney.To}</div>
                    <div className="to">
                        {account ? (account.length >= 10 ? `${account.slice(0, 9)}...` : "") : ""}
                        {/* {
                            account ? (
                                account.length >= 10 ? `${account.slice(0, 9)}...` : account
                            ) : (
                                metamask
                            ) ? (
                                metamask.length >= 10 ? `${metamask.slice(0, 9)}...` : metamask
                            ) : ""
                        } */}
                    </div>
                    {
                        account ? (
                            <button
                                className='connectBtn'
                                // onClick={() => setKadoWalletObject((prev) => ({
                                //     ...prev,
                                //     isBuySellOpen: false,
                                //     isSelectWalletOpen: true
                                // }))}
                                onClick={() => setSelectWalletOpen(true)}
                            >
                                {kadoMoney.Change}
                            </button>
                        ) : (
                            <button
                                className='connectBtn'
                                // onClick={() => setKadoWalletObject((prev) => ({
                                //     ...prev,
                                //     isBuySellOpen: false,
                                //     isSelectWalletOpen: true
                                // }))}
                                onClick={() => setSelectWalletOpen(true)}

                            >
                                {kadoMoney.Connect}
                            </button>
                        )
                    }
                </div>
            </div>
            <div className="row mx-0 mt-2 align-items-center">
                <div className="col-6 ps-0">
                    <div className="txt">
                        {priceObj?.quote?.price?.symbol ? `1 ${priceObj?.quote?.price?.symbol}` : "---"}
                        ≈
                        {priceObj?.quote?.price?.price || priceObj?.quote?.price?.price ? Number(priceObj?.quote?.price?.price).toFixed(4) : "-------"} {" "}
                        {priceObj?.quote?.price?.unit ? priceObj?.quote?.price?.unit : "---"}
                    </div>
                </div>
                <div className="col-6 d-flex justify-content-end pe-0">
                    <div className="row mx-0 align-items-center justify-content-end">
                        <div className="col-auto px-0">
                            <div className="icon d-flex align-items-center"><img src="assets/img/heroBanner/clock.png" alt="" /><span className='ps-1'>5m</span></div>
                        </div>
                        <div className="col-auto pe-0 d-flex align-items-center">
                            <div className="icon"><img src="assets/img/heroBanner/flash.png" alt="" />
                                <span className='ps-1'>
                                    {priceObj?.quote?.totalFee?.amount || priceObj?.quote?.totalFee?.amount == 0 ? (
                                        Number(priceObj?.quote?.totalFee?.amount).toFixed(2)
                                    ) : ("----")}{" "} {priceObj?.quote?.totalFee?.currency}
                                </span>
                            </div>
                            <div className={`icon2 ps-2 cursor-pointer ${showFees ? 'rotate' : ''}`}
                                onClick={() => setShowFees(!showFees)}>
                                <img src="assets/img/heroBanner/down.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5 mb-3 d-flex justify-content-center px-0"
                // onClick={() => setKadoWalletObject((prev) => ({
                //     ...prev,
                //     isBuySellOpen: false,
                //     isSelectWalletOpen: true
                // }))}
                // onClick={() => setSelectWalletOpen(true)}
                >
                    {
                    account ? (
                            <button
                                onClick={() => {
                                    setShowKado(true)
                                    window?.dataLayer?.push({
                                        'event': 'GA4_dep_event',
                                        'event_name': 'game_buy_crypto_continue',
                                        'event_currency': 'USD',
                                        'event_value': '100.00' //amount in usd(usdt)
                                    });
                                    // if (amount && (account || metamask)) {
                                    //     window.open(`https://app.kado.money/?onPayAmount=${amount}&onPayCurrency=${currency?.code}&onRevCurrency=${asset?.symbol}&cryptoList=ETH,AVAX,USDC&onToAddress=${account ? account : metamask ? metamask : ""}&network=${network?.network}&networkList=ETHEREUM,AVALANCHE&product=BUY&productList=BUY&mode=minimal&email=${userDetails?.email ? userDetails?.email : ""}`, '_blank');
                                    // }
                                }}
                                className='walletBtn'
                            >
                                {kadoMoney.Continue}
                            </button>
                        ) : (
                            <button
                                onClick={() => setSelectWalletOpen(true)}
                                className='walletBtn'>
                                {kadoMoney.Enter_Wallet_Address}
                            </button>
                        )
                    }


                </div>
                <div className='text-danger fs-6'>
                    {error ? "At the moment, we can't offer a quote. We'll try again shortly" : ""}
                </div>
            </div >
            <div className="row justify-content-center align-items-center gx-2">
                <div className="col-auto" style={{ borderRight: '1px solid #B2B6C5' }}>
                    <div className="we">{kadoMoney.We_accept}</div>
                </div>
                <div className="col-auto lisks">
                    <img src="assets/img/heroBanner/applepay.png" alt="" />
                </div>
                <div className="col-auto lisks">
                    <img src="assets/img/heroBanner/Gpay.png" alt="" />
                </div>
                <div className="col-auto lisks">
                    <img src="assets/img/heroBanner/Mastercard.png" alt="" />
                </div>
                <div className="col-auto lisks">
                    <img src="assets/img/heroBanner/visa.png" alt="" />
                </div>
                <div className="col-auto lisks">
                    <img src="assets/img/heroBanner/SamsungPay.png" alt="" />
                </div>
            </div>

            {/* fess  */}
            <div className={`position-absolute feesWrapper ${showFees ? 'active' : ''}`}>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <div className="feeHeading">Fees</div>
                        <div className="closeFee" onClick={() => setShowFees(!showFees)}>
                            <img src="assets/img/heroBanner/closee.png" alt="" />
                        </div>
                    </div>
                    <div className="col-12 d-flex mt-3 justify-content-between">
                        <div className="feeSubTxt">{kadoMoney.Processing_Fee}</div>
                        <div className="feeAmount">
                            {priceObj?.quote?.processingFee?.amount || priceObj?.quote?.processingFee?.amount == 0 ? Number(priceObj?.quote?.processingFee?.amount).toFixed(2) : "---"}{' '}
                            {priceObj?.quote?.processingFee?.currency ? priceObj?.quote?.processingFee?.currency : ""}
                        </div>
                    </div>
                    <div className="col-12 d-flex mt-3 justify-content-between">
                        <div className="feeSubTxt">{kadoMoney.Network_Fee}</div>
                        <div className="feeAmount">
                            ≈ {priceObj?.quote?.networkFee?.amount || priceObj?.quote?.networkFee?.amount == 0 ? Number(priceObj?.quote?.networkFee?.amount).toFixed(2) : "---"}{' '}
                            {priceObj?.quote?.networkFee?.currency ? priceObj?.quote?.networkFee?.currency : ""}
                        </div>
                    </div>
                </div>
            </div>

            <SelectCurrency
                setCurrency={buyCurrencyAction}
                currency={currency}
                setShowCurrency={setShowCurrency}
                showCurrency={showCurrency}
            />
            <SelectNetwork
                setShowNetwork={setShowNetwork}
                showNetwork={showNetwork}
                network={network}
                asset={asset}
                setNetwork={buyNetworkAction}
                setAsset={buyAssetAction}
            />
            <SelectWallet
                setSelectWalletOpen={setSelectWalletOpen}
                selectWalletOpen={selectWalletOpen}
                setAccount={walletBuyAction}
                account={account}
                metamask={metamask}
                setMetaMask={metamaskBuyAction}
            />

            {/* <KadoIframe
                showKado={showKado}
                setShowKado={setShowKado}
            /> */}
            <KadoIframeComp
                iframeOpen={showKado}
                setIframeOpen={setShowKado}
                amount={amount}
                asset={asset}
                account={account}
                // metamask={metamask}
                network={network}
                currency={currency}
            />

        </>
    )
}

export default BuyTab