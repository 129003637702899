// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/auth";
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  RecaptchaVerifier, signInWithPhoneNumber
} from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBs7ayolnh-EcIgiO7vyGG20MBBnqS9Apw",
  authDomain: "shinda-ki-mfalme.firebaseapp.com",
  projectId: "shinda-ki-mfalme",
  storageBucket: "shinda-ki-mfalme.appspot.com",
  messagingSenderId: "1021049497695",
  appId: "1:1021049497695:web:5a4b7440fd32470443d3ea",
  measurementId: "G-3BBWTTDJ4G"

  // apiKey: "AIzaSyB3A8Im2FIp05QELy6amOg1cz2xdnUKnjE",
  // authDomain: "fir-project-afa2b.firebaseapp.com",
  // projectId: "fir-project-afa2b",
  // storageBucket: "fir-project-afa2b.appspot.com",
  // messagingSenderId: "594611639073",
  // appId: "1:594611639073:web:bcb557c48fb162f0986460"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const provider = new GoogleAuthProvider();
export {
  auth,
  provider,
  firebase,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendPasswordResetEmail
};
