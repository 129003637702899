import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import { getAPI } from '../../../service/apiInstance';
import { useTranslation } from 'react-i18next';
import { GAME_OPTION_TABS } from '../../../constants';
import { useGames } from '../../../hooks/useGame';
import { useSelector } from 'react-redux';

const DAppUniverseSec = () => {
    const [games, setGames] = useState([])
    const [gamesLoader, setGamesLoader] = useState(true)
    const [sports, setSports] = useState([])
    const [sportloader, setSportLoader] = useState(true)
    const { t } = useTranslation()
    const casinoLabels = t("casinoLabels", { returnObjects: true })
    const navigate = useNavigate()
    const {setGameObject } = useGames()
    const sportsBook = useSelector(state=>state.sportsBook)
    const topRatedGames = useSelector(state=>state.topRatedGames)
    const [query, setQuery] = useSearchParams()






    // get api for games
    const getGames = async () => {
        setGamesLoader(true)
        try {
            const data = topRatedGames?.slice(0,4)
            setGames(data)
        } catch (error) {
        } finally {
            setGamesLoader(false)
        }
    }

    const getSports = async () => {
        setSportLoader(true)
        try {
            const data = sportsBook?.slice(1, 5)
            setSports(data)
        } catch (error) {
        } finally {
            setSportLoader(false)
        }
    }

    useEffect(()=> {
        getSports()
    }, [sportsBook])

    useEffect(() => {
        getGames()
    }, [topRatedGames])



    return (
        <>
            <div className="container-fluid dAppUniverseSection d-none d-lg-block px-0">
                <div className="dAppUniverseInner">
                    <div className="row mb-2 aligniitems-center">
                        <div className="col">
                            <div className="d-flex align-items-center dambHeading">
                                <img src="./assets/img/dAppUniverse.png" className="me-3" alt="" />
                                {casinoLabels.DAPP_UNIVERSE}
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex align-items-center">
                                <div className="dAppUniversePrev me-2 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path d="M12.1505 0.370117C18.6778 0.370117 23.9692 5.6871 23.9692 12.246C23.9692 18.8047 18.6778 24.1218 12.1505 24.1218C5.62313 24.1218 0.331718 18.8047 0.331718 12.246C0.331718 5.6871 5.62313 0.370117 12.1505 0.370117ZM8.54907 13.0503C8.60666 13.1082 8.66985 13.1571 8.73565 13.1996L13.1712 17.6566C13.3866 17.8732 13.669 17.9815 13.9514 17.9815C14.2338 17.9815 14.516 17.8732 14.7316 17.6566C15.1626 17.2235 15.1626 16.5213 14.7316 16.0884L10.9095 12.2472L14.7124 8.42598C15.1434 7.99267 15.1434 7.29065 14.7124 6.85774C14.2814 6.42467 13.5827 6.4249 13.1517 6.8575L8.73572 11.2946C8.66993 11.3371 8.60674 11.3859 8.54938 11.4439C8.32908 11.6652 8.22279 11.9568 8.22775 12.2472C8.22255 12.5373 8.32861 12.8288 8.54907 13.0503Z" fill="#212632"/>
                                        </svg>
                                </div>
                                <div className="dAppUniverseNext cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M12.1061 0.370117C5.57877 0.370117 0.287354 5.6871 0.287354 12.246C0.287354 18.8047 5.57877 24.1218 12.1061 24.1218C18.6335 24.1218 23.9249 18.8047 23.9249 12.246C23.9249 5.6871 18.6335 0.370117 12.1061 0.370117ZM15.7075 13.0503C15.6499 13.1082 15.5867 13.1571 15.5209 13.1996L11.0854 17.6566C10.8699 17.8732 10.5876 17.9815 10.3052 17.9815C10.0228 17.9815 9.74055 17.8732 9.52497 17.6566C9.09398 17.2235 9.09398 16.5213 9.52497 16.0884L13.3471 12.2472L9.5442 8.42598C9.11321 7.99267 9.11321 7.29065 9.5442 6.85774C9.97519 6.42467 10.6739 6.4249 11.1049 6.8575L15.5209 11.2946C15.5867 11.3371 15.6498 11.3859 15.7072 11.4439C15.9275 11.6652 16.0338 11.9568 16.0288 12.2472C16.034 12.5373 15.928 12.8288 15.7075 13.0503Z" fill="#212632"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Swiper className="swiper daPPUniverseSwiper"
                        navigation={{
                            nextEl: ".dAppUniverseNext",
                            prevEl: ".dAppUniversePrev",
                        }}
                        modules={[Navigation]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1400: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                        }}>
                        <SwiperSlide className="swiper-slide">
                            <div className="casinoCard">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon" style={{marginBottom: 19}}>  <img src="assets/img/app/casino.svg" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle"> {casinoLabels.CASINO} </div>
                                                <span className="casinoSubTitle ms-lg-2 ">{casinoLabels.Play_games}</span>
                                            </div>
                                            <div className=" col-lg col-12">
                                                <ul className="list-unstyled d-flex gap-2 mb-0 justify-content-end">
                                                    {!gamesLoader ?
                                                        games?.length > 0 ?
                                                            games?.map((item) => (
                                                                <li key={item?.gameData?._id}>
                                                                    <div className="casinoImg rounded-2"><img src={item?.image} alt="logo" className="img-fluid rounded-2 w-100 h-100" /> </div>
                                                                </li>
                                                            ))
                                                            : 'no data'
                                                        : 'loader'}
                                                </ul>
                                            </div>
                                            {/* <div className="col-lg-12 col-12">
                                                <span className="casinoSubTitle ms-lg-2 ">{casinoLabels.Play_games}</span>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pera"> {casinoLabels.Play_more}  </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row align-items-end gap-lg-0 gap-2">
                                            {/* <div className=" col-lg-auto col-12">
                                                <ul className="list-unstyled d-flex gap-2 mb-0">
                                                    {!gamesLoader ?
                                                        games?.length > 0 ?
                                                            games?.map((item) => (
                                                                <li key={item?.gameData?._id}>
                                                                    <div className="casinoImg rounded-2"><img src={item?.image} alt="logo" className="img-fluid rounded-2 w-100 h-100" /> </div>
                                                                </li>
                                                            ))
                                                            : 'no data'
                                                        : 'loader'}
                                                </ul>
                                            </div> */}
                                            <div className="col-lg col pe-0">
                                                <div
                                                    onClick={() => {
                                                        setGameObject((prev) => ({
                                                          selectedTab: GAME_OPTION_TABS?.LOBBY
                                                        }))
                                                        // navigate(ROUTES_CONST.GAME_PAGE)
                                                        setQuery({ q: "lobby" })
                                                        navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('lobby')}`)
                                                      }}
                                                    className="text-decoration-none cursor-pointer d-flex align-items-center justify-content-start justify-content-start gameBtn"
                                                >
                                                    {casinoLabels.Go_to_Games} <span className="icon d-inline-flex"> <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" /></span> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="casinoCard">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon" style={{marginBottom: 19}}>  <img src="assets/img/footballYellow.png" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle"> {casinoLabels.SPORTS} </div>
                                                <span className="casinoSubTitle ms-lg-2 ">{casinoLabels.Bet_on_sports}</span>
                                            </div>
                                            {/* <div className="col-lg-auto col-12">
                                                <span className="casinoSubTitle ms-lg-2 ">{casinoLabels.Bet_on_sports}</span>
                                            </div> */}
                                             <div className=" col-lg col-12">
                                                <ul className="list-unstyled d-flex gap-2 mb-0 justify-content-end">

                                                    {!sportloader ?
                                                        sports?.length > 0 ?
                                                            sports?.map((item) => (
                                                                <li key={item?.id}>
                                                                    <div className="casinoImg rounded-2"><img src={item?.image} alt="logo" className="img-fluid rounded-2 w-100 h-100 object-fit-contain" /> </div>
                                                                </li>
                                                            ))
                                                            : 'no data'
                                                        : 'loader'}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pera"> {casinoLabels.More_than} </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="row align-items-end gap-lg-0 gap-2">
                                            {/* <div className=" col-lg-auto col-12">
                                                <ul className="list-unstyled d-flex gap-2 mb-0">

                                                    {!sportloader ?
                                                        sports?.length > 0 ?
                                                            sports?.map((item) => (
                                                                <li key={item?.id}>
                                                                    <div className="casinoImg rounded-2"><img src={item?.image} alt="logo" className="img-fluid rounded-2 w-100 h-100 object-fit-contain" /> </div>
                                                                </li>
                                                            ))
                                                            : 'no data'
                                                        : 'loader'}
                                                </ul>
                                            </div> */}
                                            <div className="col-lg col pe-0">
                                                <Link to={ROUTES_CONST.SPORTS} className="text-decoration-none d-flex align-items-center justify-content-start gameBtn">{casinoLabels.Go_to_Sports}
                                                    <span className="icon d-inline-flex">
                                                        <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" />
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide className="swiper-slide">
                            <div className="casinoCard">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon">  <img src="assets/img/app/casino.svg" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle"> {casinoLabels.CASINO} </div>
                                            </div>
                                            <div className="col-lg-auto col-12">
                                                <span className="casinoSubTitle ms-lg-2 "> {casinoLabels.Play_games}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pera"> {casinoLabels.Play_more}  </div>
                                    </div>
                                    <div className="col-12 pt-lg-3 pt-1">
                                        <div className="row align-items-end gap-lg-0 gap-2">
                                            <div className=" col-lg-auto col-12">
                                                <ul className="list-unstyled d-flex gap-2 mb-0">
                                                    {!gamesLoader ?
                                                        games?.length > 0 ?
                                                            games?.map((item) => (
                                                                <li key={item?.gameData?._id}>
                                                                    <div className="casinoImg rounded-2"><img src={item?.image} alt="logo" className="img-fluid rounded-2 w-100 h-100" /> </div>
                                                                </li>
                                                            ))
                                                            : 'no data'
                                                        : 'loader'}
                                                </ul>
                                            </div>
                                            <div className="col-lg col pe-0">
                                                <div 
                                                    onClick={() => {
                                                        setGameObject((prev) => ({
                                                          selectedTab: GAME_OPTION_TABS?.LOBBY
                                                        }))
                                                        // navigate(ROUTES_CONST.GAME_PAGE)
                                                        setQuery({ q: "lobby" })
                                                        navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('lobby')}`)
                                                      }}
                                                className="text-decoration-none cursor-pointer d-flex align-items-center justify-content-lg-end justify-content-start gameBtn">
                                                    {casinoLabels.Go_to_Games}
                                                    <span className="icon d-inline-flex"> <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" /></span> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default DAppUniverseSec