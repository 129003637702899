import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas'
import './../../style.scss'
import CashbackBonusDetails from './components/CashbackBonusDetails';
import RackBack from './components/RackBack';
import WeeklyBonus from './components/WeeklyBonus';
import MonthlyBonus from './components/MonthlyBonus';
import CashBackClaim from '../CashBackClaim';

const BonusDeatilOffcanvas = ({show, setShow, showData}) => {
    const handleClose = () => setShow(false);

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} className='bonusOffcanvas' placement={'end'}>
                <div className="row offcanvasHeaderWrapper align-items-start mx-0">
                    <div className="col px-0">
                        <div className='d-flex flex-column justify-content-start align-items-start offcanvasHeader'>
                            <div className='title'>Bonus Details</div>
                            <div className='subTitle'>
                                {
                                    showData === 'cashback' ? 'Cashback': 
                                    showData === 'rakeback' ? 'Rakeback + Boost' : 
                                    showData === 'weekly' ? 'Weekly Bonus': 
                                    showData === 'monthly' ? 'Monthly Bonus':  ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-auto pe-0">
                        <div className="close" onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                            <path d="M12.2062 2.42655C12.549 2.08379 12.549 1.52806 12.2062 1.1853C11.8635 0.842531 11.3077 0.842531 10.965 1.1853L6.23167 5.91865L1.49833 1.1853C1.15557 0.842531 0.599835 0.842531 0.257076 1.1853C-0.0856919 1.52806 -0.0856919 2.08379 0.257076 2.42655L4.99043 7.15989L0.257076 11.8932C-0.0856919 12.236 -0.0856919 12.7917 0.257076 13.1345C0.599835 13.4772 1.15557 13.4772 1.49833 13.1345L6.23167 8.40113L10.965 13.1345C11.3077 13.4772 11.8635 13.4772 12.2062 13.1345C12.549 12.7917 12.549 12.236 12.2062 11.8932L7.47291 7.15989L12.2062 2.42655Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <Offcanvas.Body className='offcanvasBodyWrapper'>
                {
                    showData === 'cashback' ? <CashbackBonusDetails/> : 
                    showData === 'rakeback' ? <RackBack/> : 
                    showData === 'weekly' ? <WeeklyBonus/>: 
                    showData === 'monthly' ? <MonthlyBonus/>:  ''
                }
                    
                </Offcanvas.Body>
                <div>
                    <div className="row btnPadding mx-0">
                        <div className="col-12 px-0">
                            <button className='closeBtn' onClick={handleClose}>Close</button>
                        </div>
                    </div>
                </div>
            </Offcanvas>
        </>
    )
}

export default BonusDeatilOffcanvas