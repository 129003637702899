export const ROUTES_CONST = {
    INDEX: '/',
    CASINO: '/casino',
    SPORTS: '/sports',
    ACCOUNT: '/accounts',
    GIVEAWAY: '/giveaway',
    AFFILIATE: '/affiliate',
    AFFILIATE_DASHBOARD: '/affiliate-dashboard',
    VIP_CLUB: '/vip-club',
    PROMOTIONS: "/promotions",
    FAIRNESS: "/fairness",
    FORUM: "/forum",
    BLOG: "/blog",
    NEWS: "/news",
    PARTNERS: "/partners",
    GLOBAL_SETTINGS: "/global-settings",
    Help_center: "/help-center",
    AML_POLICY: "/aml-policy",
    Responsible_Gamble: "/responsible-gamble",
    Terms_Conditions: '/terms-conditions',
    // SLOT_PAGE: '/slotpage',
    FAVOURITE_PAGE: '/favourites',
    Recent_Games: '/recent',
    // SLOT_PAGE: '/all-games',
    SLOT_PAGE: '/games',
    PLAY_SLOT: '/playslot',
    BUY_CRYPTO_URL: 'https://buy.onramper.com/',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    RESPONSIBLE_GAMBLING: '/responsible-gambling',
    NEW_AML_POLICY: '/amlpolicy',
    DOWNLOAD_APP: '/download-app',
    SPONSERSHIP_PAGE: '/sponsorship',
    AFFILIATE_PAGE: '/affiliate-page',
    SPORTS_POLICY: '/sports-policy',
    PROVIDERS: '/providers',
    PROMOTION_DETAIL: '/promotion-detail',
    FAQ_PAGE : '/faq',
    GAME_PAGE : '/games',
    DGN_TOKEN : '/dgw-token',
    DGW_STAKING : '/dgw-staking',
    LOTTERY : '/lottery',
    GAME_ERROR:"/game-error",
    POKER:"/poker",
    CRYPTO_STACKING: '/crypto-staking'

}