import React, { useEffect, useState } from 'react'
import './style.scss'
import calendar from '../../assets/img/calendar (1) 1.png'
import levelUp from '../../assets/img/levelUp.png'
import daily from '../../assets/img/dailyBonus.png'
import weekly from '../../assets/img/weeklyBonus.png'
import monthly from '../../assets/img/monthlyBonus.png'
import rackback from '../../assets/img/rakeBack.png'
import wheel from '../../assets/img/wheelSpin.png'
import spins from '../../assets/img/wheelSpin.png'
import free from '../../assets/img/freeSpin.png'
import { useBonus } from '../../hooks/useBonus'
import { BONUS_DROPDOWN_OPTIONS, CLAIM_BONUS_OPTIONS } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import { useSelector } from 'react-redux'
import { getAPIAuth } from '../../service/apiInstance'
import { useAuth } from '../../hooks/useAuth'
import TimerComponent from '../newWallet/components/TimerComponent'
import { useClaimBonus } from '../../hooks/useClaimBonus'

const BonusDropdown = ({ closeBonusDropdown }) => {
    const { setBonusObject } = useBonus()
    const navigate = useNavigate()
    // freeSpinLeft
    const userDetails = useSelector(state => state.userDetails)
    const [listLoader, setListLoader] = useState(true)
    const [bonusData, setBonusData] = useState({})
    const [lockStatus, setLockStatus] = useState({})
    const [width, setWidth] = useState(0)
    const userBalance = useSelector(state => state.userBalance)
    const { token } = useAuth();
    const userBonusDetail = useSelector(state => state.userBonusDetail)
    const {setClaimBonusObject} = useClaimBonus()


    // get cashback data
    const getCashbackData = async () => {
        setListLoader(true)
        try {
            const res = await getAPIAuth(`user/get-userbonus-stats`, token)
            if (res?.data?.success) {
                setLockStatus(res.data?.lockStatus)
                setBonusData(res?.data?.data?.[0])
            }
        } catch (error) {

        }
        finally {
            setListLoader(false)
        }
    }
    useEffect(() => {
        if (token) {
            getCashbackData()
        }
    }, [token])

    const handleOpenBonusDetail = () => {
        setBonusObject(prev => ({
            ...prev,
            selectedTab: BONUS_DROPDOWN_OPTIONS.BONUS_DETAIL
        }))
    }

    useEffect(() => {
        const ll = userDetails?.nextlevel?.min_amount
        const percentage = (userDetails?.wagerAmount / ll) * 100
        setWidth(percentage)
    }, [userDetails?.wagerAmount, userDetails?.nextlevel?.min_amount])

    const openClaimModal = (bonusType)=> {
        closeBonusDropdown()
        navigate(ROUTES_CONST.VIP_CLUB)
        setClaimBonusObject(prev=>({
            ...prev,
            isClaimBonusOpen : true,
            selectedTab : bonusType
        }))
    }

    return (

        <>
            <div className="bonusDropDownCloseLayer" onClick={closeBonusDropdown}></div>
            <div className="bonusDropdownWrapper h-100 bonusDropModal">
                <div className="row mx-0 h-100 bonusDropModalInner flex-nowrap flex-column">
                    <div className="col-12 bonusDropModalHeader">
                        <div className="row mx-0">
                            <div className="col-12 d-flex align-items-center justify-content-between px-0">
                                <div className="heading">BONUS</div>
                                <div className="closeBtn cursor-pointer" onClick={closeBonusDropdown}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <path d="M9.22987 1.13297C9.48903 0.873792 9.48903 0.45357 9.22987 0.19439C8.9707 -0.0647967 8.55046 -0.0647967 8.29129 0.19439L4.71213 3.77356L1.13297 0.19439C0.873792 -0.0647967 0.45357 -0.0647967 0.19439 0.19439C-0.0647967 0.45357 -0.0647967 0.873792 0.19439 1.13297L3.77356 4.71213L0.19439 8.29129C-0.0647967 8.55046 -0.0647967 8.9707 0.19439 9.22987C0.45357 9.48903 0.873792 9.48903 1.13297 9.22987L4.71213 5.65071L8.29129 9.22987C8.55046 9.48903 8.9707 9.48903 9.22987 9.22987C9.48903 8.9707 9.48903 8.55046 9.22987 8.29129L5.65071 4.71213L9.22987 1.13297Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="subHeading">Rewards are money refund calculated based on core token
                                    wager amounts. Core tokens are marked with golden tick.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col flex-fill px-0 bonusDropModalBody">
                        
                    <div className={`row bonusList align-items-center mx-0 ${userBonusDetail?.lockedStatus?.freeSpinStatus === "disabled" ? 'locked' : ''}`}>
                            {/* <div className={`row bonusList align-items-center mx-0 ${userDetails?.freeSpinLeft?"":""}`}> */}
                            <div className="col ps-0">
                                <div className="row mx-0 align-items-center">
                                    <div className="col-auto px-0"><div className="icon">
                                        <img src={free} alt="" />
                                        </div></div>
                                    <div className="col pe-0">
                                        <div className="text">Free Spins</div>
                                        <div className="textGreen d-flex align-items-end">
                                            {
                                                userBonusDetail?.lockedStatus?.freeSpinStatus === 'play' ?
                                                    <>
                                                        {userBonusDetail?.bonusData?.freeSpinAmount ? <>`You have {userBonusDetail?.bonusData?.freeSpinAmount} {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} ` <br /> </> : ""}
                                                        {userBonusDetail?.bonusData?.freeSpinLeft ? userBonusDetail?.bonusData?.freeSpinLeft : 0}/100 Spins remaining
                                                    </> :
                                                    userBonusDetail?.lockedStatus?.freeSpinStatus === 'claim' ?
                                                        <>
                                                            you have {userBonusDetail?.bonusData?.freeSpinAmount ? userBonusDetail?.bonusData?.freeSpinAmount : 0.00} {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}  <br />
                                                            {userBonusDetail?.bonusData?.freeSpinLeft ? userBonusDetail?.bonusData?.freeSpinLeft : 0}/100 Spins remaining

                                                        </> : 'you have 0/100 spins remaning'
                                            }
                                            <div className="iconnn cursor-pointer ms-1" onClick={handleOpenBonusDetail}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8" fill="none">
                                                    <path d="M3.84783 7.69565C1.72526 7.69565 0 5.97039 0 3.84783C0 1.72526 1.72526 0 3.84783 0C5.97039 0 7.69565 1.72526 7.69565 3.84783C7.69565 5.97039 5.97039 7.69565 3.84783 7.69565ZM3.84783 0.536906C2.02235 0.536906 0.536906 2.02235 0.536906 3.84783C0.536906 5.67331 2.02235 7.15875 3.84783 7.15875C5.67331 7.15875 7.15875 5.67331 7.15875 3.84783C7.15875 2.02235 5.67331 0.536906 3.84783 0.536906Z" fill="#42FF00" />
                                                    <path d="M3.84755 4.4743C3.7008 4.4743 3.5791 4.3526 3.5791 4.20584V2.41616C3.5791 2.2694 3.7008 2.14771 3.84755 2.14771C3.99431 2.14771 4.11601 2.2694 4.11601 2.41616V4.20584C4.11601 4.3526 3.99431 4.4743 3.84755 4.4743Z" fill="#42FF00" />
                                                    <path d="M3.84817 5.63742C3.80164 5.63742 3.75511 5.62669 3.71216 5.60879C3.6692 5.59089 3.62983 5.56584 3.59404 5.53362C3.56182 5.49783 3.53677 5.46204 3.51887 5.4155C3.50097 5.37255 3.49023 5.32602 3.49023 5.27949C3.49023 5.23296 3.50097 5.18642 3.51887 5.14347C3.53677 5.10052 3.56182 5.06115 3.59404 5.02535C3.62983 4.99314 3.6692 4.96808 3.71216 4.95019C3.79806 4.91439 3.89828 4.91439 3.98419 4.95019C4.02714 4.96808 4.06651 4.99314 4.10231 5.02535C4.13452 5.06115 4.15958 5.10052 4.17747 5.14347C4.19537 5.18642 4.20611 5.23296 4.20611 5.27949C4.20611 5.32602 4.19537 5.37255 4.17747 5.4155C4.15958 5.46204 4.13452 5.49783 4.10231 5.53362C4.06651 5.56584 4.02714 5.59089 3.98419 5.60879C3.94124 5.62669 3.8947 5.63742 3.84817 5.63742Z" fill="#42FF00" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0">
                                {
                                    userBonusDetail?.lockedStatus?.freeSpinStatus === 'claim' ?
                                        <div className='d-flex flex-column align-items-end justify-content-end'>
                                            <button className="bonusBtn"
                                                onClick={() => {
                                                    // if (!userDetails?.freeSpinLeft) {
                                                        // if (true) {
                                                        setBonusObject(prev => ({
                                                            ...prev,
                                                            selectedTab: BONUS_DROPDOWN_OPTIONS.FREE_SPIN_BONUS_CLAIM
                                                        }))
                                                    // }
                                                }}>
                                                claim
                                            </button>
                                            <div className="lefttYMtxtttt">

                                                <TimerComponent targetTime={userBonusDetail?.bonusData?.spinTimmer} isBonusDropdown={true} />
                                            </div>
                                        </div>
                                        :
                                        <button className="bonusBtn"
                                            onClick={() => {
                                                if (userDetails?.freeSpinLeft) {
                                                    // if (true) {
                                                    setBonusObject(prev => ({
                                                        ...prev,
                                                        selectedTab: BONUS_DROPDOWN_OPTIONS.FREE_SPIN_GAMES
                                                    }))
                                                }
                                            }}>
                                            play
                                        </button>

                                }
                            </div>
                        </div>
                        <div className={`row bonusList align-items-center mb-2 mx-0 ${userBonusDetail?.lockedStatus?.dailyBonnus ? '' : 'locked'}`}>
                            <div className="col ps-0">
                                <div className="row mx-0 align-items-center">
                                    <div className="col-auto px-0">
                                        <div className="icon">
                                            <img src={calendar} alt="" />
                                        </div>
                                    </div>
                                    <div className="col pe-0">
                                        <div className="text">CALENDAR</div>
                                        {
                                            userBonusDetail?.bonusData?.dailyBonnus ?
                                                <div className="textGreen">Pending: {!isNaN(Number(userBonusDetail?.bonusData?.dailyBonnus)) ? Number(userBonusDetail?.bonusData?.dailyBonnus).toFixed(2) : "0.0"} KSH</div>
                                                : ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0">
                                <button onClick={()=>openClaimModal(CLAIM_BONUS_OPTIONS?.DAILY_BONUS)} className="bonusBtn">
                                    CLAIM
                                </button>
                            </div>
                        </div>
                        <div className="row bonusList align-items-center mb-2 mx-0 locked">
                            <div className="col ps-0">
                                <div className="row mx-0 align-items-center">
                                    <div className="col-auto px-0">
                                        {/* <div className="icon">
                                            <img src={levelUp} alt="" />
                                        </div> */}
                                        <div class="iconProgress" style={{ "--value": width + '%' }}>
                                            <span>{Number(width)?.toFixed(1)}%</span>
                                            <progress value={width} min="0" max="100">{Number(width)?.toFixed(1)}%</progress>
                                        </div>
                                    </div>
                                    <div className="col pe-0">
                                        <div className="text">LEVEL -UP BONUS</div>
                                        <div className="textGreen d-flex align-items-center">Level {Number(userDetails?.level) + 1} <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                                            <path d="M4.35778 8.46167C4.23968 8.46235 4.12406 8.42785 4.02565 8.36257C3.92724 8.29729 3.8505 8.20418 3.80522 8.09511C3.75993 7.98605 3.74815 7.86596 3.77138 7.75018C3.79461 7.63439 3.85179 7.52814 3.93564 7.44498L7.08677 4.2998L3.93564 1.15462C3.83824 1.04088 3.78734 0.894578 3.79312 0.744944C3.7989 0.59531 3.86093 0.453367 3.96682 0.34748C4.07271 0.241594 4.21465 0.179563 4.36428 0.173783C4.51392 0.168004 4.66022 0.218901 4.77396 0.316304L8.34127 3.88362C8.45201 3.99501 8.51416 4.1457 8.51416 4.30277C8.51416 4.45985 8.45201 4.61054 8.34127 4.72193L4.77396 8.28924C4.66322 8.39908 4.51375 8.46101 4.35778 8.46167Z" fill="#42FF00" />
                                            <path d="M0.790392 8.46141C0.6723 8.4621 0.556678 8.4276 0.458268 8.36232C0.359858 8.29704 0.283118 8.20393 0.237833 8.09486C0.192548 7.98579 0.180769 7.86571 0.203998 7.74992C0.227227 7.63414 0.284411 7.52789 0.368261 7.44473L3.51939 4.29955L0.368261 1.15437C0.256304 1.04241 0.193408 0.890569 0.193408 0.732238C0.193408 0.573908 0.256304 0.422063 0.368261 0.310107C0.480217 0.19815 0.632062 0.135254 0.790392 0.135254C0.948723 0.135254 1.10057 0.19815 1.21252 0.310107L4.77984 3.87742C4.89057 3.98881 4.95273 4.1395 4.95273 4.29658C4.95273 4.45365 4.89057 4.60434 4.77984 4.71574L1.21252 8.28305C1.15746 8.33922 1.0918 8.38392 1.01933 8.41453C0.946873 8.44515 0.869056 8.46109 0.790392 8.46141Z" fill="#42FF00" />
                                        </svg>Level {Number(userDetails?.nextlevel?.level) + 1}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0">
                                <button className="bonusBtn">add</button>
                            </div>
                        </div>
                        <div className={`row bonusList align-items-center mb-2 mx-0  ${userBonusDetail?.lockedStatus?.dailyBonnus ? "" : "locked"}`}>
                            <div className="col ps-0">
                                <div className="row mx-0 align-items-center">
                                    <div className="col-auto px-0"><div className="icon"><img src={daily} alt="" /></div></div>
                                    <div className="col pe-0">
                                        <div className="text">DAILY BONUS</div>
                                        {/* <div className="textGreen">Pending: 0.00 KSH</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0">
                                <button
                                    onClick={()=>openClaimModal(CLAIM_BONUS_OPTIONS?.DAILY_BONUS)}
                                    className="bonusBtn">{userBonusDetail?.lockedStatus?.dailyBonnus ? "claim" : "add"}</button>
                            </div>
                        </div>
                        <div className={`row bonusList align-items-center mb-2 mx-0  ${userBonusDetail?.lockedStatus?.weeklyBonus?.status ? "" : "locked"}`}>
                            <div className="col ps-0">
                                <div className="row mx-0 align-items-center">
                                    <div className="col-auto px-0"><div className="icon"><img src={weekly} alt="" /></div></div>
                                    <div className="col pe-0">
                                        <div className="text">WEEKLY BONUS</div>
                                        {/* <div className="textGreen">Pending: 0.00 KSH</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0">
                                <button onClick={()=>openClaimModal(CLAIM_BONUS_OPTIONS?.WEEKLY_BONUS)} className="bonusBtn">
                                    {userBonusDetail?.lockedStatus?.weeklyBonus?.status ? "claim" : "add"}
                                </button>
                            </div>
                        </div>
                        <div className={`row bonusList align-items-center mb-2 mx-0 ${userBonusDetail?.lockedStatus?.monthlyBonus?.status ? "" : "locked"}`}>
                            <div className="col ps-0">
                                <div className="row mx-0 align-items-center">
                                    <div className="col-auto px-0"><div className="icon"><img src={monthly} alt="" /></div></div>
                                    <div className="col pe-0">
                                        <div className="text">MONTHLY BONUS</div>
                                        {/* <div className="textGreen">Pending: 0.00 KSH</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0 d-flex flex-column justify-content-end align-items-end">
                                <button onClick={()=>openClaimModal(CLAIM_BONUS_OPTIONS?.MONTHLY_BONUS)} className="bonusBtn">
                                    {userBonusDetail?.lockedStatus?.monthlyBonus?.status ? "claim" : "add"}
                                </button>
                                {/* {userBonusDetail?.lockedStatus?.monthlyBonus?.status ? 
                                <div className="lefttYMtxtttt">
                                    <TimerComponent targetTime={userBonusDetail?.lockedStatus?.monthlyBonus?.data?.[0]?.expireDate} isBonusDropdown={true} />
                                </div>: ''} */}
                            </div>
                        </div>
                        <div className={`row bonusList align-items-center mx-0 ${userBonusDetail?.lockedStatus?.rakeBack ? "" : "locked"}`}>
                            <div className="col ps-0">
                                <div className="row mx-0 align-items-center">
                                    <div className="col-auto px-0"><div className="icon"><img src={rackback} alt="" /></div></div>
                                    <div className="col pe-0">
                                        <div className="text">RAKEBACK</div>
                                        <div className="textGreen">Pending: 0.00 KSH</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0">
                                <button className="bonusBtn">add</button>
                            </div>
                        </div>
                        <div className="row bonusList align-items-center mx-0 locked">
                            <div className="col ps-0">
                                <div className="row mx-0 align-items-center">
                                    <div className="col-auto px-0"><div className="icon"><img src={wheel} alt="" /></div></div>
                                    <div className="col pe-0">
                                        <div className="text">Wheel Spin</div>
                                        {/* <div className="textGreen">Pending: 0.00 KSH</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0">
                                <button className="bonusBtn">CLAIM</button>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 px-0 bonusDropModalFooter">
                        <div className="viewBtn cursor-pointer" onClick={() => {
                            closeBonusDropdown()
                            navigate(ROUTES_CONST.VIP_CLUB)
                        }}>VIEW DETAILS</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BonusDropdown