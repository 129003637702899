import React, { useEffect, useRef, useState } from "react";
import { getAPIAuth } from "../../service/apiInstance";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { currencyExchangeData, userBalanceAction } from "../../store/action";
import { useClaimBonus } from "../../hooks/useClaimBonus";
import { CLAIM_BONUS_OPTIONS } from "../../constants";
import { socket } from "../../service/socket";

const UserBalanceComp = ({ setShowUsdtDetailModal, setShowUsdtBonusModal }) => {
    const { token, setToken } = useAuth()
    const userDetails = useSelector(state => state.userDetails)
    const intervalRef = useRef()
    const location = useLocation()
    const arrPath = location.pathname.split('/')
    const userBalance = useSelector(state => state.userBalance)
    const [userBal, setUserBal] = useState(userBalance)
    const [currencyExchange, setCurrencyExcgange] = useState([])
    const navigate = useNavigate()
    const {setClaimBonusObject} = useClaimBonus()
    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false)

    const dispatch = useDispatch()
    const getUserBal = async () => {
        try {
            const res = await getAPIAuth(`user/user-balance?userId=${userDetails?.id}`, token, setToken)
            if (res?.data?.success) {
                setUserBal(res.data.data)
                dispatch(userBalanceAction(res.data.data))
            }
        } catch (error) {

        }
    }


    // to show updated user balance 
    useEffect(() => {
        if (userDetails?.id && token) {
            // polling
            // if (location.pathname === ROUTES_CONST.SPORTS || arrPath[arrPath.length - 2] === 'games') {//to call the api only on sports and all-games page

            // if (intervalRef.current) {//if previously any interval is running ,cancel it 
            //     clearInterval(intervalRef.current)
            // }
            // // start a new interval
            // intervalRef.current = setInterval(() => getUserBal(), 3000)
            // }
            // getUserBal()
        }
        // return () => clearInterval(intervalRef.current)// clean up , when component unmounts

    }, 
    // [token, userDetails?.id, location.pathname]
    [token]
)


useEffect(()=> {
    if(userDetails?.id) {
            try {
            console.log('socket userDetails?.id', userDetails?.id)

            socket.on(`userbalance-${userDetails?.id}`, (data) => {
                if (data?.status) {
                    setUserBal(data?.data)
                    dispatch(userBalanceAction(data?.data))
                }
                console.log('socket dataaaaaaaaaaaaaaaaaa', data)
            });
        } catch (error) {
            console.log('socket error', error)
        }
        }
}, [userDetails?.id])



useEffect(()=> {
    try {
        socket.on(`socket-connected`, (data) => {
            console.log('socket connected', data)
        });
    } catch (error) {
        console.log('socket connection error', error)
    }
}, [])


    // const getCurrencyExchangeData = async ()=> {
    //     try {
    //         const res = await getAPIAuth('currency/getCurrencyExchangeData', token)
    //         if(res?.data?.success) {
    //             setCurrencyExcgange(res?.data?.data)
    //             dispatch(currencyExchangeData(res?.data?.data))
    //         }
    //     } catch (error) {

    //     }
    // }

    // useEffect(()=> {
    //     if(token) {

    //         getCurrencyExchangeData()
    //     }
    // },[])
    // console.log("userBalance", { userBalance });

    return (
        <>
            <div className="usdtBtn text-decoration-none cursor-pointer dropdown-toggl"
            //  disabled={isDropdownDisabled} type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside"
             >
                <div className="row mx-0 gap-1 align-items-center">
                    <div className="col-auto px-0 d-grid">
                        <img src={userBalance?.setCurrencyData?.icon} alt="" />

                    </div>
                    <div className="col-auto px-0 d-grid">{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}</div>
                    <div className="col-auto  px-0 d-grid">
                        <div className="usdtValue newTxt">
                        {userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(5) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(5) : "0.00"}
                        </div>
                        <div className="smallUsdtTxtHover d-flex align-items-center" style={{marginTop : 3}} onClick={()=>setShowUsdtBonusModal(true)} onMouseEnter={()=> setIsDropdownDisabled(true)} onMouseLeave={()=> setIsDropdownDisabled(false)}>
                            <div className="smallUsdtTxt" >
                                <img
                                    src="assets/img/header/lockYellow.png" alt="" />
                                {/* {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}  */}
                                Bonus {" "}
                                {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                            </div>
                            <div className="usdtHoverBox" onClick={(e)=> {
                                e.stopPropagation();
                                // navigate(ROUTES_CONST.VIP_CLUB)
                                setShowUsdtBonusModal(true)
                                // setClaimBonusObject(prev=>({
                                //     ...prev,
                                //     isClaimBonusOpen : true,
                                //     selectedTab : CLAIM_BONUS_OPTIONS?.CASHBACK_BONUS
                                // }))
                            } } onMouseEnter={()=> setIsDropdownDisabled(true)} onMouseLeave={()=> setIsDropdownDisabled(false)}>
                                <div className="currency d-flex align-items-center gap-1">
                                    <img src={userBalance?.setCurrencyData?.icon} alt="" />
                                    <span>{userBalance?.setCurrencyData?.name}</span>
                                    {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}
                                </div>
                                <div className="ms-3">
                                    <div className="mainCurr" style={{paddingLeft: 6}}>
                                        {userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(5) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(5) : "0.00"}
                                    </div>
                                    <div className="locCurr" style={{marginTop: 3}}>
                                        <img src="assets/img/header/lockYellow.png" className="object-fit-contain" alt="" />
                                        {/* {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}  */}
                                        Bonus {" "}
                                        {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserBalanceComp;



