import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { getAPIAuth } from "../../service/apiInstance";
import SkeletonLoader from "../skeletonLoader/SkeletonLoader";

const ProviderSlider = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const navigate = useNavigate()
  const { t } = useTranslation()
  const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true })
  const commonText = t("commonText", { returnObjects: true })
  const casinoLabels = t("casinoLabels", { returnObjects: true })
  
  const getData = async () => {
    try {
      const res = await getAPIAuth("games/get-all-providers?limit=10");
      if (res.data.success) {
        setProviders(res.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="gamesPage">
      <div className="container-fluid position-relative providerSection mt-0 toprated-games-main toprated-games-mainLive mb-4 mb-lg-5">
        <div className="row toprated-games position-relative ">
          <div className="col-12 px-0">
            <div className="d-flex align-items-center justify-content-between">
              <div className="gamesHeading d-flex align-items-center gap-2">
                <span className="gamesHeadingIcon">
                  <img
                    src="assets/img/sidebar/provider.png"
                    alt="related games"
                    className="h-100 w-100  img-fluid"
                  />
                </span>
                {PlaySlotLabels.PROVIDERS}
              </div>
              {/* <NavLink to={ROUTES_CONST.PROVIDERS} className="viewAll">
              {PlaySlotLabels.View_All}
            </NavLink> */}
              <div className="col-auto seeAllBtn">
                <span
                 className='cursor-pointer'
                  onClick={() => navigate(ROUTES_CONST.PROVIDERS)}
                >
                  <span className='seeAllTitle'>{casinoLabels.See_all}</span>
                  <span className='seeAllIcon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                      <path d="M11.0351 4.01642L7.94995 0.588419C7.88413 0.515745 7.79226 0.474609 7.6949 0.474609H5.63811C5.50304 0.474609 5.38032 0.554139 5.32479 0.677547C5.26994 0.80164 5.29256 0.946302 5.38306 1.0464L8.2619 4.24541L5.38306 7.44373C5.29256 7.54451 5.26925 7.68917 5.32479 7.81258C5.38032 7.93667 5.50304 8.0162 5.63811 8.0162H7.6949C7.79226 8.0162 7.88413 7.97438 7.94995 7.90308L11.0351 4.47508C11.1524 4.34482 11.1524 4.14599 11.0351 4.01642Z" fill="#EFB90B" />
                      <path d="M6.5786 4.01642L3.4934 0.588419C3.42759 0.515745 3.33572 0.474609 3.23836 0.474609H1.18156C1.0465 0.474609 0.923779 0.554139 0.868245 0.677547C0.813397 0.80164 0.836022 0.946302 0.926521 1.0464L3.80535 4.24541L0.926521 7.44373C0.836022 7.54451 0.812712 7.68917 0.868245 7.81258C0.923779 7.93667 1.0465 8.0162 1.18156 8.0162H3.23836C3.33572 8.0162 3.42759 7.97438 3.4934 7.90308L6.5786 4.47508C6.69584 4.34482 6.69584 4.14599 6.5786 4.01642Z" fill="#EFB90B" />
                    </svg>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 games-card-main pt-3 px-0">
            <Swiper
              className="mySwiper sportsSwiper"
              // navigation={{
              //   nextEl: ".swiper-button-next-unique3",
              //   prevEl: ".swiper-button-prev-unique3",
              // }}
              navigation={{
                nextEl: ".providerNext",
                prevEl: ".providerPrev",
              }}
              modules={[Navigation,]}
              breakpoints={{
                0: {
                  slidesPerView: 3,
                  // centeredSlides: true,
                  spaceBetween: 8,
                },
                576: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
                1400: {
                  slidesPerView: 7,
                  spaceBetween: 10,
                },
              }}
            >
              <div className="swiper-wrapper">
                {isLoading ? (
                              [1,1,1,1,1,1,1,1,1,1]?.map((item) => (
                                <SwiperSlide
                                  key={item?._id}
                                  className="swiper-slide cursor-pointer"
                                >
                                  <SkeletonLoader type={'provider'}/>
                                </SwiperSlide>
                              ))
                ) : providers?.length === 0 ? (
                  <div className="w-100 h-100">
                    <h6 className="text-light">{commonText.NoDataAvailable}</h6>
                  </div>
                ) : (
                  providers?.map((item) => (
                    <SwiperSlide
                      key={item?._id}
                      className="swiper-slide cursor-pointer"
                      // onClick={() => navigate("/all-games", { state: { provider_id: item?.provider } })}
                      onClick={() => navigate(`${ROUTES_CONST.PROVIDERS}/${item?.provider}`)}
                    >
                      <div className="providerCard d-flex align-items-center">
                        <div className="providerImg">
                          <img
                            src={item.image}
                            alt="provider"
                            className="w-100 h-100 object-fit-contain"
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                )}
              </div>
            </Swiper>
            <div className="swiper-button-prev-unique swiper-button-prev-unique1 providerPrev p-0 d-inline-flex align-items-center justify-content-center cursor-pointer swiper-button-disabled">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M12.1505 0.370117C18.6778 0.370117 23.9692 5.6871 23.9692 12.246C23.9692 18.8047 18.6778 24.1218 12.1505 24.1218C5.62313 24.1218 0.331718 18.8047 0.331718 12.246C0.331718 5.6871 5.62313 0.370117 12.1505 0.370117ZM8.54907 13.0503C8.60666 13.1082 8.66985 13.1571 8.73565 13.1996L13.1712 17.6566C13.3866 17.8732 13.669 17.9815 13.9514 17.9815C14.2338 17.9815 14.516 17.8732 14.7316 17.6566C15.1626 17.2235 15.1626 16.5213 14.7316 16.0884L10.9095 12.2472L14.7124 8.42598C15.1434 7.99267 15.1434 7.29065 14.7124 6.85774C14.2814 6.42467 13.5827 6.4249 13.1517 6.8575L8.73572 11.2946C8.66993 11.3371 8.60674 11.3859 8.54938 11.4439C8.32908 11.6652 8.22279 11.9568 8.22775 12.2472C8.22255 12.5373 8.32861 12.8288 8.54907 13.0503Z" fill="#212632" />
              </svg>
            </div>
            <div className="swiper-button-next-unique swiper-button-next-unique1 providerNext p-0 d-inline-flex align-items-center justify-content-center cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M12.1061 0.370117C5.57877 0.370117 0.287354 5.6871 0.287354 12.246C0.287354 18.8047 5.57877 24.1218 12.1061 24.1218C18.6335 24.1218 23.9249 18.8047 23.9249 12.246C23.9249 5.6871 18.6335 0.370117 12.1061 0.370117ZM15.7075 13.0503C15.6499 13.1082 15.5867 13.1571 15.5209 13.1996L11.0854 17.6566C10.8699 17.8732 10.5876 17.9815 10.3052 17.9815C10.0228 17.9815 9.74055 17.8732 9.52497 17.6566C9.09398 17.2235 9.09398 16.5213 9.52497 16.0884L13.3471 12.2472L9.5442 8.42598C9.11321 7.99267 9.11321 7.29065 9.5442 6.85774C9.97519 6.42467 10.6739 6.4249 11.1049 6.8575L15.5209 11.2946C15.5867 11.3371 15.6498 11.3859 15.7072 11.4439C15.9275 11.6652 16.0338 11.9568 16.0288 12.2472C16.034 12.5373 15.928 12.8288 15.7075 13.0503Z" fill="#212632" />
              </svg>
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div >
      </div >
    </div>
  );
};

export default ProviderSlider;
