import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import './style.scss'
import { useState } from 'react'
import { useEffect } from 'react'
import { getAPI } from '../../service/apiInstance'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import TimerComp from './components/TimerComp'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const PromotionDetail = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const promotionId = useParams()?.promotionId
  const navigate = useNavigate()
  const { t } = useTranslation()
  const promotionDetailLabels = t("promotionDetailLabels", { returnObjects: true })

  const getPromotionDetails = async () => {
    setLoading(true)
    try {
      const res = await getAPI(`get-promotion-discription?promotionId=${promotionId}`)
      setData(res?.data?.data[0])
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getPromotionDetails()
  }, [])

  useEffect(() => {
    const el = document.getElementById('contentMaterial')
    if (el) {
      el.innerHTML = data?.description
    }
  }, [data?.description])

  return (
    <main className="main vh-100" id="main">
      <div className="mainContaint">
        <div className="homepage promotionDetailPage">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="promotionBtn d-inline-flex align-items-center justify-content-center">
                <span onClick={() => navigate(ROUTES_CONST.PROMOTIONS)} className='cursor-pointer'>
                  <img src="./assets/img/promotions/promotionWhite.png" alt="provider icon" className='promotionIcon me-3' />
                  {promotionDetailLabels.PROMOTIONS}
                </span>
                <img src="./assets/img/promotions/arrowYellow.png" className='d-flex mx-3 arrowIcon' alt="" />
                <span className='cursor-pointer'>{promotionDetailLabels.Details}</span>
              </div>
            </div>
            <div className="col-12">
              <div className="promotionDetailBanner">
                <img src={data?.image} className='w-100' alt="promotion banner image" />
              </div>
            </div>
            <div className="col-12">
              <div className="row mx-0 detailBox justify-content-between">
                <div className="col-md-7 col-5  ps-0 pe-1 pe-md-3">
                  <div className="proName">{data?.text}</div>
                  <div className="grty">$ {data?.totalPrize} {promotionDetailLabels.Guaranteed_Prize}</div>
                  <div className="date">
                    <img src="./assets/img/promotions/calendar.png" alt="" />
                    {data?.endDate ? moment(data?.endDate).format('DD MMMM YYYY HH:mm:ss') : "-- ---- ---- --:--:--"}
                  </div>
                </div>
                <div className="col-7 col-md-5 col-sm-5 pe-0 ps-1">
                  <TimerComp text={promotionDetailLabels.Time_Left} endDate={data?.endDate} />
                  <div className="ttlPrz">
                    <span>{promotionDetailLabels.TOTALPRIZE} :</span>
                    <span>$ {data?.totalPrize ? Number(data?.totalPrize).toFixed(2) : '00'}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mx-0 descBox">
                <div id='contentMaterial' className="col-12 px-0 descTxt mt-3">
                  {data?.description}
                </div>
                {
                  data?.url ? 
                    <div className="col-12 d-flex justify-content-center mt-3">
                      <Link to={data?.url} target='_blank' className='promotionPlayGamesBtn text-decoration-none'>Play Games</Link>
                    </div>
                  : ''
                }
                { /* <div className="col-12 text-center partGameHeading">{promotionDetailLabels.Participating_Games}</div>
                <div className="col-12 px-0 mt-4">
                  <div className="row mx-0 gameBox justify-content-center g-3">
                    {
                      data?.games?.map((item) => (
                        <div key={item?.uuid} className="col-4 col-lg-3 mt-0 cursor-pointer" onClick={() => navigate(`${ROUTES_CONST.SLOT_PAGE}/${item?.uuid}`)}>
                          <div className="gameCard">
                            <div className="gameImg">
                              <img src={item.image} className='w-100 h-100 object-fit-contain' alt="" />
                            </div>
                            <div className="gameName">
                              {item?.name ? (item?.name.length > 11 ? item.name.slice(0, 11) + '...' : item?.name) : ("")}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </main>
  )
}

export default PromotionDetail