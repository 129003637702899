import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TimerComponent from "../../../components/newWallet/components/TimerComponent";
import { useClaimBonus } from "../../../hooks/useClaimBonus";
import { CLAIM_BONUS_OPTIONS } from "../../../constants";



const ProgressBar = (total, claimed) => {
    const width = ((claimed / total) * 100) + '%'
    return (
        <>
            <div className="progress">
                <div className="progress-bar" style={{ width: width }}></div>
            </div>
        </>
    )
}

const CashbackBonus = ({ setshow, setShowData}) => {
    const userDetails = useSelector(state => state.userDetails)
    const userBonusDetail = useSelector(state => state?.userBonusDetail)
    const {setClaimBonusObject} = useClaimBonus()

    console.log('userBonusDetail', userBonusDetail)

    return (
        <div className="row">
            <div className="col-lg-3 col-6 order-2 order-lg-1">
                {/* CASHBACK */}
                <div className="cardwraper h-100 locked">
                    <div className="row flex-column h-100">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <div className="name d-flex align-items-center">
                                CASHBACK
                                <span className="ms-2 d-flex">
                                    5%
                                </span>
                            </div>
                            <div onClick={() => {
                                setshow(true)
                                setShowData('cashback')
                            }}
                                className="exclamation"
                            >
                                <img src="assets/img/vipPage/exclamation.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 flex-fill py-2 py-sm-3">
                            <div class="ring">
                                <img src="assets/img/vipPage/ring.png" alt="" />
                            </div>
                            <div className="bonusPregressBox">
                                <div className="row mx-0 mt-2 mt-sm-3 p-2 p-sm-3">
                                    <div className="col-12 px-0">
                                        {ProgressBar(userBonusDetail?.bonusData?.cashback, userBonusDetail?.bonusData?.cashbackClaimedBonus)}
                                    </div>
                                    <div className="col px-0 bonutTxxt">
                                        Bonus :
                                    </div>
                                    <div className="col-auto px-0 bonutTxxt">
                                        {
                                            !isNaN(Number(userBonusDetail?.bonusData?.cashback)) ? (Number(userBonusDetail?.bonusData?.cashback).toFixed(2)) : '0.00'
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className="position-relative opacity-50">
                        <div className="progress-bar">
                            <progress value={85} min={0} max={100} style={{ visibility: 'hidden', height: 0, width: 0 }}>85%</progress>
                        </div>
                        <div className="progress-bar1">
                            <progress value={75} min={0} max={100} style={{ visibility: 'hidden', height: 0, width: 0 }}>75%</progress>
                        </div>
                    </div> */}
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            {
                                userBonusDetail?.lockedStatus?.cashback?.status ? (
                                    <button
                                        onClick={() => {
                                            // setshowModal(true);
                                            // setBonusType('cashback')
                                            
                                            setClaimBonusObject(prev=>({
                                                ...prev,
                                                isClaimBonusOpen : true,
                                                selectedTab : CLAIM_BONUS_OPTIONS?.CASHBACK_BONUS
                                            }))
                                        }}
                                        className={`claimClass`}>
                                        Claim in: &nbsp;
                                        <TimerComponent targetTime={userBonusDetail?.lockedStatus?.cashback?.data?.[0]?.expireDate}/>
                                    </button>
                                ) : (
                                    <button
                                        className={`claimClass locked`}>
                                        <img src="assets/img/vipPage/lockDark.png" alt="" />
                                    </button>
                                )
                            }
                            {/* <button
                                className={`claimClass locked`}
                                onClick={() => {
                                    // if (userBonusDetail?.lockedStatus?.cashback) {
                                        setshowModal(true);
                                        setBonusType('cashback')
                                    // }
                                }}
                            >
                                <img src="assets/img/vipPage/lockDark.png" alt="" />
                            </button> */}

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-12 order-1 order-lg-2 mb-4 mb-lg-0">
                <div className="row gy-4 h-100">
                    <div className="col-6">
                        {/* Deposit bonus */}
                        <div className="cardwraper flex-column h-100 d-flex">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <div className={`name ${Number(userDetails?.totalbonus) <= 0 ? "" : ""}`}>
                                    Deposit bonus
                                </div>
                                <div className={`exclamation ${Number(userDetails?.totalbonus) <= 0 ? "" : ""}`}>
                                    <img src="assets/img/vipPage/exclamation.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 py-2 py-sm-3 flex-fill d-flex justify-content-center align-items-center">
                                <div className="coinBag flex-column p-2 p-sm-3">
                                    <img src="assets/img/vipPage/coinsBag.png" alt="" />
                                    <div className="bonusPregressBox w-100">
                                        <div className="row mx-0 mt-3 mt-sm-4 g-0">
                                            <div className="col-12">
                                                {/* <div className="progress">
                                                    <div className="progress-bar" style={{ width: '25%' }}></div>
                                                </div> */}
                                                {ProgressBar(userBonusDetail?.bonusData?.depositeBonus, userBonusDetail?.bonusData?.depositeClaimedBonus)}
                                            </div>
                                            <div className="col bonutTxxt">
                                                Bonus :
                                            </div>
                                            <div className="col-auto bonutTxxt">
                                                {/* $ {userBonusDetail?.bonusData?.depositeBonus} */}
                                                {
                                                    !isNaN(Number(userBonusDetail?.bonusData?.depositeBonus)) ? (Number(userBonusDetail?.bonusData?.depositeBonus).toFixed(2)) : '0.00'
                                                }
                                                {/* $0,00 */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                {/* <button className="claimClass">CLAIM</button> */}
                                {/* {
                                    Number(userDetails?.totalbonus) <= 0 ? (

                                        <button className="claimClass locked">

                                            <img src="assets/img/vipPage/lockDark.png" alt="" />
                                        </button>
                                    ) : (
                                        <button className="claimClass">
                                            ${Number(userDetails?.totalbonus).toFixed(2)}
                                        </button>
                                    )
                                } */}
                                {userBonusDetail?.lockedStatus?.depositeBonus ? (
                                    <button className="claimClass"
                                        onClick={() => {
                                            // setshowModal(true);
                                            // setBonusType('deposit')
                                            setClaimBonusObject(prev=>({
                                                ...prev,
                                                isClaimBonusOpen : true,
                                                selectedTab : CLAIM_BONUS_OPTIONS?.DEPOSIT_BONUS
                                            }))
                                        }}
                                    >
                                        claim
                                    </button>
                                ) : (
                                    <button className="claimClass locked"
                                    >
                                        <img src="assets/img/vipPage/lockDark.png" alt="" />
                                    </button>
                                )}
                                {/* <button
                                    className={`claimClass locked`}
                                    onClick={() => {
                                        // if (userBonusDetail?.lockedStatus?.depositeBonus) {
                                            setshowModal(true);
                                            setBonusType('deposit')
                                        // }
                                    }}
                                >
                                    <img src="assets/img/vipPage/lockDark.png" alt="" />
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        {/* DAILY BONUS */}
                        <div className="cardwraper flex-column h-100 d-flex">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <div className="name ">DAILY BONUS </div>
                                <div className="exclamation "><img src="assets/img/vipPage/exclamation.png" alt="" /></div>
                            </div>
                            <div className="col-12 py-2 py-sm-3 flex-fill d-flex justify-content-center align-items-center">
                                <div className="coinBag flex-column p-2 p-sm-3">
                                    <img src="assets/img/vipPage/b1.png" alt="" />
                                    <div className="bonusPregressBox w-100">
                                        <div className="row mx-0 mt-3 mt-sm-4 g-0">
                                            <div className="col-12">
                                                {/* <div className="progress">
                                                    <div className="progress-bar" style={{ width: '25%' }}></div>
                                                </div> */}
                                                {ProgressBar(userBonusDetail?.bonusData?.dailyBonnus, userBonusDetail?.bonusData?.dailyBonusclaimed)}
                                            </div>
                                            <div className="col bonutTxxt">
                                                Bonus :
                                            </div>
                                            <div className="col-auto bonutTxxt">
                                                $ {
                                                    !isNaN(Number(userBonusDetail?.bonusData?.dailyBonnus)) ? (Number(userBonusDetail?.bonusData?.dailyBonnus).toFixed(2)) : '0.00'
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                userBonusDetail?.lockedStatus?.dailyBonnus ? (
                                    <button className="claimClass"
                                        onClick={() => {
                                            // setshowModal(true);
                                            // setBonusType('daily')
                                            setClaimBonusObject(prev=>({
                                                ...prev,
                                                isClaimBonusOpen : true,
                                                selectedTab : CLAIM_BONUS_OPTIONS?.DAILY_BONUS
                                            }))
                                        }} >
                                        Claim
                                    </button>
                                ) : (
                                    <button className="claimClass locked"
                                    >
                                        <img src="assets/img/vipPage/lockDark.png" alt="" />
                                    </button>
                                )
                            }
                            {/* <button className="claimClass locked"
                                onClick={() => {
                                    // if (userBonusDetail?.lockedStatus?.dailyBonnus) {
                                        setshowModal(true);
                                        setBonusType('daily')
                                    // }
                                }}
                            >
                                <img src="assets/img/vipPage/lockDark.png" alt="" />
                            </button> */}

                        </div>
                    </div>
                    <div className="col-6">
                        {/* WEEKLY BONUS */}
                        <div className="cardwraper flex-column h-100 d-flex">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <div className="name">WEEKLY BONUS</div>
                                <div onClick={() => {
                                    setshow(true)
                                    setShowData('weekly')
                                }} className="exclamation"><img src="assets/img/vipPage/exclamation.png" alt="" /></div>
                            </div>
                            <div className="col-12 py-2 py-sm-3 flex-fill d-flex justify-content-center align-items-center">
                                <div className="coinBag flex-column p-2 p-sm-3">
                                    <img src="assets/img/vipPage/b2.png" alt="" />
                                    <div className="bonusPregressBox w-100">
                                        <div className="row mx-0 mt-3 mt-sm-4 g-0">
                                            <div className="col-12">
                                                {/* <div className="progress">
                                                    <div className="progress-bar" style={{ width: '25%' }}></div>
                                                </div> */}
                                                {ProgressBar(userBonusDetail?.bonusData?.weeklyBonus, userBonusDetail?.bonusData?.weeklyClaimedBonus)}
                                            </div>
                                            <div className="col bonutTxxt">
                                                Bonus :
                                            </div>
                                            <div className="col-auto bonutTxxt">
                                                {/* $ {userBonusDetail?.bonusData?.weeklyBonus} */}
                                                {
                                                    !isNaN(Number(userBonusDetail?.bonusData?.weeklyBonus)) ? (Number(userBonusDetail?.bonusData?.weeklyBonus).toFixed(2)) : '0.00'
                                                }
                                                {/* $0,00 */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                userBonusDetail?.lockedStatus?.weeklyBonus?.status ? (
                                    <button className="claimClass"
                                        onClick={() => {
                                            // setshowModal(true);
                                            // setBonusType('weekly')
                                            setClaimBonusObject(prev=>({
                                                ...prev,
                                                isClaimBonusOpen : true,
                                                selectedTab : CLAIM_BONUS_OPTIONS?.WEEKLY_BONUS
                                            }))
                                        }} >
                                        Claim in: &nbsp;
                                        <TimerComponent targetTime={userBonusDetail?.lockedStatus?.weeklyBonus?.data?.[0]?.expireDate}/>
                                    </button>
                                ) : (
                                    <button
                                        className="claimClass locked"
                                    >
                                        <img src="assets/img/vipPage/lockDark.png" alt="" />
                                    </button>
                                )
                            }
                            {/* <button className="claimClass locked"
                                onClick={() => {
                                    // if (userBonusDetail?.lockedStatus?.weeklyBonus) {
                                        setshowModal(true);
                                        // setBonusType('daily')
                                        setBonusType('weekly')
                                    // }
                                }}
                            >
                                <img src="assets/img/vipPage/lockDark.png" alt="" />
                            </button> */}
                        </div>
                    </div>
                    <div className="col-6">
                        {/* MONTHLY BONUS */}
                        <div className="cardwraper flex-column h-100 d-flex">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <div className="name">MONTHLY BONUS</div>
                                <div onClick={() => {
                                    setshow(true)
                                    setShowData('monthly')
                                }} className="exclamation"><img src="assets/img/vipPage/exclamation.png" alt="" /></div>
                            </div>
                            <div className="col-12 py-2 py-sm-3 flex-fill d-flex justify-content-center align-items-center">
                                <div className="coinBag flex-column p-2 p-sm-3">
                                    <img src="assets/img/vipPage/b3.png" alt="" />
                                    <div className="bonusPregressBox w-100">
                                        <div className="row mx-0 mt-3 mt-sm-4 g-0">
                                            <div className="col-12">
                                                {/* <div className="progress">
                                                    <div className="progress-bar" style={{ width: '25%' }}></div>
                                                </div> */}
                                                {ProgressBar(userBonusDetail?.bonusData?.monthlyBonus, userBonusDetail?.bonusData?.monthlyClaimedBonus)}
                                            </div>
                                            <div className="col bonutTxxt">
                                                Bonus :
                                            </div>
                                            <div className="col-auto bonutTxxt">
                                                {/* $ {userBonusDetail?.bonusData?.monthlyBonus} */}
                                                {
                                                    !isNaN(Number(userBonusDetail?.bonusData?.monthlyBonus)) ? (Number(userBonusDetail?.bonusData?.monthlyBonus).toFixed(2)) : '0.00'
                                                }
                                                {/* $0,00 */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                userBonusDetail?.lockedStatus?.monthlyBonus?.status ? (
                                    <button className="claimClass"
                                        onClick={() => {
                                            // setshowModal(true);
                                            // setBonusType('monthly')
                                            setClaimBonusObject(prev=>({
                                                ...prev,
                                                isClaimBonusOpen : true,
                                                selectedTab : CLAIM_BONUS_OPTIONS?.MONTHLY_BONUS
                                            }))
                                        }} >
                                        Claim in: &nbsp;
                                        <TimerComponent targetTime={userBonusDetail?.lockedStatus?.monthlyBonus?.data?.[0]?.expireDate}/>
                                        
                                    </button>
                                ) : (
                                    <button className="claimClass locked"
                                    >
                                        <img src="assets/img/vipPage/lockDark.png" alt="" />
                                    </button>
                                )}

                            {/* <button className="claimClass locked"
                                onClick={() => {
                                    // if (userBonusDetail?.lockedStatus?.monthlyBonus) {
                                        setshowModal(true);
                                        // setBonusType('weekly')
                                        setBonusType('monthly')
                                    // }
                                }}
                            >
                                <img src="assets/img/vipPage/lockDark.png" alt="" />
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-6 order-3">
                {/* RAKEBACK */}
                <div className="cardwraper h-100">
                    <div className="row flex-column h-100">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <div className="name">RAKEBACK</div>
                            <div onClick={() => {
                                setshow(true)
                                setShowData('rakeback')
                            }}
                                className="exclamation"><img src="assets/img/vipPage/exclamation.png" alt="" /></div>
                        </div>
                        <div className="col-12 py-2 py-sm-3 flex-fill">
                            <div className="ring">
                                <img src="assets/img/vipPage/b4.png" alt="" />
                            </div>

                            <div className="bonusPregressBox w-100 mt-2 mt-sm-3 p-2 p-sm-3">
                                <div className="row mx-0 g-0">
                                    <div className="col-12">
                                        {ProgressBar(userBonusDetail?.bonusData?.rakeBack, userBonusDetail?.bonusData?.rakeClaimedBonus)}
                                    </div>
                                    <div className="col bonutTxxt">
                                        Bonus :
                                    </div>
                                    <div className="col-auto bonutTxxt">
                                        $
                                    {
                                                    !isNaN(Number(userBonusDetail?.bonusData?.rakeBack)) ? (Number(userBonusDetail?.bonusData?.rakeBack).toFixed(2)) : '0.00'
                                                }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            {/* {
                                userBonusDetail?.lockedStatus?.rakeBack ? (
                                    <button className="claimClass" >
                                        Claim
                                    </button>
                                ) : (
                                    <button className="claimClass locked"
                                    >
                                        <img src="assets/img/vipPage/lockDark.png" alt="" />
                                    </button>
                                )} */}
                            {/* <button className="claimClass locked"
                            >
                                <img src="assets/img/vipPage/lockDark.png" alt="" />
                            </button> */}
                                {userBonusDetail?.lockedStatus?.rakeBack ? (
                                    <button className="claimClass"
                                        onClick={() => {
                                            // setshowModal(true);
                                            // setBonusType('deposit')
                                            setClaimBonusObject(prev=>({
                                                ...prev,
                                                isClaimBonusOpen : true,
                                                selectedTab : CLAIM_BONUS_OPTIONS?.RACKNACK_BONUS
                                            }))
                                        }}
                                    >
                                                                                Claim in: &nbsp;
                                        <TimerComponent targetTime={userBonusDetail?.lockedStatus?.rackbackBonus?.data?.[0]?.expireDate}/>
                                    </button>
                                ) : (
                                    <button className="claimClass locked"
                                    >
                                        <img src="assets/img/vipPage/lockDark.png" alt="" />
                                    </button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashbackBonus;
