import React from "react";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { getAPIAuth } from "../../../service/apiInstance";
import GamesCard from "../../../components/common/GamesCard/GamesCard";
import { MOBILE_SCREEN_WIDTH } from "../../../constants";
import SearchInput from './SearchInput';

const Favourite = () => {
  const [gameList, setGameList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useSelector((state) => state.userDetails);
  const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
  const { t } = useTranslation()
  const favouriteLabels = t("favouriteLabels", { returnObjects: true })
  const commonText = t('commonText',{ returnObjects: true })

  const FavGame = async () => {
    if (userDetails?.id) {
      setIsLoading(true);
      try {
        const res = await getAPIAuth(
          `crypto/get-fav-games?userId=${userDetails?.id}&is_mobile=${isMobile ? "true" : "false"}`
        );
        setGameList(res.data.data);
      } catch (error) {
        //   errorToaster(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updateFavGame = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/get-fav-games?userId=${userDetails?.id}&is_mobile=${isMobile ? "true" : "false"}`
      );
      setGameList(res.data.data);
    } catch (error) {
      //   errorToaster(error.message);
    }
  };
  useEffect(() => {
    FavGame();
  }, [userDetails?.id]);
  return (
    <>
    <div className="toprated-games-main resultpage p-0">
    <div className="row toprated-games gap-2 mx-0">
      
    <div className="d-flex px-0 align-items-center justify-content-between my-3 mt-lg-4">
        <div className="col-12 col-md px-0">
            <SearchInput/>
        </div>
    </div>
              <div className="col-12 px-0 games-card-main">
                {isLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="light" size="sm" />
                  </div>
                ) : (
                  gameList?.length === 0 ? (
                    <div className="text-light w-100 text-center">
                      {commonText.NoDataAvailable}
                    </div>
                  ) : (
                    <div className="row mx-0 mySwiper searchGameCardWrapper w-100 g-2 row-cols-8  casinoCard1 ">
                      {
                        gameList?.map((item, index) => (
                          <>
                            <GamesCard
                              key={item?.gameData?._id}
                              RTP={item?.rtp}
                              image={item?.gameData?.image}
                              name={item?.gameData?.name}
                              isFav={item?.gameData?.is_Fav}
                              id={item?.gameData?._id}
                              favButton={true}
                              uuid={item?.gameData?.uuid}
                              FavGame={updateFavGame}
                            />
                          </>
                        ))
                      }
                    </div>
                  ))}
              </div>
              <div className="col-12">
              </div>
            </div>
    </div>
    </>
  )
}

export default Favourite