
import React from 'react'
import {Modal, ModalBody } from 'react-bootstrap'
import { APP_NAME } from '../../../constants'

const UsdtDetails = ({showUsdtDetailModal, setShowUsdtDetailModal}) => {
  const handleClose = ()=> {
    setShowUsdtDetailModal(false)
  }
  return (
    <Modal className="usdtDetailModal" show={showUsdtDetailModal} onHide={handleClose} centered>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="question mb-2">What is KSH?</div>
            <div className="answer mb-3">KSH is the currency on {APP_NAME}. You can play games, bet on sports and use all our features with KSH. The exact amount you deposit on {APP_NAME} will be displayed in KSH which is equal to the US Dollar.</div>
          </div>
          <div className="col-12">
            <div className="question mb-2">Can KSH be exchanged to any other currency?</div>
            <div className="answer mb-3">Absolutely! You can withdraw KSH or any other currencies at any time. Your balance equivalent will be converted and sent to your wallet of choice without any fee from {APP_NAME}.</div>
          </div>
          <div className="col-12">
            <div className="question mb-2">What is the locked KSH amount?</div>
            <div className="answer mb-3">The locked KSH displayed in yellow is the total bonus you have received from {APP_NAME}. This amount will be unlocked as you wager on {APP_NAME} platform. Refer to the FAQ for more informations about the bonuses!</div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UsdtDetails