import React, { useEffect, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CalendarModal from "./CalendarModal";
import { useTranslation } from "react-i18next";
import { getAPIAuth } from "../../../service/apiInstance";
import moment from "moment";
import { useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";

const breakpoints = {
    0: {
        slidesPerView: 3,
        spaceBetween: 8,
    },
    430: {
        slidesPerView: 3,
        spaceBetween: 8,
    },
    576: {
        slidesPerView: 3,
        spaceBetween: 8,
    },
    768: {
        slidesPerView: 3,
        spaceBetween: 8,
    },
    992: {
        slidesPerView: 4,
        spaceBetween: 8,
    },
    1200: {
        slidesPerView: 5,
        spaceBetween: 8,
    },
    1300: {
        slidesPerView: 5.5,
        spaceBetween: 8,
    },
    1400: {
        slidesPerView: 6,
        spaceBetween: 8,
    },
    1500: {
        slidesPerView: 6,
        spaceBetween: 8,
    },
}
const BonusCalender = () => {
    const [bonusWeek, setBonusWeek] = useState([])
    const [showCalendar, setShowCalendar] = useState(false)
    const { t } = useTranslation()
    const VIPClubLabels = t("VIPClubLabels", { returnObjects: true })
    const commonText = t('commonText', { returnObjects: true })
    const [dailyBonus, setDailyBonus] = useState([])
    const currentDate = new Date()
    const userBonusDetail = useSelector(state => state.userBonusDetail)
    const [weekStartDate, setWeekStartDate] = useState()
    const [weekEndDate, setWeekEndDate] = useState()
    const {token} = useAuth()

    const getDatesInCurrentWeek = () => {
        let currentDate = new Date();
        let currentDayOfWeek = currentDate.getDay();

        let startDate = new Date(currentDate);

        startDate.setDate(currentDate.getDate() - currentDayOfWeek);

        setWeekStartDate(startDate)

        let endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        setWeekEndDate(endDate)

        let datesInCurrentWeek = [];

        let currentDateInWeek = new Date(startDate);
        while (currentDateInWeek <= endDate) {
            datesInCurrentWeek.push(new Date(currentDateInWeek));
            currentDateInWeek.setDate(currentDateInWeek.getDate() + 1);
        }

        return setBonusWeek(datesInCurrentWeek);
    }


    useEffect(() => {
        getDatesInCurrentWeek()
        // getDailyBonus()
    }, [])

    const getDailyBonus = async () => {
        if(weekStartDate && currentDate) {
            try {
                // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
                const res = await getAPIAuth(`user/getCalenderBonus?startDate=${moment(weekStartDate).format('YYYY-MM-DD')}&endDate=${moment(weekEndDate).format('YYYY-MM-DD')}`, token)
                if (res?.data?.success) {
                    setDailyBonus(res?.data?.data)
                }
            } catch (error) {

            }
        }
    }

    useEffect(()=> {
        getDailyBonus()
    },[bonusWeek])


    const convertDate = (date) => {
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    }


    const convertToDateString = (date)=> {
        return new Date(date)
    }


    return (
        <>
            <div className="row py-4 position-relative">
                <div className="col-12 d-md-none d-block">
                    <div className="row justify-content-between align-items-center">
                        <div className="bonusHeading col-12 d-flex justify-content-start align-items-center">{VIPClubLabels.Bonus_calendar}:<span>$0.00</span> <span></span></div>
                        <div className="calendar col-12 d-flex justify-content-between align-items-center">
                            <div className="content">{VIPClubLabels.Total_Claimed}:$0.00</div>
                            <div className="d-flex align-items-center">
                                <div onClick={() => setShowCalendar(true)} className="calendarView">{VIPClubLabels.Calendar_view}</div>
                                <div className="vipBtnPrev d-flex align-items-center justify-content-center me-2"><img src="assets/img/vipPage/lftArrow.png" alt /></div>
                                <div className="vipBtnNext d-flex align-items-center justify-content-center"><img src="assets/img/vipPage/rytArrow.png" alt /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 d-md-block d-none">
                    <div className="row justify-content-between align-items-center">
                        <div className="bonusHeading col-auto d-flex  align-items-center">{VIPClubLabels.Bonus_calendar}:<span>$0.00</span>
                            <span>{VIPClubLabels.Total_Claimed}:$0.00</span></div>
                        <div className="calendar col-auto d-flex align-items-center">
                            <div onClick={() => setShowCalendar(true)} className="calendarView cursor-pointer">
                                {VIPClubLabels.Calendar_view}
                            </div>
                            <div className="vipBtnPrev d-flex align-items-center justify-content-center me-2"><img src="assets/img/vipPage/lftArrow.png" alt /></div>
                            <div className="vipBtnNext d-flex align-items-center justify-content-center"><img src="assets/img/vipPage/rytArrow.png" alt /></div>
                        </div>
                    </div>
                </div>

                <div className="col-12 pt-4">
                    <Swiper className="swiper mySwiper bonusSwiper"
                        navigation={{
                            nextEl: ".vipBtnNext",
                            prevEl: ".vipBtnPrev",
                        }}
                        modules={[Navigation]}
                        breakpoints={breakpoints}
                    >
                        <div className="swiper-wrapper bonusSwiperWrapper">
                        {
                            dailyBonus?.length > 0 ?
                            dailyBonus?.map((item, index) => (
                                    <SwiperSlide className="swiper-slide h-auto "
                                        key={item?.date + index}
                                        style={{
                                            width: "151.333px", marginRight: "8px"
                                        }}>

                                        <div className={`cards1 h-100 ${convertToDateString(item?.date)?.getDate() === currentDate?.getDate() ? 'active' : ''}`}>
                                            <div className="row flex-column h-100">
                                                <div className="col-12 pb-2">
                                                    <div className="date">{moment(item?.date).format("MMM DD")}</div>
                                                </div>
                                                <div className={`col-12 py-2 py-sm-3 d-flex align-items-center justify-content-center  ${item?.dailyBonus != '0' ? "" : "invisible"}`}>
                                                    <div className="icons dollorIcon"><img src="assets/img/vipPage/dollor.png" alt /></div>
                                                </div>
                                                {
                                                    item?.dailyBonus != "0" ? (
                                                        <div className="col-12">
                                                            <div className="dollor activeDollar">
                                                                $ {
                                                                    !isNaN(Number(item?.dailyBonus)) ? Number(item?.dailyBonus).toFixed(2) : '0.00'
                                                                }
                                                            </div></div>
                                                    ) : (
                                                        <>
                                                            <div className="col-12"><div className="dollor">
                                                                $0.00</div></div>
                                                            <div className="renew flex-fill d-flex justify-content-center align-items-end">No Reward</div>
                                                        </>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                                : (
                                    commonText.NoDataAvailable
                                )
                        }



                        </div>
                    </Swiper>
                </div>
            </div>
            <CalendarModal showModal={showCalendar} setShowModal={setShowCalendar} />
        </>
    );
};

export default BonusCalender;




// {
//     bonusWeek?.length > 0 ?
//         bonusWeek?.map((item) => (


//             <SwiperSlide className="swiper-slide h-auto "
//                 style={{
//                     width: "151.333px", marginRight: "8px"
//                 }}>

//                 <div className={`cards1 h-100 ${item?.getDate() === currentDate?.getDate() ? 'active' : ''}`}>
//                     <div className="row flex-column h-100">
//                         <div className="col-12 pb-2">
//                             <div className="date">{convertDate(item)}</div>
//                         </div>
//                         <div className={`col-12 py-2 py-sm-3 d-flex align-items-center justify-content-center  ${item?.getDate() === currentDate?.getDate() && userBonusDetail?.bonusData?.dailyBonnus != '0' ? "" : "invisible"}`}>
//                             <div className="icons dollorIcon"><img src="assets/img/vipPage/dollor.png" alt /></div>
//                         </div>
//                         {
//                             item?.getDate() === currentDate?.getDate() && userBonusDetail?.bonusData?.dailyBonnus != "0" ? (
//                                 <div className="col-12">
//                                     <div className="dollor activeDollar">
//                                         {/* $0.00 */}
//                                         $ {
//                                             !isNaN(Number(userBonusDetail?.bonusData?.dailyBonnus)) ? Number(userBonusDetail?.bonusData?.dailyBonnus).toFixed(2) : '0.00'
//                                         }
//                                     </div></div>
//                             ) : (
//                                 <>
//                                     <div className="col-12"><div className="dollor">
//                                         $0.00</div></div>
//                                     <div className="renew flex-fill d-flex justify-content-center align-items-end">No Reward</div>
//                                 </>
//                             )
//                         }

//                     </div>
//                 </div>
//             </SwiperSlide>
//         ))
//         : (
//             commonText.NoDataAvailable
//         )
// }


// {
//     dailyBonus?.length > 0 ?
//     dailyBonus?.map((item) => (
//             <SwiperSlide className="swiper-slide h-auto "
//                 style={{
//                     width: "151.333px", marginRight: "8px"
//                 }}>

//                 <div className={`cards1 h-100 ${item?.date?.getDate() === currentDate?.getDate() ? 'active' : ''}`}>
//                     <div className="row flex-column h-100">
//                         <div className="col-12 pb-2">
//                             <div className="date">{convertDate(item?.date)}</div>
//                         </div>
//                         <div className={`col-12 py-2 py-sm-3 d-flex align-items-center justify-content-center  ${item?.date?.getDate() === currentDate?.getDate() && userBonusDetail?.bonusData?.dailyBonnus != '0' ? "" : "invisible"}`}>
//                             <div className="icons dollorIcon"><img src="assets/img/vipPage/dollor.png" alt /></div>
//                         </div>
//                         {
//                             item?.date?.getDate() === currentDate?.getDate() && userBonusDetail?.bonusData?.dailyBonnus != "0" ? (
//                                 <div className="col-12">
//                                     <div className="dollor activeDollar">
//                                         {/* $0.00 */}
//                                         $ {
//                                             !isNaN(Number(userBonusDetail?.bonusData?.dailyBonnus)) ? Number(userBonusDetail?.bonusData?.dailyBonnus).toFixed(2) : '0.00'
//                                         }
//                                     </div></div>
//                             ) : (
//                                 <>
//                                     <div className="col-12"><div className="dollor">
//                                         $0.00</div></div>
//                                     <div className="renew flex-fill d-flex justify-content-center align-items-end">No Reward</div>
//                                 </>
//                             )
//                         }

//                     </div>
//                 </div>
//             </SwiperSlide>
//         ))
//         : (
//             commonText.NoDataAvailable
//         )
// }
