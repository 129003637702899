import React from 'react'

const CommingSoon = () => {
  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <div className="text-success" style={{
        fontSize : '2.5rem', fontWeight : 800, letterSpacing : '2px'
      }}>COMING SOON</div>
    </div>
  )
}

export default CommingSoon