import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import MessageOffcanvas from "../MessageOffcanvas";
import SetCurrencyModal from "../UnAuthCurrencyModal/UnAuthCurrencyModal";
import {
  AUTH_MODAL_TABS,
  CLAIM_BONUS_OPTIONS,
  CLICK_ID,
  NEW_CLICK_ID,
  TRX_ID,
  DEFAULT_CURRENCY,
  MOBILE_SCREEN_WIDTH,
  SELECTED_CURRENCY,
  SM_SCREEN_WIDTH,
  TABS_OPTIONS,
  TOKEN_NAME,
  WALLET_MODAL_TABS,
  USER_LANG,
  SPIN_STRING,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { reducerConst } from "../../constants/storeConstants";
import { fetchUserDetails } from "../../service/fetchUserDetails";
import AuthModal from "../AuthModal";
import { useAuthModal } from "../../hooks/useAuthModal";
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NewWallet from "../../components/newWallet/index";
import { useWalletModal } from "../../hooks/useWallet";
import { ROUTES_CONST } from "../../constants/routeConstant";
import UserBalanceComp from "./UserBalanceComp";
import { socket } from "../../service/socket";
import { useTranslation } from "react-i18next";
import LogoutModal from "../logoutModal/LogoutModal";
import { useLogoutModal } from "../../hooks/useLogout";
import ReferralModal from "../ReferralModal/ReferralModal";
import UnAuthCurrencyModal from "../UnAuthCurrencyModal/UnAuthCurrencyModal";
import ResultModal from './../resultModal/ResultModal'
import { useResultModal } from "../../hooks/useResult";
import CadoWallet from "../cadoWallet";
import { btUrlAction, getFavCryptoAction, getFavFiatAction, getUserBonusDetails, userBalanceAction } from "../../store/action";
import UpdatedAuthModal from "../UpdatedAuthModal";
import NotificationOffcanvas from "../notificationOffcanvas/NotificationOffcanvas";
import { useNotification } from "../../hooks/useNotification";
import './style.scss'
import UsdtDetails from "./components/UsdtDetails";
import UsdtBonusModal from "./components/UsdtBonusModal";
import UsdtBonusHistory from "./components/UsdtBonusHistory";
import { getAPIAuth, postAPI, postAPIAuth } from "../../service/apiInstance";
import FreeSpinMsgModal from "../freeSpinModal";
import { useMediaQuery } from "react-responsive";
import RampAnalysisModal from './../rampAnalysis/RampAnalysisModal'
import { Dropdown, Spinner, Tab, Tabs } from "react-bootstrap";
import FiatModal from "./components/FiatModal";
import AddCurrency from "../AddCurrencyModal/AddCurrency";
import { convertToEightDigitsAfterDecimal } from "../../utils/convertToEightDigitsAfterDecimal";
import { useClaimBonus } from "../../hooks/useClaimBonus";
import DepositeMessage from "../depositMessage/DepositeMessage";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useBtAuth } from "../../hooks/useBtAuth";
import { useSidebar } from "../../hooks/useSidebar";


const bonusData = [
  {
    name: "CASHBACK",
    img: 'assets/img/header/bonus1.png',
  },
  {
    name: 'DEPOSIT BONUS',
    img: "assets/img/header/bonus2.png",
  },
  {
    name: 'DAILY BONUS',
    img: "assets/img/header/bonus3.png",
  },
  {
    name: 'WEEKLY BONUS',
    img: "assets/img/header/bonus4.png",
  },
  {
    name: 'MONTHLY BONUS',
    img: "assets/img/header/bonus5.png",
  },
  {
    name: 'FREE BETS',
    img: "assets/img/header/bonus6.png",
  },
  {
    name: ' TIPS',
    img: "assets/img/header/bonus7.png",
  },

]

const option = {
  CURRENCY: "CURRENCY",
  mNFT: "mNFT"
}
const AppHeader = () => {
  const { token } = useAuth();
  const [isUnAuthCurrency, setIsUnAuthCurrency] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const [currency, setCurrency] = useState();
  const [selectedTab, setSelectedTab] = useState("");
  const [languageSelectedTab, setLanguageSelectedTab] = useState();
  const dispatch = useDispatch();
  const { setAuthModalObject, authModalObject } = useAuthModal();
  const { setIsNotificationOpen } = useNotification()
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const { t } = useTranslation()
  const desktopHeader = t("header", { returnObjects: true }) //string has to be same as language json key 
  const { setIsLogoutOpen } = useLogoutModal()
  const [showLanguage, setShowLanguage] = useState(false);
  const { setToken } = useAuth()
  const { resultObject, setResultObject } = useResultModal()
  const userBalance = useSelector(state => state.userBalance)
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [showUsdtDetailModal, setShowUsdtDetailModal] = useState(false)
  const [showUsdtBonusModal, setShowUsdtBonusModal] = useState(false)
  const [showBonusHistoryModal, setShowBonusHistoryModal] = useState(false)
  // const click_id = searchParams.get('click_id') ?? sessionStorage.getItem(CLICK_ID)
  const [click_id, setClick_id] = useState(searchParams.get('click_id') ?? sessionStorage.getItem(CLICK_ID))
  const [new_click_id, setNew_Click_id] = useState(searchParams.get('clickid') ?? localStorage.getItem(NEW_CLICK_ID))
  const [trx_id, setTrx_id] = useState(searchParams.get('trx_id') ?? localStorage.getItem(TRX_ID))
  // const [new_click_id, setNew_Click_id] = useState(searchParams.get('clickid') ?? sessionStorage.getItem(NEW_CLICK_ID))
  const interValRef = useRef(null)
  const [notifyStatus, setNotifyStatus] = useState()
  const currencyExchange = useSelector(state => state.currencyExchangeData)
  const [query, setQuery] = useSearchParams()
  const isMobile = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })
  const [key, setKey] = useState('Currency');
  const [showFiatModal, setShowFiatModal] = useState(false)
  // const dispatch = useDispatch()
  // const userBalance = useSelector(state => state.userBalance)
  const [showFavoriteModal, setShowFavoriteModal] = useState(false)
  const [favCrypto, setFavCrypto] = useState([]);
  const [favFiat, setFavFiat] = useState([]);
  const favFiatDetails = useSelector(state => state.favFiat);
  const favCryptoDetails = useSelector(state => state.favCrypto);
  const [isChecked, setIsChecked] = useState(false);
  const [userCurrency, setUserCurrency] = useState();
  const [currencyLoader, setCurrencyLoader] = useState({
    index: "",
    status: false,
    type: ""
  })
  const [isHidden, setIsHidden] = useState(false);
  const [selectedDrop, setSelectedDrop] = useState(option.CURRENCY)
  const [currencySearch, setCurrencySearch] = useState('')
  const intervalRef = useRef()
  const arrPath = location.pathname.split('/')
  const myDivRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false)
    const {setClaimBonusObject} = useClaimBonus()
    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false)
    const [showDepositMsg, setShowDepositMsg] = useState(false)
    const [depositMsgData, setDepositMsgData] = useState({})
    const ipAddress = useSelector(state => state.ipAddress)
    const country = useSelector(state => state.country)
    const [bonus, setBonus] = useState({ bonus: false })
  const { setBtToken } = useBtAuth()
  const {isSideOpen, setIsSideOpen} = useSidebar()


    console.log('teleeeeeeeee', query?.get('tglogin'))

    useEffect(() => {
      // const encodedParam = searchParams.get("bonus")
      const encodedParam = localStorage.getItem(SPIN_STRING)
      if (!!encodedParam) {
          const decodedParam = JSON.parse(atob(encodedParam));
          setBonus(decodedParam)
      }
  }, [authModalObject.isAuthOpen])


  // to get trx id from url
  useEffect(()=> {
    const paramsString = searchParams.get('params');
    const params = new URLSearchParams(decodeURIComponent(paramsString));

    // Get the trx_id value
    const trxIdValue = params.get('trx_id');
    if(trxIdValue) {
      setTrx_id(trxIdValue)
    }
  }, [searchParams])


    // useEffect(()=> {
    //   let script 
    //   if(query?.get('tglogin')) {
    //     const token = process.env.REACT_APP_TOKEN;
    //     const chatId = query?.get('tglogin');
    //     // const btn = telegramBtn?.current
    
    //     script = document.createElement('script');
    //     script.async = true;
    //     script.src = 'https://telegram.org/js/telegram-widget.js?22';
    //     script.setAttribute('data-telegram-login', 'bot name will come here');
    //     script.setAttribute('data-size', 'large');
    //     script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    //     script.setAttribute('data-request-access', 'write');
    //     document?.body?.appendChild(script);
    
    //     window.onload = function() {
    //       if(query?.get('tglogin')) {
    //         console.log('telegram onload window fun')
    //         const token = '<%=  process.env.REACT_APP_TOKEN %>';
    //         const userId = query?.get('tglogin');
    //         window.onTelegramAuth({ id: userId }) ;
    //       }
    //     };
    
    //     window.onTelegramAuth = (user) => {
    //       console.log('Logged in as telegram auth fun' + JSON.stringify(user));
    //       handleTelegramResponse(user) // function to interact with database
    //     };
    //   }

  
    //   return () => {
    //     if(query?.get('tglogin')) {
    //       document?.body?.removeChild(script);
    //     }
    //   };
    // }, [query])


    useEffect(() => {
      const handleMessage = (event) => {
        if (event.data === 'navigate-home') {
          navigate('/');
        }
      };
  
      window.addEventListener('message', handleMessage);
  
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, [location]);

    const handleTelegramResponse = (response) => {
      // telegram will only work when the bot domain is set correctly 
      // to set use bot father bot of telegram 
      // if want to use telegram auth on localhost or on live use the localhost or domain respectively
      // setIsOpen(prev => !prev)
      // setUserObj(response)
      const userObj = response
  
      console.log('telegram user obj', userObj)
      // if (parent !== "signIn") {
      // if (!checks.terms) {
      //   // errorToaster()
      //   errorToaster("Please read and tick the bottom agreement");
      //   return
      // }
      // }
      const telegramHandler = async (values) => {
        try {
          // const lang = JSON.parse(localStorage.getItem(USER_LANG))
          const lang = localStorage.getItem(USER_LANG)
          const body = {
            fullname: `${userObj?.first_name} ${userObj?.last_name}`,
            image: userObj?.photo_url,
            username: userObj?.username,
            userid: userObj?.id,
            // email: values?.email,
            loginMethod: "telegram",
            ipAddress: ipAddress ? ipAddress : "",
            country: country ? country : "",
            // language: lang?.symbol
            language: lang,
            trackingToken: click_id ? click_id : null,
            clickid : !!new_click_id ? new_click_id : null,
            trx_id : !!trx_id ? trx_id : null,
            // encodedString : !!trx_id ? trx_id : null,
            bonus: !!bonus ? bonus.bonus : false
  
          }
  
          const res = await postAPI("user/social-authentication", body)
          if (res.data.success) {
            succesToaster(res.data.message);
            setToken(res?.data.data?.token);
            setBtToken(res?.data?.data?.betByToken)
            // setIsOpen(false)
            setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
            localStorage.removeItem(TRX_ID)
            // if (parent === 'signup') {
  
            //   setSpinModalObject(prev => ({
            //     ...prev,
            //     isModalOpen: true
            //   }))
            //   setIsReferral(true)
            //   setIsReferralInput(true)
            //   window?.dataLayer?.push({
            //     'event': 'GA4_event',
            //     'event_name': 'game_sign_up'
            //   });
            // }
            // setQuery({ tglogin: "" })
            localStorage.removeItem('clickid')
  
          } else {
            errorToaster("Something went wrong")
          }
        } catch (error) {
  
        } finally {
          // setIsLoading(false)
        }
      }
      telegramHandler()
    };


    useEffect(()=> {
      if(userDetails?.id) {
              try {
              console.log('socket deposit userDetails?.id', userDetails?.id)
  
              socket.on(`deposit-${userDetails?.id}`, (data) => {
                  if (data?.amount) {
                    setShowDepositMsg(true)
                    setDepositMsgData(data)
                  }
                  console.log('socket deposit dataaaaaaaaaaaaaaaaaa', data)
              });
          } catch (error) {
              console.log('socket deposit error', error)
          }
          }
  }, [userDetails?.id])


  // useEffect(() => {
  //   if (myDivRef.current.classList.contains('myClass')) {
  //     console.log('Div has the class "myClass"');
  //   } else {
  //     console.log('Div does not have the class "myClass"');
  //   }
  // }, []);

  // for checking if dropdown has show class or not
  // useEffect(() => {
  //   let observer;
  //   if (token) {
  //     observer = new MutationObserver((mutationsList) => {
  //       for (let mutation of mutationsList) {
  //         if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
  //           const hasClass = myDivRef?.current?.classList?.contains('show');
  //           setDropdownOpen(hasClass)
  //         }
  //       }
  //     });

  //     observer?.observe(myDivRef.current, { attributes: true });
  //   }

  //   return () => {
  //     if (token) {

  //       observer?.disconnect();
  //     }
  //   };
  // }, [token]);



  useEffect(() => {
    if (Object.keys(userDetails).length !== 0) {
      setIsChecked(userDetails?.fiatStatus)
    }
  }, [userDetails.id])

  useEffect(() => {
    if (!token) {
      if (query.get("action") == 'register') {
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: true, selectedTab: AUTH_MODAL_TABS.SIGN_UP }))
      } else if (query.get("action") == 'login') {
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: true, selectedTab: AUTH_MODAL_TABS.LOG_IN }))
      } else if (query.get('action') === 'forgotPassword') {
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: true, selectedTab: AUTH_MODAL_TABS.LOG_IN }))
      }
    } else {
      if (query.get("action") == 'wallet') {
        setWalletModalObject(pre => ({ ...pre, isWalletOpen: true, selectedTab: WALLET_MODAL_TABS.DEPOSIT }))
      }
    }
  }, [query.get("action"), token])

  ///----->>>notification------------>>>
  const checkStatus = async () => {
    try {
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
      const res = await getAPIAuth('user/checkReadStatus', token)
      if (res?.data?.success) {
        setNotifyStatus(res?.data?.data)
      }
    } catch (error) {

    }
  }


  useEffect(() => {

    if (token) {
      if (interValRef.current) {
        clearInterval(interValRef.current)
      }
      interValRef.current = setInterval(() => {
        checkStatus()
      }, 20000)
    }

    return () => {
      clearInterval(interValRef.current)
    }
  }, [token])

  // used in authModal 
  // removed in logout modal
  useEffect(() => {
    sessionStorage.setItem(CLICK_ID, click_id)
  }, [click_id])

  useEffect(() => {
    localStorage.setItem(NEW_CLICK_ID, new_click_id)
  }, [new_click_id])

  useEffect(() => {
    localStorage.setItem(TRX_ID, trx_id)
  }, [trx_id])

  console.log('trx_id', trx_id)

  const getUserBal = async () => {
    try {
      const res = await getAPIAuth(`user/user-balance?userId=${userDetails?.id}`, token)
      if (res?.data?.success) {
        // setUserBal(res.data.data)
        dispatch(userBalanceAction(res.data.data))
      }
    } catch (error) {

    }
  }

  // get cashback data
  const getCashbackData = async () => {
    try {
      const res = await getAPIAuth(`user/get-userbonus-stats`, token)
      if (res?.data?.success) {
        dispatch(getUserBonusDetails({
          bonusData: res?.data?.data?.[0],
          lockedStatus: res.data?.lockStatus,
          freeSpinPopupStatus: res?.data?.freeSpinPopupStatus
        }))
        // setLockStatus(res.data?.lockStatus)
        // setBonusData(res?.data?.data?.[0])
      }
    } catch (error) {

    }
  }
  
  useEffect(() => {
    if (token) {
      getCashbackData()
    }
  }, [token])

  useEffect(() => {
    let timer
    if (token && location.pathname.includes('games')) {
      timer = setInterval(() => {
        getCashbackData()
      }, 2000);
    }
    return () => {
      if (token && location.pathname.includes('games')) {
        clearInterval(timer)
      }
    }
  }, [token, location])

  useEffect(() => {
    {/* <script src='https://static-cdn.trackier.com/js/trackier-web-sdk.js'></script>
<script>TrackierWebSDK.init()</script> */}
    // const script = document.createElement('script');
    // script.src = 'https://static-cdn.trackier.com/js/trackier-web-sdk.js';
    // script.async = true;
    // let trackingToken = window?.TrackierWebSDK.getTrackingToken()
    let trackingToken = window?.TrackierWebSDK?.getTrackingToken()
    if (trackingToken) {
      setClick_id(trackingToken)
    }

  }, [])

  // console.log(userDetails)
  const getFav = async () => {
    try {
      const res = await getAPIAuth(`crypto/get-fav-currency-crypto?userId=${userDetails?.id}`, token);
      // console.log(res, "ress")
      if (res?.data?.success) {
        setFavFiat(res?.data?.fiat)
        dispatch(getFavFiatAction(res?.data?.fiat));
        setFavCrypto(res?.data?.crypto)
        dispatch(getFavCryptoAction(res?.data?.crypto))
      }

    } catch (error) {

    }
  }
  useEffect(() => {
    if (userDetails?.id) {
      getUserBal();
      // getFav();
      // userCryptoDetails();
    }
    // }, [userDetails.id, favFiatDetails, favCryptoDetails, showFavoriteModal, isChecked])
  }, [userDetails.id, showFavoriteModal, isChecked])


  // to show updated user balance 
  useEffect(() => {
    if (userDetails?.id && token) {
      // polling
      if (dropdownOpen) {//to call the api only when dropdown is open

        if (intervalRef.current) {//if previously any interval is running ,cancel it 
          clearInterval(intervalRef.current)
        }
        // start a new interval
        intervalRef.current = setInterval(() => {
          getUserBal()
          // getFav()
        }, 3000)
      }
    }
    return () => clearInterval(intervalRef.current)// clean up , when component unmounts

  }, [token, userDetails?.id, dropdownOpen])

  useEffect(() => {
    if (token) {
      const getData = async () => {

        const res = await fetchUserDetails(token);
        if (res?.response?.data?.msg === "Invalid token") {
          // localStorage.removeItem(TOKEN_NAME);
          // // localStorage.removeItem(BT_TOKEN_NAME);
          // setToken("");
          // const auth = getAuth();
          // dispatch(getUserDetails({}))
          // signOut(auth)
          //     .then(() => {
          //         // succesToaster("Logged Out")
          //     })
          //     .catch((error) => {
          //         // An error happened.
          //     });
          //     window.location.reload()
        } else {
          dispatch({
            type: reducerConst.USER_DETAILS,
            payload: res?.data?.data ? res.data.data : {},
          });
        }
      };
      getData();
    }
  }, [token, isChecked, userDetails.usercurrency]);
  // will be useful 
  // const userCurrencyHandler = async () => {
  //   if (!userDetails?.id) return;
  //   if (!userDetails?.usercurrency) {
  //     try {
  //       const item = JSON.parse(localStorage.getItem(SELECTED_CURRENCY)) || DEFAULT_CURRENCY;
  //       const body = {
  //         userId: userDetails.id,
  //         currency: item.code,
  //         image: item.icon,
  //       };
  //       const res = await postAPIAuth("user/save-user-currency", body);
  //       // if (res.data.success) {
  //       //     const res = await fetchUserDetails(token)
  //       //     dispatch({ type: reducerConst.USER_DETAILS, payload: res?.data?.data ? res.data.data : {} })
  //       // }
  //     } catch (error) { }
  //   }
  // };

  // useEffect(() => {
  //   if (token) {
  //     userCurrencyHandler();
  //   }
  // }, [token, userDetails?.id]);

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem(SELECTED_CURRENCY));
    if (!!userDetails?.usercurrency) {
      setCurrency(userDetails.usercurrency);
    } else if (item?.id) {
      setCurrency(item?.code);
    } else {
      setCurrency(DEFAULT_CURRENCY.code);
    }
  }, [
    JSON.parse(localStorage.getItem(SELECTED_CURRENCY))?._id,
    userDetails?.id,
    token,
    userDetails?.usercurrency,
  ]);

  const sideAppHandler = () => {
    const exists = document.body.classList;
    if (exists["value"] === "asideBar") {
      document.body.classList.remove("asideBar");
    } else {
      document.body.classList.add("asideBar");
    }
  };
  // function for logout
  const logoutFunction = () => {
    setIsLogoutOpen(true)
  };
  // remove this comment , if not needed
  // const getSome = async () => {
  //   try {
  //     const body = {
  //       action: "list-of-watchlist",
  //       watch_list_number: "1",
  //       // useId
  //     };
  //     const res = fetch("https://www.algoniti.com/learnotrade/server/list-of-watchlist.php",
  //       {
  //         method: "POST", // *GET, POST, PUT, DELETE, etc.
  //         mode: "cors", // no-cors, *cors, same-origin
  //         cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //         credentials: "same-origin", // include, *same-origin, omit
  //         headers: {
  //           "Content-Type": "application/json",
  //           // 'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         redirect: "follow", // manual, *follow, error
  //         referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //         body: JSON.stringify(body), // body data type must match "Content-Type" header
  //       });



  //   } catch (error) {
  //   }
  // }


  const handleChange = async (item) => {
    try {
      const res = await getAPIAuth(`user/setCryptoForUser?currency=${item.code}&type=${item.type}&userId=${userDetails.id}`, token);
      if (res?.data?.success) {
        setUserCurrency(res.data.data);
        // get user updated wallet info--->>>
        const getUserBal = async () => {
          try {
            const res = await getAPIAuth(`user/user-balance?userId=${userDetails?.id}`, token)
            if (res?.data?.success) {
              // setUserBal(res.data.data)
              dispatch(userBalanceAction(res.data.data))
            }
          } catch (error) {

          }
        }
        getUserBal()
        const dropdownMenus = document.getElementsByClassName("dropdown-menu show");
        const dropdownMenusArray = Array.from(dropdownMenus);
        dropdownMenusArray.map(menu => {
          menu.classList.remove("show");
        });

      }
    } catch (error) {
      console.log(error)

    } finally {
      setCurrencyLoader((pre) => ({ ...pre, index: '', status: false, type: "" }))
    }

  }
  const searchHandler = (e) => {
    if (e !== '') {
      const res = getAPIAuth(`crypto/get-fav-currency-crypto?userId=${userDetails.id}&search=${e.trim(' ')}`)
      // console.log(res, "ress")
      if (res?.data?.success) {
        setFavFiat(res?.data?.fiat)
        setFavCrypto(res?.data?.crypto)
      }
    }

  }

  useEffect(() => {
    if (currencySearch) {
      const filterFiat = favFiatDetails.filter(item => item.code.toLowerCase().includes(currencySearch.toLowerCase()) || item.name.toLowerCase().includes(currencySearch.toLowerCase()))
      setFavFiat(filterFiat)
      const filterCrypto = favCryptoDetails.filter(item => item.code.toLowerCase().includes(currencySearch.toLowerCase()) || item.name.toLowerCase().includes(currencySearch.toLowerCase()))
      setFavCrypto(filterCrypto)
    } else {
      setFavFiat(favFiatDetails)
      setFavCrypto(favCryptoDetails)
    }
  }, [currencySearch])

  const handleViewFiat = async (value) => {
    try {
      const res = await getAPIAuth(`user/setFiatForUser?fiatStatus=${value}&userId=${userDetails.id}`, token);
      if (res?.data?.success) {
        setIsChecked(res.data.data.fiatStatus)
        if (res.data.data.fiatStatus === true) {
          setShowFiatModal(true);
        } else if (res.data.data.fiatStatus === false) {
          setShowFiatModal(false)
        }
      }
    } catch (error) {
    }
  }
  // const userCryptoDetails=async(value)=>{
  //   try {
  //     const res =await getAPIAuth(`user/setCryptoForUser?setCurrency=${userDetails.usercurrency}&userId=${userDetails.id}`, token);
  //     if (res?.data?.success) {
  //       setUserCurrency(res.data.data)
  //       }
  //   } catch (error) {
  //     console.log(error)

  //   }
  // }

  const handleSidebar = () => {
    setIsSideOpen(!isSideOpen)
  }


  return (
    <>
      <div className="container-fluid navbar position-fixed top-0 w-100">
        <div className="row align-items-center w-100 mx-0 h-100">
          <div className={`col-auto h-100 px-0 ${token ? 'px-sm-0' : 'px-0'} navFixxColll d-none d-xl-flex`}>
            <div className={`row align-items-center mx-0 ${token ? 'justify-content-start' : 'justify-content-start'}`}>
              <>
              
              {
                token ? ( ''
                  // <div className="col-auto navGroup1">
                  //   <div className="row navGroupInner flex-nowrap align-items-center">
                  //     <div className="col-auto groupLeft pe-3 pe-sm-4 d-grid">
                  //       <div className="row groupLeftInner gap-1 gap-md-2 d-inline-grid">
                  //         <div className="col-auto usdt">
                  //           {
                  //             isMobile ? <>
                  //               <div className="dropdown">
                  //                 <div className="row usdtInner align-items-center gap-1 gapmd2 gap-lg-2 cursor-pointer dropdown-toggle" disabled={isDropdownDisabled} type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                  //                   <div className="col-auto px-0 usdtIcon d-grid">
                  //                     <img src={userBalance?.setCurrencyData?.icon} alt="" />
                  //                   </div>
                  //                   <div className="col-auto px-0 usdtTitle">
                  //                     {/* USDT: */}
                  //                     {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}   :
                  //                   </div>
                  //                   <div className="col-auto px-0 ">
                  //                     <div className="usdtValue">

                  //                       {
                  //                         userBalance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(2) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : "0.00"

                  //                       }
                  //                     </div>

                  //                   <div className="usdtBtn2 smallUsdtTxtHover" onClick={(e)=> {
                  //               e.stopPropagation();
                  //               setShowUsdtBonusModal(true)
                  //           } } onMouseEnter={()=> setIsDropdownDisabled(true)} onMouseLeave={()=> setIsDropdownDisabled(false)}>
                  //                   <div className=" smallUsdtTxt">
                  //                     <img
                  //                       src="assets/img/header/lockYellow.png" alt="" />
                  //                     $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                  //                   </div>
                                      
                  //                     <div className="usdtHoverBox" onClick={(e)=> {
                  //                         e.stopPropagation();
                  //                         setShowUsdtBonusModal(true)
                  //                     } } onMouseEnter={()=> setIsDropdownDisabled(true)} onMouseLeave={()=> setIsDropdownDisabled(false)}>
                  //                         <div className="currency d-flex align-items-center gap-1">
                  //                             <img src={userBalance?.setCurrencyData?.icon} alt="" />
                  //                             <span>{userBalance?.setCurrencyData?.name}</span>
                  //                             {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}
                  //                         </div>
                  //                         <div className="ms-3">
                  //                             <div className="mainCurr">
                  //                                 {userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(5) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(5) : "0.00"}
                  //                             </div>
                  //                             <div className="locCurr">
                  //                                 <img src="assets/img/header/lockYellow.png" className="object-fit-contain" alt="" />
                  //                                 $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                  //                             </div>
                  //                         </div>
                  //                     </div>
                  //                   </div>
                  //                   </div>
                  //                 </div>
                  //                 <div
                  //                   className="dropdown-menu innerUsdtBtn userBalInnerDrop drop"
                  //                   ref={myDivRef}
                  //                 >
                  //                   <div className="row mx-0">
                  //                     <div className="col-12 px-0">
                  //                       <div className="row mx-0">
                  //                         <div className="col px-0">
                  //                           <div className="searchInp">
                  //                             <img src="./assets/img/searchGray.png" alt="" />
                  //                             <input
                  //                               type="text"
                  //                               className="form-control shadow-none"
                  //                               placeholder="Search"
                  //                               value={currencySearch}
                  //                               onChange={(e) => setCurrencySearch(e.target.value)}

                  //                             />
                  //                           </div>
                  //                         </div>
                  //                         <div className="col-auto ps-2 pe-0">
                  //                           <div className="plusBtn" onClick={() => setShowFavoriteModal(true)}>
                  //                             +
                  //                           </div>
                  //                         </div>
                  //                         <div className="col-12 px-0">
                  //                           <div className="dropTabs">
                  //                             <div className={`tab ${selectedDrop === option.CURRENCY ? "active" : ""} `} onClick={() => setSelectedDrop(option.CURRENCY)}>Currency</div>
                  //                             <div className={`tab ${selectedDrop === option.mNFT ? "active" : ""} `} onClick={() => setSelectedDrop(option.mNFT)}>mNFT</div>
                  //                           </div>
                  //                         </div>
                  //                       </div>
                  //                     </div>
                  //                     {
                  //                       selectedDrop === option.CURRENCY ? (
                  //                         <>
                  //                           <div className="innerScrollDiv">
                  //                             <div className="col-12 px-0">
                  //                               {favFiat.length > 0 ? (
                  //                                 <>
                  //                                   {isHidden ? "" : <div className="dropTitle mb-1">Fiat</div>}

                  //                                   <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                  //                                     {
                  //                                       favFiat?.map((item, index) => (
                  //                                         <li key={index} className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${isHidden && (parseFloat(item.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}  ${item.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}
                  //                                           onClick={() => {
                  //                                             setCurrencyLoader((pre) => ({ ...pre, index: index, status: true, type: "fiat" }))
                  //                                             handleChange(item)
                  //                                           }}
                  //                                         >
                  //                                           <div className="d-flex align-items-center">

                  //                                             {
                  //                                               currencyLoader.index == index && currencyLoader.status && currencyLoader.type == 'fiat' ? (
                  //                                                 <><Spinner size="sm" /></>
                  //                                               ) : (<>
                  //                                                 <img className="rounded" src={item.icon} alt="" />
                  //                                               </>)
                  //                                             }
                  //                                             {item.code}
                  //                                           </div>
                  //                                           <div>
                  //                                             {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}

                  //                                             {userDetails.fiatStatus ? (
                  //                                               <span className="smallVal"> {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}
                  //                                               </span>
                  //                                             ) : (
                  //                                               ''
                  //                                             )
                  //                                             }
                  //                                           </div>
                  //                                         </li>
                  //                                       ))}
                  //                                   </ul>
                  //                                 </>) : null}
                  //                             </div>
                  //                             <div className="col-12 px-0">
                  //                               {favCrypto.length > 0 ? (
                  //                                 <>
                  //                                   {isHidden ? "" : <div className="dropTitle mb-1">Crypto</div>}

                  //                                   <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                  //                                     {/*     */}
                  //                                     {favCrypto?.map((item, index) => (

                  //                                       <li
                  //                                         key={index}
                  //                                         className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${isHidden && (parseFloat(item.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}  ${item.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}
                  //                                         onClick={() => {
                  //                                           setCurrencyLoader((pre) => ({ ...pre, index: index, status: false, type: "crypto" }))

                  //                                           handleChange(item)
                  //                                         }}
                  //                                       >
                  //                                         <div className="d-flex align-items-center">

                  //                                           {
                  //                                             currencyLoader.index == index && currencyLoader.status && currencyLoader.type == 'crypto' ? (
                  //                                               <><Spinner size="sm" /></>
                  //                                             ) : (<>
                  //                                               <img className="rounded" src={item.icon} alt="" />
                  //                                             </>)
                  //                                           }
                  //                                           {item.code}
                  //                                         </div>
                  //                                         <div className="text-end">
                  //                                           {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}
                  //                                           {userDetails.fiatStatus ? <span className="smallVal"> {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}</span> : ""}
                  //                                         </div>
                  //                                       </li>
                  //                                     ))}
                  //                                   </ul>
                  //                                 </>) : null}
                  //                             </div>
                  //                           </div>
                  //                         </>
                  //                       ) : (
                  //                         <>
                  //                           <div className="innerScrollDiv d-flex justify-content-center align-items-center">
                  //                             <div>
                  //                               <img
                  //                                 src="/assets/img/stacking/noData.svg"
                  //                                 alt=""
                  //                                 style={{
                  //                                   width: "150px"
                  //                                 }}
                  //                               />
                  //                               <div className="dropTitle mb-1 text-center mt-3">No Data Available</div>
                  //                             </div>


                  //                           </div>
                  //                         </>
                  //                       )
                  //                     }

                  //                     <div className="col-12">
                  //                       <div className="row pt-2 pb-1 dropCheckBoxWrapper">
                  //                         <div className="col-6 dropCheckBox ps-0">
                  //                           <input onChange={(e) => handleViewFiat(e.target.checked)} type="checkbox" id="fiat" className="d-none" checked={isChecked} />
                  //                           <label htmlFor="fiat" className="checkLabel"></label>
                  //                           <label htmlFor="fiat" className="textLabel">View in fiat</label>
                  //                         </div>
                  //                         <div className="col-6 dropCheckBox pe-0 justify-content-end">
                  //                           <input type="checkbox" id="small" className="d-none"
                  //                             onChange={(e) => setIsHidden(e.target.checked)}
                  //                           />
                  //                           <label htmlFor="small" className="checkLabel"></label>
                  //                           <label htmlFor="small" className="textLabel">Hide Small</label>
                  //                         </div>
                  //                       </div>
                  //                     </div>
                  //                   </div>
                  //                 </div>
                  //               </div>
                  //             </> :
                  //               <>
                  //                 <div className="row usdtInner align-items-center gap-1 gap-md-2" >
                  //                   <div className="col-auto px-0 usdtIcon d-grid">
                  //                     <img src={userBalance?.setCurrencyData?.icon} alt="" />
                  //                   </div>
                  //                   <div className="col-auto px-0 usdtTitle">
                  //                     {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}   :
                  //                   </div>
                  //                   <div className="col-auto px-0 usdtValue">
                  //                     {
                  //                       userBalance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(5) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(5) : "0.00"

                  //                     }
                  //                   </div>
                  //                 </div>
                  //               </>
                  //           }
                  //         </div>
                  //         <div className="col-auto username">
                  //           <div className="row usernameInner align-items-center gap-1 gap-md-2">
                  //             <div className="col-auto px-0 usernameIcon d-grid">
                  //               <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12 12" fill="none">
                  //                 <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1848 5.99928C11.1848 7.57962 10.4692 8.99666 9.33714 9.95817C8.39446 10.7589 7.16297 11.2437 5.81555 11.2437C4.46815 11.2437 3.23666 10.7589 2.29394 9.95817C1.16193 8.99666 0.446289 7.57962 0.446289 5.99928C0.446289 3.10288 2.85019 0.754883 5.81555 0.754883C8.78089 0.754883 11.1848 3.10288 11.1848 5.99928ZM5.81555 9.67036C6.75877 9.67036 7.62081 9.33099 8.28069 8.77052C8.35666 8.70596 8.42995 8.63852 8.50034 8.5683C7.91998 7.65142 6.93413 7.04816 5.81555 7.04816C4.697 7.04816 3.71112 7.65142 3.13075 8.5683C3.20118 8.63852 3.27447 8.70596 3.35044 8.77052C4.01034 9.33099 4.87239 9.67036 5.81555 9.67036ZM5.81555 5.99928C6.70518 5.99928 7.42633 5.2949 7.42633 4.42596C7.42633 3.55704 6.70518 2.85264 5.81555 2.85264C4.92592 2.85264 4.20477 3.55704 4.20477 4.42596C4.20477 5.2949 4.92592 5.99928 5.81555 5.99928Z" fill="#909399" />
                  //               </svg>
                  //             </div>
                  //             <div className="col-auto px-0 usernameTitle">
                  //               {/* Username */}
                  //               {userDetails.username}
                  //             </div>
                  //             {/* <div className="col-auto px-0 usernameValue">
                  //               $0.2013.9
                  //             </div> */}
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //     <div className="col-auto groupRight d-sm-block d-none">
                  //       <div className="row groupRightInner ms-0">
                  //         <div className="col-auto refferal">
                  //           <div
                  //             className="row refferalInner flex-nowrap align-items-center gap-1 gap-md-2 cursor-pointer"
                  //             onClick={() => {
                  //             }
                  //             }
                  //           >
                  //             <div className="col-auto px-0 refferalIcon d-grid">
                  //               <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12 12" fill="none">
                  //                 <path fill-rule="evenodd" clip-rule="evenodd" d="M2.95472 3.33046C3.29977 3.03779 3.51876 2.60104 3.51876 2.11314C3.51876 1.23194 2.8044 0.517578 1.9232 0.517578C1.04199 0.517578 0.327637 1.23194 0.327637 2.11314C0.327637 2.92969 0.941015 3.60298 1.73215 3.69738L2.2345 5.3719C1.10217 5.95433 0.327637 7.13443 0.327637 8.49538C0.327637 10.4341 1.89922 12.0056 3.83787 12.0056C5.19885 12.0056 6.3789 11.2311 6.96134 10.0987L8.63585 10.6011C8.73031 11.3922 9.40357 12.0056 10.2201 12.0056C11.1013 12.0056 11.8157 11.2913 11.8157 10.4101C11.8157 9.52886 11.1013 8.81449 10.2201 8.81449C9.73219 8.81449 9.29545 9.03347 9.00276 9.37856L7.32768 8.87602C7.34121 8.75099 7.3481 8.62398 7.3481 8.49538C7.3481 7.75664 7.11987 7.07118 6.73011 6.50572L9.63703 3.5988C9.81765 3.66974 10.0143 3.7087 10.2201 3.7087C11.1013 3.7087 11.8157 2.99434 11.8157 2.11314C11.8157 1.23194 11.1013 0.517578 10.2201 0.517578C9.33892 0.517578 8.62455 1.23194 8.62455 2.11314C8.62455 2.31892 8.66348 2.5156 8.73446 2.69621L5.82755 5.60312C5.26206 5.21335 4.57661 4.98515 3.83787 4.98515C3.70926 4.98515 3.58227 4.99207 3.45725 5.00555L2.95472 3.33046ZM6.07166 8.49538C6.07166 9.72907 5.07156 10.7292 3.83787 10.7292C2.60418 10.7292 1.60409 9.72907 1.60409 8.49538C1.60409 7.26169 2.60418 6.2616 3.83787 6.2616C5.07156 6.2616 6.07166 7.26169 6.07166 8.49538Z" fill="#EFB90B" />
                  //               </svg>
                  //             </div>
                  //             <div className="col-auto ps-0 pe-1 refferalTitle">{desktopHeader.REFERRAL}</div>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                ) : (
                  <>
                    {/* <div className="col-auto d-lg-none d-block">
                      <div className="row">
                        <div className="col-12">
                          <span
                            onClick={() => {
                              setAuthModalObject(pre => ({
                                ...pre,
                                isAuthOpen: true
                              }))
                              setQuery({ action: "login" })
                            }
                            }
                            className="connectBtn cursor-pointer"
                          >{desktopHeader.login}</span>
                        </div>
                      </div>
                    </div> */}
                  </>
                )
              }
              </>
              <div className={`col px-0 ${token ? 'd-xl-block' : 'd-lg-block'} d-none`}>
                <div className="row justify-content-center align-items-center mx-0">
                  <div className="col-auto ps-0">
                    <div className="headerMenuSideIcon" onClick={handleSidebar}>
                      <img src="assets/img/headermenu.svg" alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="row gamesBtns position-relative mx-0">
                      <NavLink to={ROUTES_CONST.INDEX} className="col-auto gameBtn pe-1 ps-0 text-decoration-none">
                        <div className={`row cInner mx-0 gap-1 flex-nowrap align-items-center px-2 ${location.pathname === ROUTES_CONST.INDEX ? '' : 'ps-3'}`}>
                          <div className="col-auto px-0 cIcon d-grid">
                            <img src="assets/img/app/casino.svg" alt="" />
                          </div>
                          <div className="col-auto px-0 cTitle d-grid">{desktopHeader.Casino}</div>
                        </div>
                      </NavLink>
                      <span
                        // to={ROUTES_CONST.SPORTS}
                        onClick={() => navigate(ROUTES_CONST.SPORTS)}
                        className={`col-auto gameBtn px-1 text-decoration-none ${location.pathname === "/sports" && !location.search.includes("racing") ? "active" : ""}`}
                      >
                        <div className="row cInner mx-0 gap-1 flex-nowrap align-items-center px-2">
                          <div className="col-auto px-0 cIcon d-grid">
                            <img src="assets/img/gameSports.png" alt="" />
                          </div>
                          <div className="col-auto px-0 cTitle d-grid">{desktopHeader.Sports}</div>
                        </div>
                      </span>
                      {/* <NavLink
                        to={ROUTES_CONST.POKER}
                        // onClick={() => {
                        //   // dispatch(btUrlAction("55"))
                        //   navigate(`${ROUTES_CONST.POKER}`)
                        // }}
                        className={`col-auto gameBtn px-1 cursor-pointer text-decoration-none  `}>
                        <div className="row cInner mx-0 gap-2 flex-nowrap align-items-center px-2">
                          <div className="col-auto px-0 cIcon d-grid">
                            <img
                              loading="lazy"
                              // src="assets/img/gameRacing.png"
                              src="assets/img/app/casino.svg"
                              alt="" />
                          </div>
                          <div className="col-auto px-0 cTitle d-grid">Poker</div>
                        </div>
                      </NavLink> */}
                      {/* <span
                        // to={}
                        onClick={() => {
                          dispatch(btUrlAction("55"))
                          navigate(`${ROUTES_CONST.SPORTS}?bt-path=%2Fhorse-racing-55`)
                        }}
                        className={`col-auto gameBtn px-1 cursor-pointer text-decoration-none ${location.search.includes("racing") ? "active" : ""} `}>
                        <div className="row cInner mx-0 gap-2 flex-nowrap align-items-center px-2">
                          <div className="col-auto px-0 cIcon d-grid">
                            <img src="assets/img/gameRacing.png" alt="" />
                          </div>
                          <div className="col-auto px-0 cTitle d-grid">{desktopHeader.Racing}</div>
                        </div>
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto pe-0 d-lg-block d-none">
            <Link
              to={ROUTES_CONST.INDEX}
              className="text-decoration-none d-flex align-items-center logoNav"
            >
              <img
                className="image"
                // src="assets/img/header/logonav.svg"
                src="assets/img/flameLogo.png"
                alt="logo"
              />
            </Link>
          </div>
          <div className="col-auto px-0 d-lg-none d-block">
            <Link
              to={ROUTES_CONST.INDEX}
              className="text-decoration-none d-flex align-items-center logoNav "
            >
              <img
                className="image"
                src="assets/img/flameLogo.png"
                alt="logo"
              />
            </Link>
          </div>
          {/* <div className="col-auto d-xl-none d-lg-block d-none ps-0">
              <button
                className="btn menuBtn ps-0 shadow-none border-0 bg-transparent toggleButton"
                //  onClick={() => document.body.classList.remove("asideBar")}
                onClick={sideAppHandler}
              >
                <img src="assets/img/Menu.png" className="w-100 menuicon" alt="" />
                <img
                  src="assets/img/Closeicon.png"
                  className="w-100 closeicon"
                  alt=""
                />
              </button>
          </div> */}
          <div className="col px-sm-0 pe-0 ">
            {token ? (
              <div className="row mx-0 align-items-center justify-content-end dnone">
                {/* <div className="col-auto order-2 order-xl-1">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <div className="dropdown giftDrowpdown ">
                        <Link
                          className="dropdown-toggle giftBtnDrop text-decoration-none d-flex"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="position-relative d-flex align-items-center">
                                <div className="dropImg d-inline-flex">
                                  <img
                                    className="image"
                                    src="assets/img/header/Gift_1_.svg"
                                    alt="gift"
                                  />
                                </div>
                                <span className="giftNotification">1</span>
                              </div>
                            </div>
                            <div className="col ps-0">
                              <div className="dropbtn">{desktopHeader?.BONUS}</div>
                            </div>
                          </div>
                        </Link>
                        <ul className="dropdown-menu dropMenunav ">
                          {
                            bonusData?.map((item) => (
                              <li key={item.name} className="dropdown-item dropItem d-flex px-0 position-relative">
                                <div className="row mx-0 w-100 align-items-center">
                                  <div className="col px-0">
                                    <div className="row mx-0 align-items-center">
                                      <div className="col-auto px-0">
                                        <div className="icongift">
                                          <img
                                            className="image"
                                            src={item.img}
                                            alt="bouns"
                                          />
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="bonusTitle d-flex align-items-center">
                                          {item.name}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto px-0">
                                    <span
                                      className="tretched-link ms-auto text-decoration-none d-flex align-items-center justify-content-center justify-content-center clamBtn lockBtn disabled "
                                    >
                                      <img
                                        className="image"
                                        src="assets/img/header/lock.png"
                                        alt="logo"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))
                          }
                          <div className="dropFooter">
                            <Link
                              to={ROUTES_CONST.VIP_CLUB}
                              className="text-white stretched-lin ms-auto text-decoration-none d-flex align-items-center justify-content-center viewBtn"
                            >
                              view dashboard
                            </Link>
                          </div>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="col-auto d-xl-none d-block px-0 order-1 order-xl-2">
                  <ul className="list-unstyled d-flex align-items-center gap-3 mb-0">
                    <li onClick={() => setWalletModalObject((prev) => ({
                          isWalletOpen: true,
                          selectedTab: WALLET_MODAL_TABS.DEPOSIT
                        }))}>
                      <UserBalanceComp />
                    </li>
                  </ul>
                </div> */}
                {/* <div className="col-auto d-xl-block d-none order-xl-2">
                  <ul className="list-unstyled d-flex align-items-center gap-3 mb-0">
                    <li>
                      <UserBalanceComp />
                    </li>
                    <li>
                      <div
                        onClick={() => setWalletModalObject((prev) => ({
                          isWalletOpen: true,
                          selectedTab: WALLET_MODAL_TABS.DEPOSIT
                        }))}
                        className=" text-decoration-none d-flex align-items-center justify-content-center navBankBtn customBtn cursor-pointer"
                      >
                        {desktopHeader?.BANK}
                      </div>
                    </li>
                    <li>
                      <Link to={ROUTES_CONST.BUY_CRYPTO_URL}
                        target="_blank"
                        className=" text-decoration-none d-flex align-items-center justify-content-center buyCripto cursor-pointer"
                      >
                        {desktopHeader.BUY_CRYPTO}
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <div className="col-auto px-0 order-xl-3 d-flex">
                  <div className="d-flex d-sm-flex align-items-center gap-2 gap-xxl-3 navEndWrapper">
                    <div className="col-auto">
                      <div className="row mx-0 gx-0">
                        <div className="col-auto">
                          {/* <div className="dropdown"> */}
                            <UserBalanceComp setShowUsdtDetailModal={setShowUsdtDetailModal} setShowUsdtBonusModal={setShowUsdtBonusModal} />
                            {/* <div className="dropdown-menu innerUsdtBtn userBalInnerDrop" ref={myDivRef}>
                              <div className="row mx-0">
                                <div className="col-12 px-0">
                                  <div className="row mx-0">
                                    <div className="col px-0">
                                      <div className="searchInp">
                                        <img src="./assets/img/searchGray.png" alt="" />
                                        <input
                                          type="text"
                                          className="form-control shadow-none"
                                          placeholder="Search"
                                          value={currencySearch}
                                          onChange={(e) => setCurrencySearch(e.target.value)}

                                        />
                                      </div>
                                    </div>
                                    <div className="col-auto ps-2 pe-0">
                                      <div className="plusBtn" onClick={() => setShowFavoriteModal(true)}>
                                        +
                                      </div>
                                    </div>
                                    <div className="col-12 px-0">
                                      <div className="dropTabs">
                                        <div className={`tab ${selectedDrop === option.CURRENCY ? "active" : ""} `} onClick={() => setSelectedDrop(option.CURRENCY)}>Currency</div>
                                        <div className={`tab ${selectedDrop === option.mNFT ? "active" : ""} `} onClick={() => setSelectedDrop(option.mNFT)}>mNFT</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {
                                  selectedDrop === option.CURRENCY ? (
                                    <>
                                      <div className="innerScrollDiv">
                                        <div className="col-12 px-0">
                                          {favFiat.length > 0 ? (
                                            <>
                                              {isHidden ? "" : <div className="dropTitle mb-1">Fiat</div>}

                                              <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                                {
                                                  favFiat?.map((item, index) => (
                                                    <li key={index} className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${isHidden && (parseFloat(item.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}  ${item.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}
                                                      onClick={() => {
                                                        setCurrencyLoader((pre) => ({ ...pre, index: index, status: true, type: "fiat" }))
                                                        handleChange(item)
                                                      }}
                                                    >
                                                      <div className="d-flex align-items-center">

                                                        {
                                                          currencyLoader.index == index && currencyLoader.status && currencyLoader.type == 'fiat' ? (
                                                            <><Spinner size="sm" /></>
                                                          ) : (<>
                                                            <img className="rounded" src={item.icon} alt="" />
                                                          </>)
                                                        }
                                                        {item.code}
                                                      </div>
                                                      <div>
                                                        {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}

                                                        {userDetails.fiatStatus ? (
                                                          <span className="smallVal"> {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}
                                                          </span>
                                                        ) : (
                                                          ''
                                                        )
                                                        }
                                                      </div>
                                                    </li>
                                                  ))}
                                              </ul>
                                            </>) : null}
                                        </div>
                                        <div className="col-12 px-0">
                                          {favCrypto.length > 0 ? (
                                            <>
                                              {isHidden ? "" : <div className="dropTitle mb-1">Crypto</div>}

                                              <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                                {favCrypto?.map((item, index) => (

                                                  <li
                                                    key={index}
                                                    className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${isHidden && (parseFloat(item.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}  ${item.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}
                                                    onClick={() => {
                                                      setCurrencyLoader((pre) => ({ ...pre, index: index, status: false, type: "crypto" }))

                                                      handleChange(item)
                                                    }}
                                                  >
                                                    <div className="d-flex align-items-center">

                                                      {
                                                        currencyLoader.index == index && currencyLoader.status && currencyLoader.type == 'crypto' ? (
                                                          <><Spinner size="sm" /></>
                                                        ) : (<>
                                                          <img className="rounded" src={item.icon} alt="" />
                                                        </>)
                                                      }
                                                      {item.code}
                                                    </div>
                                                    <div className="text-end">
                                                      {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}
                                                      {userDetails.fiatStatus ? <span className="smallVal"> {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}</span> : ""}
                                                    
                                                    </div>
                                                  </li>
                                                ))}
                                              </ul>
                                            </>) : null}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="innerScrollDiv d-flex justify-content-center align-items-center">
                                        <div>
                                          <img
                                            src="/assets/img/stacking/noData.svg"
                                            alt=""
                                            style={{
                                              width: "150px"
                                            }}
                                          />
                                          <div className="dropTitle mb-1 text-center mt-3">No Data Available</div>
                                        </div>


                                      </div>
                                    </>
                                  )
                                }

                                <div className="col-12">
                                  <div className="row pt-2 pb-1 dropCheckBoxWrapper">
                                    <div className="col-6 dropCheckBox ps-0">
                                      <input onChange={(e) => handleViewFiat(e.target.checked)} type="checkbox" id="fiat" className="d-none" checked={isChecked} />
                                      <label htmlFor="fiat" className="checkLabel"></label>
                                      <label htmlFor="fiat" className="textLabel">View in fiat</label>
                                    </div>
                                    <div className="col-6 dropCheckBox pe-0 justify-content-end">
                                      <input type="checkbox" id="small" className="d-none"
                                        onChange={(e) => setIsHidden(e.target.checked)}
                                      />
                                      <label htmlFor="small" className="checkLabel"></label>
                                      <label htmlFor="small" className="textLabel">Hide Small</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-auto ps-0 d-xl-block d-none">
                      <div
                        onClick={() => {
                          setWalletModalObject((prev) => ({
                            isWalletOpen: true,
                            selectedTab: WALLET_MODAL_TABS.DEPOSIT
                          }))
                          setQuery({ action: "wallet" })
                        }}
                        className="connectBtn cursor-pointer">{desktopHeader.Deposit}</div>
                    </div>
                    <div className="dropdown dropdownAccount d-xl-block d-none">
                      <span
                        className="dropdown-toggle accountDrop text-decoration-none d-flex searchBtn align-items-center justify-content-center cursor-pointer"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      // data-bs-auto-close="outside"
                      >
                        <div className="row align-items-center flex-nowrap">
                          <div className="col-auto px-0 dropImg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7258 10.2075C20.7258 13.149 19.3939 15.7865 17.2868 17.5761C15.5322 19.0664 13.2401 19.9688 10.7322 19.9688C8.22428 19.9688 5.93216 19.0664 4.17751 17.5761C2.07053 15.7865 0.738525 13.149 0.738525 10.2075C0.738525 4.81654 5.21283 0.446289 10.7322 0.446289C16.2515 0.446289 20.7258 4.81654 20.7258 10.2075ZM10.7322 17.0404C12.4878 17.0404 14.0922 16.4087 15.3204 15.3655C15.4619 15.2454 15.5983 15.1199 15.7293 14.9892C14.6491 13.2826 12.8141 12.1598 10.7322 12.1598C8.65024 12.1598 6.81526 13.2826 5.73504 14.9892C5.86612 15.1199 6.00253 15.2454 6.14393 15.3655C7.37219 16.4087 8.97668 17.0404 10.7322 17.0404ZM10.7322 10.2075C12.388 10.2075 13.7303 8.89649 13.7303 7.27915C13.7303 5.66186 12.388 4.35078 10.7322 4.35078C9.07632 4.35078 7.73407 5.66186 7.73407 7.27915C7.73407 8.89649 9.07632 10.2075 10.7322 10.2075Z" fill="#909399" />
                            </svg>
                          </div>
                          <div className="col-auto px-0 d-lg-block d-none dropdownTitle">
                            <div className="dropbtn ">
                              {/* {userDetails?.username} */}
                            </div>
                          </div>
                          {/* <div className="col-auto px-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
                              <path d="M8.12052 1.15573L4.72485 5.96627C4.49141 6.29698 4.09724 6.29698 3.8638 5.96627L0.468099 1.15573C0.212977 0.794272 0.405155 0.201172 0.777418 0.201172H7.81117C8.18346 0.201172 8.37564 0.794272 8.12052 1.15573Z" fill="white" />
                            </svg>
                          </div> */}
                        </div>
                      </span>
                      <ul className="dropdown-menu dropmenu mb-0 accountDrop rounded-3 p-3">
                        <li>
                          <NavLink
                            to={ROUTES_CONST.ACCOUNT}
                            className='text-decoration-none'
                            onClick={() =>
                              dispatch({ type: "PROFILE", payload: "Profile" })
                            }
                          >
                            <span className="dropdown-item profileBtn d-flex align-items-center">
                              <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/profile.png"
                                  alt="profile"
                                  className="h-100 w-100 img-fluid"
                                />
                              </span>
                              {desktopHeader.ACCOUNT_OPTIONS.Profile}
                            </span>
                          </NavLink>
                        </li>
                        <li className="accountContent">
                          <NavLink
                            to={ROUTES_CONST.ACCOUNT}
                            className='text-decoration-none'
                            onClick={() =>
                              dispatch({ type: "WALLET", payload: "Wallet" })
                            }
                          >
                            <span className="dropdown-item profileBtn d-flex align-items-center">
                              <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/wallet.png"
                                  alt="Wallet"
                                  className="h-100 w-100 img-fluid"
                                />
                              </span>
                              {desktopHeader.ACCOUNT_OPTIONS.Wallet}

                            </span>
                          </NavLink>
                        </li>
                        <li className="accountContent">
                          <NavLink
                            to={ROUTES_CONST.ACCOUNT}
                            className='text-decoration-none'
                            onClick={() =>
                              dispatch({
                                type: "AFFILIATE",
                                payload: "Affiliate",
                              })
                            }
                          >
                            <span className="dropdown-item profileBtn d-flex align-items-center">
                              <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/affiliate.png"
                                  alt="Affiliate"
                                  className="h-100 w-100 img-fluid"
                                />
                              </span>
                              {desktopHeader.ACCOUNT_OPTIONS.Affiliate}

                            </span>
                          </NavLink>
                        </li>
                        <li className="accountContent">
                          <NavLink
                            to={ROUTES_CONST.ACCOUNT}
                            className='text-decoration-none'
                            onClick={() =>
                              dispatch({
                                type: "DEPOSITS",
                                payload: "Deposits",
                              })
                            }
                          >
                            <span className="dropdown-item profileBtn d-flex align-items-center">
                              <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/deposits.png"
                                  alt="Deposits"
                                  className="h-100 w-100 img-fluid"
                                />
                              </span>
                              {desktopHeader.ACCOUNT_OPTIONS.Deposits}

                            </span>
                          </NavLink>
                        </li>
                        <li className="accountContent">
                          <NavLink
                            to={ROUTES_CONST.ACCOUNT}
                            className='text-decoration-none'
                            onClick={() =>
                              dispatch({
                                type: "WITHDRAW",
                                payload: "Withdrawals",
                              })
                            }
                          >
                            <span className="dropdown-item profileBtn d-flex align-items-center">
                              <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/withdraw.png"
                                  alt="Withdrawals"
                                  className="h-100 w-100 img-fluid"
                                />
                              </span>

                              {desktopHeader.ACCOUNT_OPTIONS.Withdrawals}
                            </span>
                          </NavLink>
                        </li>
                        <li className="accountContent">
                          <NavLink
                            to={ROUTES_CONST.ACCOUNT}
                            className='text-decoration-none'
                            onClick={() =>
                              dispatch({
                                type: "TRANSACTIONS",
                                payload: "Transactions",
                              })
                            }
                          >
                            <span className="dropdown-item profileBtn d-flex align-items-center">
                              <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/withdraw.png"
                                  alt="Withdrawals"
                                  className="h-100 w-100 img-fluid"
                                />
                              </span>

                              {desktopHeader.ACCOUNT_OPTIONS.Transactions}
                            </span>
                          </NavLink>
                        </li>
                        <li className="accountContent">
                          <NavLink
                            to={ROUTES_CONST.ACCOUNT}
                            className='text-decoration-none'
                            onClick={() =>
                              dispatch({ type: "SETTING", payload: "Settings" })
                            }
                          >
                            <span className="dropdown-item profileBtn d-flex align-items-center">
                              <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/setting.png"
                                  alt="Settings"
                                  className="h-100 w-100 img-fluid"
                                />
                              </span>
                              {desktopHeader.ACCOUNT_OPTIONS.Settings}

                            </span>
                          </NavLink>
                        </li>
                        <li className="accountContent">
                          <span
                            className="dropdown-item profileBtn d-flex align-items-center cursor-pointer"
                            onClick={logoutFunction}
                          >
                            <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                              <img
                                src="assets/img/sidebar/logout.png"
                                alt="Log Out"
                                className="h-100 w-100 img-fluid"
                              />
                            </span>
                            {desktopHeader.ACCOUNT_OPTIONS.Log_Out}

                          </span>
                        </li>
                      </ul>
                    </div>

                    {/* search offcanvas */}
                    {/* <NavLink
                      className="searchBtn d-flex align-items-center"
                      to={ROUTES_CONST.SLOT_PAGE}
                    >
                      <img
                        className="image"
                        src="assets/img/header/search.svg"
                        alt="search"
                      />
                    </NavLink> */}
                    {/* message offacanvas */}
                    {/* <span
                      className=" searchBtn d-flex align-items-center"
                      data-bs-toggle="offcanvas"
                      href="#message"
                      role="button"
                      aria-controls="message"
                      onClick={() => {
                        socket.emit('joinRoom', { msg: null })
                        document.body.classList.toggle('showMsgOffcanvas')
                      }}
                    >
                      <img
                        className="image"
                        src="assets/img/header/chat.svg"
                        alt="search"
                      />
                    </span> */}
                    {/* language */}
                    {/* <span
                      className="searchBtn d-flex align-items-center"
                      // data-bs-toggle="offcanvas"
                      // href="#message"
                      // role="button"
                      // aria-controls="message"
                      // onClick={() => {
                      //   socket.emit('joinRoom', { msg: null })
                      //   document.body.classList.toggle('showMsgOffcanvas')
                      // }}
                      onClick={() => {
                        setShowLanguage(true);
                        setLanguageSelectedTab(TABS_OPTIONS.language);
                      }}
                    >
                      <img
                        className="image w-100"
                        src="assets/img/aboutJonathan/Global.png"
                        alt="search"
                      />
                    </span> */}
                    {/* <span>
                      <div className="gd"><img src="assets/img/whitelogo.png" alt="" /></div>
                      <div className="gdTxt"><img src="assets/img/logotxt.png" alt="" /></div>
                    </span> */}
                    {/* <div className="col-auto borderStart  d-lg-block d-none">
                      <span
                        onClick={() => {
                          setResultObject({ ...resultObject, isResultOpen: true })
                        }}
                        to={ROUTES_CONST.SLOT_PAGE}

                        className="searchBtn d-flex align-items-center cursor-pointer">
                        <img className="w-100" src="assets/img/headerSearch.png" alt="" />
                      </span>

                    </div> */}
                    {/* <div className="col-auto borderStart ps-3 d-lg-block d-none">
                      <span
                        className="headerSearchBtn googleLangIcon">
                        <div id="google_translate_element"></div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M4.76244 4.97571H4.54296L4.13281 7.02696H5.17259L4.76244 4.97571Z" fill="#969CAA" />
                          <path d="M13.0586 9.07825C13.28 9.67308 13.5746 10.1546 13.9005 10.5643C14.2264 10.1546 14.5552 9.67304 14.7766 9.07825H13.0586Z" fill="#969CAA" />
                          <path d="M15.9859 3.40308H9.61738L10.9453 14.0586C10.9688 14.4944 10.8496 14.9048 10.5652 15.226L8.31641 17.796H15.9859C16.8343 17.796 17.5244 17.1059 17.5244 16.2576V4.9757C17.5244 4.12737 16.8343 3.40308 15.9859 3.40308ZM15.9859 9.07821H15.849C15.5572 10.0144 15.0945 10.7469 14.6136 11.3244C14.9903 11.6688 15.3931 11.9513 15.7936 12.2678C16.0145 12.4446 16.0506 12.767 15.8733 12.9884C15.6968 13.2094 15.373 13.2453 15.1526 13.068C14.7174 12.7246 14.309 12.437 13.9005 12.0622C13.492 12.437 13.1177 12.7246 12.6826 13.068C12.4622 13.2453 12.1384 13.2094 11.9619 12.9884C11.7846 12.767 11.8207 12.4446 12.0416 12.2678C12.4421 11.9513 12.8107 11.6688 13.1874 11.3244C12.7065 10.7469 12.278 10.0144 11.9862 9.07821H11.8492C11.5658 9.07821 11.3364 8.84885 11.3364 8.5654C11.3364 8.28195 11.5658 8.05258 11.8492 8.05258H13.3877V7.53977C13.3877 7.25632 13.6171 7.02696 13.9005 7.02696C14.1839 7.02696 14.4133 7.25632 14.4133 7.53977V8.05258H15.9859C16.2694 8.05258 16.4988 8.28195 16.4988 8.5654C16.4988 8.84885 16.2694 9.07821 15.9859 9.07821Z" fill="#969CAA" />
                          <path d="M8.3669 1.63963C8.27125 0.871403 7.61471 0.291992 6.8405 0.291992H1.55797C0.709641 0.291992 0.0195312 0.982102 0.0195312 1.83043V13.1807C0.0195312 14.029 0.709641 14.7191 1.55797 14.7191C4.62357 14.7191 6.82891 14.7191 9.64507 14.7191C9.79468 14.5481 9.92008 14.4411 9.92537 14.2178C9.92667 14.1619 8.37384 1.69515 8.3669 1.63963ZM6.30813 10.0938C6.0362 10.1498 5.76075 9.97322 5.70468 9.69166L5.37693 8.05257H3.927L3.59924 9.69166C3.54417 9.96909 3.27672 10.1514 2.9958 10.0938C2.71837 10.0382 2.53806 9.7683 2.59365 9.49036L3.61924 4.36223C3.66731 4.12284 3.87767 3.95006 4.12204 3.95006H5.18185C5.42622 3.95006 5.63658 4.12284 5.68465 4.36223L6.71028 9.49036C6.76586 9.7683 6.58559 10.0382 6.30813 10.0938Z" fill="#969CAA" />
                          <path d="M6.01172 15.7449L6.09972 16.4485C6.15838 16.9197 6.47147 17.4002 6.98647 17.6401C7.95836 16.5703 7.37037 17.2175 8.70813 15.7449H6.01172Z" fill="#969CAA" />
                        </svg>
                      </span>

                    </div> */}
                    <div className="col-auto px-0 borderStart  d-lg-block d-none">
                      <span
                        // className=" searchBtn d-flex align-items-center"
                        className="headerMessagesBtn searchBtn d-flex align-items-center cursor-pointer activeStateBtn active"
                        data-bs-toggle="offcanvas"
                        href="#message"
                        role="button"
                        aria-controls="message"
                        onClick={() => {
                          socket.emit('joinRoom', { msg: null })
                          document.body.classList.toggle('showMsgOffcanvas')
                        }}
                      >
                        <img className="w-100" src="assets/img/msg.png" alt="" />
                      </span>
                    </div>
                    {/* notification btn */}
                    <span
                      className={`searchBtn d-flex align-items-center cursor-pointer activeStateBtn ${notifyStatus?.status ? "active" : ""}`}
                      onClick={() => setIsNotificationOpen(true)}
                    >
                      <img
                        className="image w-100"
                        src="assets/img/header/notificationIcon.png"
                        alt="search"
                      />
                    </span>
                    {/* <span
                      className="searchBtn d-flex align-items-center cursor-pointer"
                      onClick={() => {
                        setShowLanguage(true);
                        setLanguageSelectedTab(TABS_OPTIONS.language);
                      }}
                    >
                      <img
                        className="image w-100"
                        src="assets/img/aboutJonathan/Global.png"
                        alt="search"
                      />
                    </span> */}
                  </div>
                  <span
                    className={`searchBtn d-none d-sm-none align-items-center cursor-pointer me-2 activeStateBtn ${notifyStatus?.status ? "active" : ""}`}
                    onClick={() => setIsNotificationOpen(true)}
                  >
                    <img
                      className="image w-100"
                      src="assets/img/header/notificationIcon.png"
                      alt="search"
                    />
                  </span>
                  {/* <span
                    className="searchBtn d-none d-sm-none align-items-center cursor-pointer"
                    onClick={() => {
                      setShowLanguage(true);
                      setLanguageSelectedTab(TABS_OPTIONS.language);
                    }}
                  >
                    <img
                      className="image w-100"
                      src="assets/img/aboutJonathan/Global.png"
                      alt="search"
                    />
                  </span> */}
                </div>
              </div>
            ) : (
              <div className="row align-items-center justify-content-end w-100 dnone">
                <div className="col-auto px-0">
                  <div className="row align-items-center">
                    <div className="col-auto ps-2 pe-2 d-lg-block d-none">
                      <span
                        className="connectBtn connectBtnSecond cursor-pointer"
                        onClick={() => {
                          setAuthModalObject(pre => ({
                            // ...pre,
                            selectedTab: AUTH_MODAL_TABS.LOG_IN,
                            isAuthOpen: true,
                          }))
                          setQuery({ action: "login" })
                        }}
                      >{desktopHeader.login}</span>
                    </div>
                    
                    <div className="col-auto ps-2 pe-2 d-lg-none d-block">
                      <span
                        className="connectBtn cursor-pointer"
                        onClick={() => {
                          setAuthModalObject(pre => ({
                            // ...pre,
                            selectedTab: AUTH_MODAL_TABS.LOG_IN,
                            isAuthOpen: true,
                          }))
                          setQuery({ action: "login" })
                        }}
                      >{desktopHeader.login}</span>
                    </div>
                    <div className="col-auto ps-2 pe-2 d-lg-block d-none">
                      <span
                        className="connectBtn cursor-pointer"
                        onClick={() => {
                          setAuthModalObject(pre => ({
                            // ...pre,
                            selectedTab: AUTH_MODAL_TABS.SIGN_UP,
                            isAuthOpen: true

                          }))
                          setQuery({ action: "register" })
                        }}
                      >{desktopHeader.Sign_up}</span>
                    </div>
                    {/* <div className="col-auto px-2 d-lg-block d-none">
                      <span className="searchBtn d-flex align-items-center cursor-pointer"
                        onClick={() => {
                          setResultObject({ ...resultObject, isResultOpen: true })
                        }}
                      >
                        <img className="w-100" src="assets/img/headerSearch.png" alt="" />
                      </span>
                    </div> */}
                    {/* <div className="col-auto px-2 ps-1 d-lg-block d-none">
                      <span className="searchBtn d-flex align-items-center cursor-pointer"
                      >
                        <img className="w-100" src="assets/img/msg.png" alt="" />
                      </span>
                    </div> */}
                    {/* <div className="col-auto px-lg-2 px-0">
                      <div 
                      className="usdtBtn text-decoration-none cursor-pointer">
                        <div className="row mx-0 gap-2 align-items-center">
                          <div className="col-auto px-0 d-grid">
                            <img src="assets/img/usdt.png" alt="" />
                          </div>
                          <div className="col-auto px-0 d-grid">USDT</div>
                          <div className="col-auto usdtValue px-0 d-lg-none d-grid">0.00</div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-auto px-2 d-lg-block d-none">
                      <span
                        data-bs-toggle="offcanvas"
                        href="#message"
                        role="button"
                        aria-controls="message"
                        onClick={() => {
                          socket.emit('joinRoom', { msg: null })
                          document.body.classList.toggle('showMsgOffcanvas')
                        }}
                        className="headerMessagesBtn"
                      >
                        <img src="assets/img/headerMessages.png" alt="" />
                      </span>
                    </div> */}
                  </div>
                </div>
                {/* <div className="col-auto">
                  <span
                    className="text-decoration-none d-flex align-items-center loginBtn cursor-pointer"
                    onClick={() =>
                      setAuthModalObject((pre) => ({
                        isAuthOpen: true,
                        selectedTab: AUTH_MODAL_TABS.LOG_IN,
                      }))
                    }
                  >
                    {desktopHeader?.LOGIN}
                  </span>
                </div>
                <div className="col-auto pe-0">
                  <span
                    className="text-decoration-none d-flex align-items-center registerBtn cursor-pointer"
                    onClick={() =>
                      setAuthModalObject((pre) => ({
                        selectedTab: AUTH_MODAL_TABS.SIGN_UP,
                        isAuthOpen: true,
                      }))
                    }
                  >
                    {desktopHeader?.REGISTER}
                  </span>
                </div> */}

                {/* language button for sm md screens */}
                {/* <span
                  className="searchBtn d-flex align-items-center ms-3 cursor-pointer"
                  onClick={() => {

                    setShowLanguage(true);
                    setLanguageSelectedTab(TABS_OPTIONS.language);
                  }}
                >
                  <img
                    className="image w-100"
                    src="assets/img/aboutJonathan/Global.png"
                    alt="search"
                  />
                </span> */}
              </div>
            )}
          </div>
        </div>
      </div >
      <SetCurrencyModal
        isOpen={isUnAuthCurrency}
        setIsOpen={setIsUnAuthCurrency}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {/* <AuthModal /> */}
      {
        walletModalObject?.isWalletOpen && (

          <NewWallet />
        )
      }

      <MessageOffcanvas />
      <LogoutModal />
      <ReferralModal />
      <ResultModal />
      <CadoWallet />
      <RampAnalysisModal />
      <UnAuthCurrencyModal
        isOpen={showLanguage}
        setIsOpen={setShowLanguage}
        selectedTab={languageSelectedTab}
        setSelectedTab={setLanguageSelectedTab}

      />
      <UpdatedAuthModal />
      <NotificationOffcanvas />
      <UsdtDetails showUsdtDetailModal={showUsdtDetailModal} setShowUsdtDetailModal={setShowUsdtDetailModal} />
      <UsdtBonusModal showUsdtBonusModal={showUsdtBonusModal} setShowUsdtBonusModal={setShowUsdtBonusModal} setShowBonusHistoryModal={setShowBonusHistoryModal} />
      <UsdtBonusHistory showBonusHistoryModal={showBonusHistoryModal} setShowBonusHistoryModal={setShowBonusHistoryModal} />
      <FreeSpinMsgModal />
      <FiatModal showFiatModal={showFiatModal} setShowFiatModal={setShowFiatModal} />
      <AddCurrency showFavoriteModal={showFavoriteModal} setShowFavoriteModal={setShowFavoriteModal} />
      <DepositeMessage showDepositMsg={showDepositMsg} setShowDepositMsg={setShowDepositMsg} depositMsgData={depositMsgData}/>
    </>
  );
};

export default AppHeader;


// <div className="dropdown-menu innerUsdtBtn">
// <div className="row mx-0 gap-2 align-items-center py-2">
//   <div className="col-auto pe-0 d-grid">
//     <img src={userBalance?.setCurrencyData?.icon} className="coinImg" alt="" />
//   </div>
//   <div className="col-auto px-0 d-flex align-items-center usdtTxtt">
//     {/* USDT */}
//     {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}   :

//     <img
//       src="assets/img/header/exclamationYellow.png" alt=""
//       onClick={() => setShowUsdtDetailModal(true)} />
//   </div>
//   <div className="col ps-0 d-flex flex-column align-items-end">
//     <div className="usdtValue">
//       {userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(2) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : "0.00"}
//     </div>
//     <div className="usdtValue2"
//       onClick={() => setShowUsdtBonusModal(true)}
//     >
//       <img
//         src="assets/img/header/lockYellow.png" alt="" />
//       $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
//     </div>
//   </div>
// </div>
// <div className="col-12 px-0">
//   <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
//     {/* {
//       currencyExchange?.length ?
//         currencyExchange?.map((item) => (
//           <li className="usdtImageDropdownInnerList d-flex justify-content-between">
//             <div>
//               <img className="rounded" src={item?.icon} alt="" />
//               {item?.name}
//             </div>
//             <div>
//               {Number(item?.usdAmount)?.toFixed(2)}
//             </div>
//           </li>
//         ))
//         : ''
//     } */}
//   </ul>
// </div>
// </div>