import { connect } from 'socket.io-client';

// for production build
export const socket = connect("https://api.shindakimfalme.com/");
// export const socket = {
//     on:()=>{},
//     emit:()=>{},
//     disconnect:()=>{},
// };


// for development mode
// export const socket = connect("dev socket url comes here""


console.log('socket', socket)


