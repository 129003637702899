import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const BonusDetailModal = ({ showBonusModal, setShowBonusModal }) => {
    const { t } = useTranslation()
    const VIPClubLabels = t("VIPClubLabels", { returnObjects: true })
    const userDetails = useSelector(state=>state.userDetails)
    const userBonusDetail = useSelector(state => state.userBonusDetail)

    return (
        <div className={`modal fade bonusModal ${showBonusModal ? 'show d-block' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered bonusModalDialog">
                <div className="modal-content bonusModalContent">
                    <div className="modal-header bonusModalHeader d-flex align-items-center position-relative">
                        <h1 className="modal-title bonusModalTitle" id="exampleModalLabel">{VIPClubLabels.BONUS_DETAILS}</h1>
                        <button type="button" className="bonusDetailCloseBtn shadow-none" onClick={() => setShowBonusModal(false)}><img src="assets/img/vipPage/close.png" alt="" /></button>
                    </div>
                    <div className="modal-body bonusModalBody">
                        <div className="row align-items-center">
                            <div className="col-sm-5 col-6">
                                <div className="modalBox">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="user"><img src={userDetails?.image} alt="" /></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="total">{VIPClubLabels.total_rewarded}</div>
                                            <div className="dollor">${userBonusDetail?.bonusData?.freeSpinAmount ? Number(userBonusDetail?.bonusData?.freeSpinAmount).toFixed(2) : '0.00'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-7 col-6 ps-0">
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto">
                                        <div className="modalIcon"><img src="assets/img/vipPage/coinsBag.png" alt="" /></div>
                                    </div>
                                    <div className="col">
                                        <div className="row d-flex justify-content-between">
                                            <div className="txt col-auto px-0">{VIPClubLabels.Deposit}</div>
                                            <div className="dollor col-auto">${Number(userDetails?.totalbonus).toFixed(2)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto">
                                        <div className="modalIcon"><img src="assets/img/vipPage/b2.png" alt="" /></div>
                                    </div>
                                    <div className="col">
                                        <div className="row d-flex justify-content-between">
                                            <div className="txt col-auto px-0">{VIPClubLabels.Daily}</div>
                                            <div className="dollor col-auto">$0.00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto">
                                        <div className="modalIcon"><img src="assets/img/vipPage/b1.png" alt="" /></div>
                                    </div>
                                    <div className="col">
                                        <div className="row d-flex justify-content-between">
                                            <div className="txt col-auto px-0">{VIPClubLabels.Weekly}</div>
                                            <div className="dollor col-auto">$0.00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto">
                                        <div className="modalIcon"><img src="assets/img/vipPage/b3.png" alt="" /></div>
                                    </div>
                                    <div className="col">
                                        <div className="row d-flex justify-content-between">
                                            <div className="txt col-auto px-0">{VIPClubLabels.Monthly}</div>
                                            <div className="dollor col-auto">$0.00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto">
                                        <div className="modalIcon"><img src="assets/img/vipPage/ring.png" alt="" /></div>
                                    </div>
                                    <div className="col">
                                        <div className="row d-flex justify-content-between">
                                            <div className="txt col-auto px-0">{VIPClubLabels.Cashback}</div>
                                            <div className="dollor col-auto">$0.00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto">
                                        <div className="modalIcon"><img src="assets/img/vipPage/b4.png" alt="" /></div>
                                    </div>
                                    <div className="col">
                                        <div className="row d-flex justify-content-between">
                                            <div className="txt col-auto px-0">{VIPClubLabels.Rackback}</div>
                                            <div className="dollor col-auto">$0.00</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row align-items-center ">
                                    <div className="col-auto">
                                        <div className="modalIcon"><img src="assets/img/vipPage/coinsBag.png" alt="" /></div>
                                    </div>
                                    <div className="col">
                                        <div className="row d-flex justify-content-between">
                                            <div className="txt col-auto px-0">{VIPClubLabels.freeSpinBonus}</div>
                                            <div className="dollor col-auto">${userBonusDetail?.bonusData?.freeSpinAmount ? Number(userBonusDetail?.bonusData?.freeSpinAmount).toFixed(2) : '0.00'}</div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BonusDetailModal