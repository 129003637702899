import React, { useRef, useState } from "react";
import { postAPI, postAPIAuth } from "../../../service/apiInstance";
import { useSelector } from "react-redux";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import InGameModal from "./InGameModal";
import { useAuth } from "../../../hooks/useAuth";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { AUTH_MODAL_TABS, SM_SCREEN_WIDTH } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import ModeOptionModal from "./ModeOptionModal";
import { useLocation } from "react-router-dom";

const PlayingGame = ({ setIsOpen, data, game, getGameData, demo, setDemo, isLoading, showError }) => {
    const { token } = useAuth()
    const userDetails = useSelector(state => state.userDetails)
    const { setAuthModalObject } = useAuthModal()
    const iframeRef = useRef()
    const { t } = useTranslation()
    const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true })
    const isSmScreen = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })
    const [showIframe, setShowIframe] = useState(false)
    const [showOptionModal, setShowOptionModal] = useState(true)
    const location = useLocation()
    const userBonusDetail = useSelector(state => state.userBonusDetail)

    useEffect(() => {
        if (location?.search?.includes('freeSpinGame')) {
            // if (userDetails?.freeSpinLeft === 0) {
            //     setShowOptionModal(true)
            // } else {
            //     setShowOptionModal(true)
            // }
            setShowOptionModal(false)

        } else {
            setShowOptionModal(true)
        }
    }, [location, userDetails])

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setShowIframe(true)
            }, 4000)
        }
    }, [isLoading])


    // Function for to add fav
    const FavouriteGame = async () => {
        if (token) {
            let params = {
                "gameId": data?._id,
                "userId": userDetails?.id
            }
            const res = await postAPIAuth('crypto/create-favourite-games', params)
            if (res.data.status) succesToaster(res.data.message)
            getGameData()
        } else {
            setAuthModalObject(pre => ({ isAuthOpen: true, selectedTab: AUTH_MODAL_TABS.LOG_IN }))
        }
    }

    useEffect(() => {
        if (isSmScreen && !showOptionModal) {
            const iframe = iframeRef.current;
            if (iframe) {
                if (iframe?.requestFullscreen) {
                    iframe?.requestFullscreen();
                } else if (iframe?.mozRequestFullScreen) {
                    iframe?.mozRequestFullScreen();
                } else if (iframe?.webkitRequestFullscreen) {
                    iframe?.webkitRequestFullscreen();
                } else if (iframe?.msRequestFullscreen) {
                    iframe?.msRequestFullscreen();
                }
            }
        }
    }, [showOptionModal])

    const toggleFullScreen = (e) => {
        e.stopPropagation();
        const iframe = iframeRef.current;
        if (iframe) {
            if (iframe?.requestFullscreen) {
                iframe?.requestFullscreen();
            } else if (iframe?.mozRequestFullScreen) {
                iframe?.mozRequestFullScreen();
            } else if (iframe?.webkitRequestFullscreen) {
                iframe?.webkitRequestFullscreen();
            } else if (iframe?.msRequestFullscreen) {
                iframe?.msRequestFullscreen();
            }
        }
    };
    //  Function for Like
    const LikingGame = async () => {
        let params = {
            "gameId": data?._id,
            "userId": userDetails?.id
        }
        const res = await postAPIAuth('crypto/like-games', params)
        if (res.data.status) succesToaster(res.data.message)
        getGameData()
    }

    return (
        <div className="container-fluid holdwinHero gamesHeroSection pb-3">
            <div className="row gamesHeader gap-sm-0 gap-3">
                <div className="col pe-1">
                    <div className="d-flex align-items-center gap-3">
                        <div className="gamesHeading">{data?.name}</div>
                        <span className="Hacksaw text-decoration-none fw-bold">{data?.provider}</span>
                    </div>
                </div>
                <div className="col-auto ps-0">
                    <div className="d-flex align-items-center gap-2 justify-content-sm-start justify-content-between h-100">
                        <div className="d-flex align-items-center gap-2">
                            {/* <span
                                onClick={() => setDemo(!demo)}
                                className={`text-decoration-none btn bookmarkIcon text-dark d-flex align-items-center justify-content-center cursor-pointer ${demo ? "bg-warning " : "bg-light"} `}
                            >
                                DEMO
                            </span> */}

                            {/* <span
                            >
                                <input
                                    type="checkbox"
                                    className="switchBtn"
                                    onChange={(e) => setDemo(e.target.checked)}
                                ></input>
                                <span className="text-light p-1">
                                    Demo Mode
                                </span>
                            </span> */}



                            <span
                                onClick={FavouriteGame}
                                className={`text-decoration-none bookmarkIcon d-flex align-items-center justify-content-center cursor-pointer ${data?.isFav ? "active" : ""} `}
                            >
                                <img src="assets/img/sidebar/bookmark.png" alt="bookmark" className="h-100" />
                            </span>
                            <span
                                onClick={toggleFullScreen}
                                className="text-decoration-none bookmarkIcon d-flex align-items-center justify-content-center cursor-pointer"
                            >
                                <img src="assets/img/sidebar/expand.png" alt="expand" className="h-100" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                {token ? <>
                    <div className="col-12 px-0 position-relative">
                        {/* <div className="iframeMain rounded-2 overflow-hidden">
                            <iframe
                                ref={iframeRef}
                                className="game-iframe w-100 h-100" src={game?.url} allow="autopaly fullscreen" allowFullScreen />
                        </div>
                        <InGameModal game={game} /> */}
                        {
                            !isSmScreen ? <>

                                <div className="iframeMain bg-black rounded-2 overflow-hidden position-relative">
                                    <iframe
                                        ref={iframeRef}
                                        title="game iframe"
                                        className="game-iframe w-100 h-100"
                                        src={showOptionModal ? "" : game?.url}
                                        // src={game?.url}
                                        allow="autopaly fullscreen"
                                        allowFullScreen
                                    />
                                    {
                                        showOptionModal ? <ModeOptionModal isLoading={isLoading} setDemo={setDemo} setShowOptionModal={setShowOptionModal} showError={showError}/> : ''
                                    }

                                </div>
                                {/* <div className="iframeMain rounded-2 bg-black overflow-hidden d-flex align-items-center justify-content-center"> */}

                                {/* </div> */}
                                <InGameModal game={game} />
                            </> : ''
                        }
                    </div>
                    <div className="col-12 px-0 pt-md-4 pt-sm-3 pt-2">
                        <div className="d-flex align-itemscenter gap-2">
                            <div className="provablyfairCard d-flex align-items-center">
                                <div className="verifiedMain h-100 d-flex align-items-center ">
                                    <div className="verifiedIcon d-flex align-items-center justify-content-center">
                                        <img src="assets/img/sidebar/verified.png" alt="verified" className="h-100 w-100 img-fluid" />
                                    </div>
                                </div>
                                <div className="verifiedTitle text-center w-100 ">
                                    {PlaySlotLabels.THIS_GAME_IS}
                                </div>
                            </div>
                            <div className="lh-sm d-inline-flex align-items-center justify-content-center flex-column">
                                <span className="verifiedTitle">{PlaySlotLabels.Verify_Fairness}</span>
                                <div className="verifiedTitle pt-1">{`(`}{PlaySlotLabels.Advanced_Users}{`)`}</div>
                            </div>
                        </div>
                    </div>

                    {
                        isSmScreen ? <>
                            {
                                !showIframe ?
                                    <div className="vh-100 vw-100 iframeLoader position-fixed d-flex align-items-center justify-content-center top-0 start-0">
                                        {/* <video src="assets/img/gameLoader.mp4" alt="" className="h-100 w-100 object-fit-contain" style={{ mixBlendMode: 'screen' }} autoPlay muted loop /> */}
                                        <img src="assets/img/flameLogo.png" alt="" className="h-100 w-100 object-fit-contain" />
                                    </div>
                                    : ''
                            }
                            <div className={`modal fade p-0 show d-block gameModalScreen ${showIframe ? '' : 'invisible'}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-fullscreen">
                                    <div className="modal-content p-0" >
                                        <div className="modal-body p-0" style={{ background: '#0d131c' }}>
                                            <div className="iframeMain bg-black position-relative overflow-hidden">
                                                <iframe
                                                    ref={iframeRef}
                                                    className="game-iframe w-100 h-100"
                                                    src={game?.url}
                                                    allow="autopaly fullscreen"
                                                    allowFullScreen
                                                    title="game mobile iframe"
                                                    style={{ backgroundColor: '#0d131c' }}
                                                />
                                                {
                                                    showOptionModal ? <ModeOptionModal isLoading={isLoading} setDemo={setDemo} setShowOptionModal={setShowOptionModal} showError={showError}/> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : ''
                    }
                </> : <>
                    <div className="col-12 ">
                        <div className="blinkLayer">
                            <div className="msgText">{PlaySlotLabels.Please_Log_In}</div>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default PlayingGame