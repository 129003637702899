import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { useWalletModal } from "../../hooks/useWallet";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { APP_NAME, AUTH_MODAL_TABS, WALLET_MODAL_TABS } from "../../constants";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useDownloadModal } from "../../hooks/useDownload";
import { socket } from "../../service/socket";
import { useTranslation } from "react-i18next";
import { useResultModal } from "../../hooks/useResult";
import FlipClockContainer from "./Flip";

const AppFooter = () => {
  const { setWalletModalObject } = useWalletModal()
  const { token } = useAuth()
  const { setAuthModalObject } = useAuthModal();
  const navigate = useNavigate()
  const { t } = useTranslation()
  const footerLabel = t("appFooter", { returnObjects: true })
  const { setResultObject } = useResultModal()
  const [query, setQuery] = useSearchParams()

  const sideAppHandler = () => {
    const exists = document.body.classList

    if (exists["value"] === "asideBar") {
      document.body.classList.remove("asideBar")
    } else {
      document.body.classList.add("asideBar")
    }
  }

  const handleLiveSupport = () => {
    const btn = document.getElementById('zsiq_float')
    if (btn) {
      btn.click()
    }
  }

  return (
    <div className="footerSection text-white">
      {/* <FlipClockContainer/> */}
      <div className="container-fluid footer footerLinks footerFluid pt-5">
        <div className="row mx-0">
          <div className="col-lg-3 col-7 order-lg-1 order-4 pt-lg-0 pt-4">
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <div className="footerLogo"><img src="assets/img/flameLogo.png" className="object-fit-contain" alt="" /></div>
              </div>
              <div className="col-12">
                <div className="para">
                  {footerLabel.D_App_Win_is}
                  </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-5 d-lg-flex justify-content-center align-items-cente order-lg-2 order-5 pt-lg-0 pt-4">
            <div className="midLogo row align-items-cente">
              <div className="col-12 d-flex flex-column px-0 text-center align-items-cente gap-4">
                {/* <div className="gc me-4"
                  onClick={() => {
                    // window.open('assets/pdf/License.pdf', '_blank')
                  }}
                ><img src="assets/img/footer/gc.png" alt="" />
                </div> */}
                <div className="plus"><img src="assets/img/footer/eighteenplus.png" alt="" /></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-lg-3 order-1">
            <div className="row">
              <div className="col-4 col-sm-4">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">Platform</li>
                  <li className="">
                    <Link to={ROUTES_CONST.AFFILIATE_PAGE}
                      className="linkLi text-decoration-none">{footerLabel.Affiliate_Program}</Link></li>
                  <li className=""
                    id="my_custom_link"
                    onClick={handleLiveSupport}
                  > <span className="linkLi text-decoration-none">{footerLabel.LiveSupport}</span></li>
                </ul>
              </div>
              <div className="col-4 col-sm-4 px-0 order-lg-4 order-2">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">{footerLabel.Legal}</li>
                  <li className=""> <Link to={ROUTES_CONST.RESPONSIBLE_GAMBLING} className="linkLi text-decoration-none">{footerLabel.ResponsibleGambling}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.NEW_AML_POLICY} className="linkLi text-decoration-none">{footerLabel.AML_Policy}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.SPORTS_POLICY} className="linkLi text-decoration-none">{footerLabel.SportsPolicy}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.TERMS_AND_CONDITIONS} className="linkLi text-decoration-none">{footerLabel.TermsCondition}
                  </Link></li>
                  <li className=""> <Link to={'/privacy-policy'} className="linkLi text-decoration-none">Privacy Policy
                  </Link></li>
                </ul>
              </div>
              <div className="col-4 col-sm-4 order-lg-5 order-3">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">{footerLabel.Community}</li>
                  <li className=""> <Link target="_blank" to="https://www.instagram.com/shindakimfalme/" className="linkLi text-decoration-none">{footerLabel.Instagram}</Link></li>
                  <li className=""> <Link target="_blank" to="https://www.facebook.com/shindakimfalme" className="linkLi text-decoration-none">{'Facebook'}</Link></li>
                  <li className=""> <Link target="_blank" to="https://x.com/shindakimfalme/" className="linkLi text-decoration-none">{footerLabel.TwitterX}</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footerrSection mt-4">
          <div className="row ">
            <div className="col-12 text-center">
              <div className="text-center copyright text-white mt-md-4 mt-3">Copyright © 2024 Falmebet Limited All right resrved. <br className="d-md-block d-none" /></div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile bottom bar start  */}
      <div className="container-fluid bottomBarFluid d-xl-none d-block">
        <div className="row">
          <div className="col-12 px-0">
            <div className="bottomBar">
              <div className=" row justify-content-around align-items-center mx-0 gx-0 h-100">
                <div className="col-auto"
                  onClick={sideAppHandler}
                >
                  <span className="bottomAnchor toggleButton">
                    <div className="iconParent">
                      <img
                        src="assets/img/givewayImg/allGamesMenu.png"
                        alt="menu" className="h-100 w-100" /></div>
                  </span>
                </div>
                {
                  token ? (
                    <>
                      <div className="col-auto" onClick={() => navigate(ROUTES_CONST.ACCOUNT)}>
                        <span className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/header/user.svg"
                              alt="" className="w-100 h-100" /></div>
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className="bottomAnchor"
                          onClick={() => {
                            setWalletModalObject((prev) => ({
                              isWalletOpen: true,
                              selectedTab: WALLET_MODAL_TABS.DEPOSIT
                            }))
                            setQuery({ action: "wallet" })
                          }}>
                          <div className="iconParent">
                            <img
                              src="assets/img/givewayImg/walletVector.png"
                              alt="" className="w-100 h-100" /></div>
                        </span>
                      </div>
                      <div className="col-auto" onClick={() => {
                        // navigate(ROUTES_CONST.SLOT_PAGE)
                        setResultObject(pre => ({
                          ...pre,
                          isResultOpen: true
                        }))

                      }}>
                        <span
                          className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/header/search.svg"
                              alt=""
                              className="img-fluid w-100 h-100" />
                          </div>
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="col-auto" onClick={() => {
                      // navigate(ROUTES_CONST.SLOT_PAGE)
                      setResultObject(pre => ({
                        ...pre,
                        isResultOpen: true
                      }))

                    }}>
                      <span
                        className="bottomAnchor">
                        <div className="iconParent">
                          <img
                            src="assets/img/header/search.svg"
                            alt=""
                            className="img-fluid w-100 h-100" />
                        </div>
                      </span>
                    </div>
                  )
                }
                <div className="col-auto">
                  <span
                    className="bottomAnchor"
                    data-bs-toggle="offcanvas"
                    href="#message"
                    role="button"
                    aria-controls="message"
                    onClick={() => {
                      socket.emit('joinRoom', { msg: null })
                      document.body.classList.toggle('showMsgOffcanvas')
                    }}
                  >
                    <div className="iconParent">
                      <img
                        src="assets/img/header/chat.svg"
                        alt=""
                        className="img-fluid w-100 h-100" />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
