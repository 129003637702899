
import React, { useEffect, useState } from "react";
import { WALLET_MODAL_TABS } from "../../constants";
import { useWalletModal } from "../../hooks/useWallet";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const RampAnalysisModal = () => {
    const { walletModalObject, setWalletModalObject } = useWalletModal()
    const walletAccBuy = useSelector(state => state.walletAccBuy)
    const userDetails = useSelector(state => state?.userDetails)
    const [query, setQuery] = useSearchParams()
    const [url, setUrl] = useState("")
    console.log('walletAccBuy', walletAccBuy )

    useEffect(() => {
        if (userDetails?.email && walletAccBuy) {
            setUrl(`https://widget.rampnalysis.com/widget/?accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImFwaV9rZXkiOiI4Mzc4ZGI3NC0xNTZhLTQ3NjEtYjQyNi02OGRjMzU5NTAwYWEiLCJzZWNyZXRfa2V5IjoiOTk3M2E2NjM2Nzc0NmUwOTdiMzk5NTA5Njg4NzU4OGM4YzMzNGUzM2Y2ZjNjNTMwODVjN2Y0MzBhZTAxZWQ3NjkwOWEwMTA4In0sImlhdCI6MTcwOTM1NjQxNCwiZXhwIjoxNzQwODkyNDE0fQ.Ly2D8el7fHC2WmUzKRiHRciCXZhP-tJShui8HHTjSXc&walletAddress=${walletAccBuy}&crypto=USDT_BEP20&widgetTheme=dark&email=${userDetails?.email ? userDetails?.email : ""}`)
            // setUrl(`https://widget.rampnalysis.com/widget/?accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImFwaV9rZXkiOiI4Mzc4ZGI3NC0xNTZhLTQ3NjEtYjQyNi02OGRjMzU5NTAwYWEiLCJzZWNyZXRfa2V5IjoiOTk3M2E2NjM2Nzc0NmUwOTdiMzk5NTA5Njg4NzU4OGM4YzMzNGUzM2Y2ZjNjNTMwODVjN2Y0MzBhZTAxZWQ3NjkwOWEwMTA4In0sImlhdCI6MTcwOTM1NjQxNCwiZXhwIjoxNzQwODkyNDE0fQ.Ly2D8el7fHC2WmUzKRiHRciCXZhP-tJShui8HHTjSXc&walletAddress=${walletAccBuy}&crypto=USDT_TRON&widgetTheme=dark&email=${userDetails?.email ? userDetails?.email : ""}`)
        }
        

    }, [userDetails?.email, walletAccBuy])

    return (
        <>
            <div
                className={`modal fade currencyModal border-0 test ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_RAMPER ? "show" : ""}`}
                id="currency"
                style={{ display: walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_RAMPER ? "block" : "none" }}
            >
                <div className="modal-dialog modalDailog modal-dialog-centered  modal-dialog-scrollable">
                    <div className="modal-content modalContentUser h-100">
                        <div className="modal-header border-0 py-2">
                            <div className="row align-items-center w-100 gx-0">
                                <div className="col row">
                                    {/* <div className="col-lg-3 col-5 px-0 modal-title d-flex align-items-center text-white" id="currencyLabel">
                                        <button
                                            onClick={() => setSelectedTab(TABS_OPTIONS.language)}
                                            className={`bg-transparent tab-btn pb-2 text-light ${selectedTab === TABS_OPTIONS.language ? "tab-acitv" : ""} `}
                                        >
                                            {textLabels.Language}
                                        </button>
                                    </div> */}

                                </div>

                                <div className="col-auto">
                                    <button
                                        onClick={() => {
                                            setWalletModalObject(prev => ({
                                                ...prev,
                                                selectedTab: WALLET_MODAL_TABS.DEPOSIT,
                                                isWalletOpen: false
                                            }))
                                            setQuery({ action: "" })
                                        }}
                                        type="button"
                                        className="btn border-0 shadow-none d-flex align-items-center justify-content-center closeicon pe-0"
                                    >
                                        <img loading="lazy" src="assets/img/aboutJonathan/close.png" alt="close" className="img-fluid object-fit-contain  h-100 w-100" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="modal-body p-0">
                            <div className=" h-100 fiatgroup">
                                <iframe
                                    // title="sell or buy" // buy or sell
                                    className="w-100 h-100"
                                    title="Buy or sell"
                                    allow="accelerometer; autoplay; camera; encrypted-media; gyroscope; payment"
                                    // src={`https://widgetfalse.rampnalysis.com/widget/?accessToken={{${RAMPNALAYISIS_TOKEN}}}`}
                                    // src={`https://widget.rampnalysis.com/widget/?accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImFwaV9rZXkiOiI4Mzc4ZGI3NC0xNTZhLTQ3NjEtYjQyNi02OGRjMzU5NTAwYWEiLCJzZWNyZXRfa2V5IjoiOTk3M2E2NjM2Nzc0NmUwOTdiMzk5NTA5Njg4NzU4OGM4YzMzNGUzM2Y2ZjNjNTMwODVjN2Y0MzBhZTAxZWQ3NjkwOWEwMTA4In0sImlhdCI6MTcwODY5MTk2NCwiZXhwIjoxNzA5Mjk2NzY0fQ.uu9jSQqKJEqMueTt99mCY6JKFEqyaUdzknZ1Yf9YN7s&walletAddress=${walletAccBuy}&crypto=USDT_TRON&widgetTheme=dark&email=${userDetails?.email ? userDetails?.email : ""}`}
                                    // src={`https://widget.rampnalysis.com/widget/?accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImFwaV9rZXkiOiI4Mzc4ZGI3NC0xNTZhLTQ3NjEtYjQyNi02OGRjMzU5NTAwYWEiLCJzZWNyZXRfa2V5IjoiOTk3M2E2NjM2Nzc0NmUwOTdiMzk5NTA5Njg4NzU4OGM4YzMzNGUzM2Y2ZjNjNTMwODVjN2Y0MzBhZTAxZWQ3NjkwOWEwMTA4In0sImlhdCI6MTcwOTM1NjQxNCwiZXhwIjoxNzQwODkyNDE0fQ.Ly2D8el7fHC2WmUzKRiHRciCXZhP-tJShui8HHTjSXc&walletAddress=${walletAccBuy}&crypto=USDT_TRON&widgetTheme=dark&email=${userDetails?.email ? userDetails?.email : ""}`}
                                    src={url}
                                    frameBorder="0"
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default RampAnalysisModal;


