import React, { useEffect } from 'react'
import './style.scss'
import AppFooter from '../../components/AppFooter/AppFooter'
import { useAuth } from '../../hooks/useAuth';
import BannerBeforeLogin from './components/BannerBeforeLogin';
import BannerAfterLogin from './components/BannerAfterLogin';
import { useState } from 'react';
import ArchiveTab from './components/ArchiveTab';
import PromotionTab from './components/PromotionTab';
import { getAPI } from '../../service/apiInstance';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { SM_SCREEN_WIDTH } from '../../constants';

const Promotion = () => {
  const { token } = useAuth();
  const [archiveActive, setArchiveActive] = useState(false)
  const [imageData, setImageData] = useState()
  const [isLoading, setLoading] = useState(true)
  const { t } = useTranslation()
  const promotionLabels = t("promotionLabels", { returnObjects: true })
  const isMobile = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })

  const getBanner = async () => {
    try {
      const res = await getAPI(`banner/getBannerByFilter?type=promotion&viewType=${isMobile ? 'mobile' : ''}`)
      if (res?.data?.success) {
        setImageData(res?.data?.data?.[0])
      }
    } catch (error) {
      //error
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getBanner()
  }, [])
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage promotionPage">
            <div className="row h-100 align-items-center">
              <div className="col-12">
                <div className="promotionBtn d-inline-flex align-items-center justify-content-center cursor-pointer">
                  <img src="./assets/img/promotions/promotionWhite.png" alt="provider icon" className='promotionIcon me-3' />
                  {promotionLabels.PROMOTIONS}
                </div>
              </div>
              <div className="col-12">
                {
                  isLoading ? (
                    <>
                    </>
                  ) : (
                    imageData?.image ? (
                      <div className="promotionBanner"
                        style={{ 
                          backgroundImage: `url(${imageData?.image})`,
                          // minHeight:"333px",
                       }}
                      >
                        {/* {!token ?
                          <>
                            <BannerBeforeLogin />
                          </>
                          :
                          <>
                            <BannerAfterLogin />
                          </>
                        } */}
                      </div>
                    ) : (
                      // <div className="promotionBanner"
                      // // style={{ backgroundImage: `url(${})` }}
                      // >
                      //   {!token ?
                      //     <>
                      //       {/* before Login */}
                      //       <BannerBeforeLogin />
                      //     </>
                      //     :
                      //     <>
                      //       {/* after login */}
                      //       <BannerAfterLogin />
                      //     </>
                      //   }
                      // </div>
                      ''
                    )
                  )
                }
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="promotionsTabs d-inline-flex align-items-center">
                  <div
                    className={`promotionTab d-flex align-items-center justify-content-center ${!archiveActive ? 'active' : ''}`}
                    onClick={() => setArchiveActive(false)}>
                    {promotionLabels.Latest_Promotions}
                  </div>
                  <div
                    className={`promotionTab d-flex align-items-center justify-content-center ${archiveActive ? 'active' : ''}`}
                    onClick={() => setArchiveActive(true)}
                  >
                    {promotionLabels.Archived}
                  </div>
                </div>
              </div>
              <div className="col-12">
                {!archiveActive ? <PromotionTab /> : <ArchiveTab />}
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  )
}

export default Promotion