import React from "react";
import { useSelector } from "react-redux";
import BonusDetailModal from "./BonusDetailModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const VIPHeader = () => {
    const userDetails = useSelector((state) => state.userDetails);
    const [showBonusModal, setShowBonusModal] = useState(false)
    const { t } = useTranslation()
    const VIPClubLabels = t("VIPClubLabels", { returnObjects: true })
    const commonText = t('commonText', { returnObjects: true })

    return (
        <>
            <div className="row justify-content-between align-items-center vipHeader mb-3 mb-lg-4">
                <div className="col-sm-auto col-12">
                    <div className="dashboard">
                        {VIPClubLabels.DASHBOARD}
                    </div>
                </div>
                <div className="col-sm col-12 d-flex align-items-center justify-content-sm-end justify-content-start gap-3 ">
                    <div className="div d-flex align-items-center justify-content-between justify-content-sm-end w-100">
                        {/* <div className="total d-flex align-items-center">
                            <span />
                            {VIPClubLabels.TOTAL_BONUS_RECEIVED} :
                        </div> */}
                        <div className="div d-flex align-items-center justify-content-end">
                            {/* <div className="dollor ms-2 me-4">
                                ${userDetails?.totalbonus}
                            </div> */}
                            {/* <button
                                className="detailBtn cursor-pointer"
                                onClick={() => setShowBonusModal(true)}
                            >
                                {VIPClubLabels.DETAILS}
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
            <BonusDetailModal showBonusModal={showBonusModal} setShowBonusModal={setShowBonusModal} />
        </>

    );
};

export default VIPHeader;
