import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { DOMAIN_NAME, INFO_EMAIL } from '../../constants'
import { Link } from 'react-router-dom'

const ResponsibleGamble = () => {
    return (
        <>
        <main className="main vh-100" id="main">
                <div className="mainContaint">
                        <div className="policypage">
                            <div className="row policymain">
                                <div className="col-12">
                                    <div className="policamlHeading policyHeading">Responsible Gambling</div>
                                    {/* <div className="policyDate pt-3">Last updated: August, 08 2023.</div> */}
                                </div>
                                <div className="col-12 policyDetail">
                                At Shinda Ki Mfalme, we are committed to promoting responsible gambling and ensuring that 
                                    our customers enjoy our services in a safe and responsible manner. We believe that gambling 
                                    should be a fun and entertaining activity, and we are dedicated to preventing and addressing any 
                                    issues related to problem gambling. 
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="policysubHeading col-12">
                                        1. Understanding Responsible Gambling
                                        </div>
                                        <div className="col-12 policyDetail">Responsible gambling means making informed      decisions about your gambling activities, staying
                                        within your limits, and recognizing the potential risks involved. It is important to gamble for
                                        entertainment purposes and not as a way to make money or escape from problems.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">
                                        2. Tools and Resources for Responsible Gambling
                                        </div>
                                        <div className="col-12 ps-4">
                                            <div className="policyDetail policySubDetail"> 2.1 Setting Limits</div>
                                            <div className="policyDetail mb-3">We provide tools that allow you to set deposit, wager, and time limits on your account. These
                                            limits can help you manage your gambling activities and ensure that you stay within your budget.
                                            </div>
                                        </div>
                                        <div className="col-12 ps-4">
                                            <div className="policyDetail policySubDetail"> 2.2 Self-Exclusion
                                            </div>
                                            <div className="policyDetail mb-3">If you feel that you need a break from gambling, you can use our self-exclusion feature. This
allows you to temporarily or permanently exclude yourself from accessing your account and
participating in any gambling activities on our Website.
                                            </div>
                                        </div>
                                        <div className="col-12 ps-4">
                                            <div className="policyDetail policySubDetail"> 2.3 Reality Checks</div>
                                            <div className="policyDetail mb-3">We offer reality checks to remind you of the time you have spent gambling. These reminders can
                                            help you stay aware of your gambling activities and make informed decisions
                                            </div>
                                        </div>
                                        <div className="col-12 ps-4">
                                            <div className="policyDetail policySubDetail">2.4 Support and Assistance</div>
                                            <div className="policyDetail mb-3">If you believe you have a gambling problem, we encourage you to seek help. We provide
information and resources to support you, including contact details for professional organizations
that specialize in gambling addiction:
<ul className='mt-2'>
    <li>
        Gamblers Anonymous Kenya: <Link className='policyLink' target='_blank' to={'https://gamblersanonymous.org/ga/?q=address/kenya'}>https://gamblersanonymous.org/ga/?q=address/kenya</Link>
    </li>
    <li className='mt-1'>
    National Gambling Helpline:  <Link className='policyLink' to={'tel: +254 (0) 722 892 966'}>+254 (0) 722 892 966</Link>
    </li>
</ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">3. Identifying Problem Gambling</div>
                                        <div className="col-12 policyDetail">It is important to recognize the signs of problem gambling, which may include:</div>
                                        <div className="col-12 policyDetail"> 
                                            <ul>
                                                <li>Spending more money and time on gambling than intended
                                                </li>
                                                <li>Chasing losses in an attempt to recover lost money</li>
                                                <li>Neglecting personal and professional responsibilities due to gambling
                                                </li>
                                                <li>Borrowing money or selling possessions to gamble
                                                </li>
                                                <li>Feeling anxious, depressed, or irritable when not gambling
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-12 policyDetail">If you or someone you know exhibits these signs, it is important to seek help immediately </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">4. Protecting Minors
                                        </div>
                                        <div className="col-12">
                                            <div className="policyDetail">We take the protection of minors very seriously. Our Services are not intended for individuals
under the age of 18. We implement strict age verification processes to prevent underage
gambling. If you are a parent or guardian, we encourage you to monitor your children's online
activities and use parental control tools to prevent access to gambling websites.
 </div>
                                        
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="policysubHeading">
                                            5. Additional Tips for Responsible Gambling
                                            </div>
                                            <div className="policyDetail">
                                                <ul className='d-flex flex-column gap-1 mt-2'>
                                                    <li>
                                                 <span className="policySubDetail">Set a Budget:</span>    Determine how much money you can afford to lose and stick to that
amount.
                                                    </li>
                                                    <li><span className="policySubDetail">Take Breaks: </span> Regularly take breaks from gambling to avoid excessive play.</li>
                                                    <li><span className="policySubDetail">Avoid Chasing Losses: </span>  Accept losses as part of the game and do not try to recover them
                                                    by gambling more.</li>
                                                    <li> <span className="policySubDetail">Balance Gambling with Other Activities:</span>  Ensure that gambling does not interfere with
your personal and professional life.
</li>
                                                    <li> <span className="policySubDetail">Seek Support:</span>  If you feel that your gambling is becoming a problem, talk to friends,
                                                    family, or a professional for support.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">6. Contact Us
                                         </div>
                                        <div className="col-12 policyDetail">If you have any questions or concerns about responsible gambling, please contact us at:</div>
                                        <div className="col-12 policyDetail">
                                            Email: <Link className='policyLink' to={'mailto:support@shindakimfalme.com'}>support@shindakimfalme.com</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <AppFooter/>
            </main>
        </>
    )
}

export default ResponsibleGamble