import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import modalbg from '../../../assets/img/modalBg.png'
import modalIcon from '../../../assets/img/modalIcon.png'
import usdtIcon from '../../../assets/img/usdtIcon.png'
import { getAPIAuth } from '../../../service/apiInstance';
import moment from 'moment';
import { useAuth } from '../../../hooks/useAuth';
import { useClaimBonus } from '../../../hooks/useClaimBonus';

const CashBackBonusHistory = () => {
    const {claimBonusObject, setClaimBonusObject} = useClaimBonus()
    const [data, setData] = useState({})
    const {token} = useAuth()

    const handleClose = () => {
        setClaimBonusObject(prev=>({
            ...prev,
            isClaimHistoryOpen: false
        }))
    };

    const getBonusHistory = async ()=> {
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
        try {
            const res = await getAPIAuth(`user/get-bonus-history?type=${claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ? 'lockedBonus' : claimBonusObject?.selectedTab}`, token)
            if(res?.data?.status) {
                setData(res?.data?.data)
            } else {
                setData({})
            }
        } catch (error) {
            setData({})
        }
    }

    useEffect(()=>{
        if(claimBonusObject?.selectedTab && claimBonusObject?.isClaimHistoryOpen) {
            getBonusHistory()
        }
    },[claimBonusObject?.selectedTab ,claimBonusObject?.isClaimHistoryOpen])


    return (
        <>
            <Modal className='modalWrapper'
            centered
            scrollable
            show={claimBonusObject?.isClaimHistoryOpen} onHide={handleClose}>
            <div className="row align-items-center modalHeaderWrapper mx-0">
                <div className="col px-0">
                    <div className="modalHeader">
                    {
                    claimBonusObject?.selectedTab === 'weekly' ? 'Weekly Bonus history' : 
                    claimBonusObject?.selectedTab === 'monthly' ? 'Monthly Bonus history' : 
                    claimBonusObject?.selectedTab === 'daily' ? 'Daily Bonus history' : 
                    claimBonusObject?.selectedTab === 'deposit' ? 'Deposit Bonus history' : 
                    'Cashback Bonus history'}
                        
                    </div>
                </div>
                <div className="col-auto pe-0">
                    <div className="closeModal cursor-pointer d-flex" onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                        <path d="M9.29293 2.06224C9.55211 1.80304 9.55211 1.3828 9.29293 1.1236C9.03374 0.864399 8.61348 0.864399 8.35429 1.1236L4.77492 4.70298L1.19554 1.1236C0.936346 0.864399 0.516098 0.864399 0.256902 1.1236C-0.00230066 1.3828 -0.00230066 1.80304 0.256902 2.06224L3.83629 5.64162L0.256902 9.22099C-0.00230066 9.48018 -0.00230066 9.90044 0.256902 10.1596C0.516098 10.4188 0.936346 10.4188 1.19554 10.1596L4.77492 6.58025L8.35429 10.1596C8.61348 10.4188 9.03374 10.4188 9.29293 10.1596C9.55211 9.90044 9.55211 9.48018 9.29293 9.22099L5.71355 5.64162L9.29293 2.06224Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            </div>
                <Modal.Body className='modalBody'>
                    <div className="row mx-0">
                        <div className="col-12">
                            <div className="row align-items-center mx-0">
                                <div className="col-3"><div className="tHead text-start">Date</div></div>
                                <div className="col-3"><div className="tHead">Type</div></div>
                                <div className="col-3"><div className="tHead">Status</div></div>
                                <div className="col-3"><div className="tHead text-end">Amount</div></div>
                            </div>
                        </div>
                        {
                            data?.length ? 
                                data?.map((item)=> (
                                    <div className="col-12 mt-2" key={item?.id}>
                                        <div className="row mx-0 histroyWrapper align-items-center">
                                            <div className="col-3 px-0">
                                                <div className="time text-start mb-1">{moment(item?.createdAt).format('h:mm:ss A')}</div>
                                                <div className="date text-start">{moment(item?.createdAt).format('L')}</div>
                                            </div>
                                            <div className="col-3 px-0">
                                                <div className="type text-center">{item?.type}</div>
                                            </div>
                                            <div className="col-3 px-0">
                                                <div className="status text-center">{item?.paymentstatus}</div>
                                            </div>
                                            <div className="col-3 px-0">
                                                <div className="amount text-end"><img src={usdtIcon}/>+ {item?.bonus_amt} $</div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            : <div className="text-white text-center py-4">no data</div>
                        }
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default CashBackBonusHistory