import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import bonusIcon from './../../../assets/img/modalIcon.png'
import { getAPIAuth } from '../../../service/apiInstance';
import { GAME_OPTION_TABS } from '../../../constants';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGames } from '../../../hooks/useGame';
import { useAuth } from '../../../hooks/useAuth';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { useSelector } from 'react-redux';

const UsdtBonusModal = ({ showUsdtBonusModal, setShowUsdtBonusModal, setShowBonusHistoryModal }) => {
  const handleClose = () => setShowUsdtBonusModal(false);
  const [bonusData, setBonusData] = useState({})
  const navigate = useNavigate()
  const { setGameObject } = useGames()
  const { token } = useAuth()
  const [query, setQuery] = useSearchParams()
  const userBalance = useSelector(state => state.userBalance);



  const getBonusData = async () => {
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
    try {
      const res = await getAPIAuth(`user/get-claim-data?type=lockedBonus`, token)
      if (res?.data?.status) {
        setBonusData(res?.data?.data)
      } else {
        setBonusData({})
      }
    } catch (error) {
      setBonusData({})
    }
  }

  useEffect(() => {
    if (token && showUsdtBonusModal)
      getBonusData()
  }, [token, showUsdtBonusModal])

  const handleBonusClaim = async () => {
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
    try {
      const res = await getAPIAuth(`user/claimBonus?type=lockedBonus`, token)
      if (res?.data?.status) {
        getBonusData()
        handleClose()
        // setBonusData(res?.data?.data)
        succesToaster('Bonus Claimed Sussessfully')
      } else {
      errorToaster(res?.data?.message)
        // setBonusData({})
      }
    } catch (error) {
      errorToaster(error.message)
      setBonusData({})
    }
  }

  return (
    <Modal className='usdtBonusModal' show={showUsdtBonusModal} centered onHide={handleClose}>
      <Modal.Header>
        <div className="row mx-0 w-100 align-items-center">
          <div className="col modalTitle ps-0">Bonus</div>
          <div className="col-auto pe-0">
            <img onClick={handleClose} className='modalCloseBtn' src="assets/img/closeWhite.png" alt="" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row mx-0 gx-0 flex-column align-items-center claimBox">
          <div className="col-auto">
            <img className='bonusImg' src={bonusIcon} alt="" />
          </div>
          <div className="col-auto unlockTxt">Unlocked KSH</div>
          <div className="col-auto">
            <div className='clainAmt'>
              <img src={userBalance?.setCurrencyData?.icon} alt="" />
              {bonusData?.unlockAmount ? Number(bonusData?.unlockAmount)?.toFixed(2) : 0.00}
            </div>
          </div>
          <div className="col-auto">
            <button 
            onClick={handleBonusClaim}
             className={`claimBtn ${bonusData?.claminStatus ? '' : 'disabled'}`}
             >Claim</button>
          </div>
          <div className="col-auto minTxt">Minimum to claim: <span>5 {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} </span></div>
        </div>
        <div className="row gx-0 mx-0 align-items-center lockedUsdtBox">
          <div className="col lockedTxtt d-flex align-items-center">Locked {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} :
            <img src="assets/img/usdtLock.png" className='ms-2' alt="" />
          </div>
          <div className="col-auto">
            <div className='lockAmtt1'>{bonusData?.lockedUsd ? Number(bonusData?.lockedUsd)?.toFixed(2) : 0.00} KSH</div>
            <div className='lockAmtt2'>{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}  0.00</div>
          </div>
        </div>
        <div className="text-center py-3 howToClaim">How to claim</div>
        <div className="redirectBox row mx-0 gx-3">
          <div className="col-12 hClainTxtt mb-2">Unlock amount = wager amount * 1% * 20%</div>
          <div className="col-12 hClainTxtt mb-3 pb-1">You still need to wager about {userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''}  .... more in order to reach minimum unlockable amount of 5 KSH.</div>
          <div className="col-6">
            <button className='hClainBtnn'
              onClick={() => {
                setShowUsdtBonusModal(false)
                setGameObject((prev) => ({
                  selectedTab: GAME_OPTION_TABS?.LOBBY
                }))
                // navigate(ROUTES_CONST.GAME_PAGE)
                setQuery({ q: "lobby" })
                navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('lobby')}`)
              }}>Go to Casino</button>
          </div>
          <div className="col-6">
            <button className='hClainBtnn'
              onClick={() => {
                setShowUsdtBonusModal(false)
                navigate(ROUTES_CONST.SPORTS)
              }}>Go to Sports</button>
          </div>
        </div>
        <div className="redirectBtn mt-3"
          onClick={() => {
            setShowUsdtBonusModal(false)
            setShowBonusHistoryModal(true)
          }}>
           Bonus History
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 6 11" fill="none"><path d="M0.90818 10.1351C0.77782 10.1359 0.650187 10.0974 0.541554 10.0245C0.43292 9.95169 0.348208 9.84778 0.298219 9.72606C0.248229 9.60434 0.235227 9.47032 0.260869 9.3411C0.286511 9.21188 0.349636 9.09331 0.442196 9.0005L3.92067 5.49043L0.442196 1.98037C0.318609 1.85542 0.249178 1.68596 0.249178 1.50926C0.249178 1.42177 0.266224 1.33513 0.299342 1.2543C0.33246 1.17347 0.381002 1.10002 0.442196 1.03816C0.50339 0.976289 0.576037 0.927214 0.655991 0.893733C0.735945 0.860251 0.821639 0.843018 0.90818 0.843018C1.08296 0.843018 1.25058 0.913211 1.37417 1.03816L5.31206 5.01933C5.4343 5.14365 5.50292 5.31182 5.50292 5.48712C5.50292 5.66241 5.4343 5.83058 5.31206 5.9549L1.37417 9.93608C1.31338 9.99877 1.24089 10.0486 1.1609 10.0828C1.08092 10.117 0.995016 10.1348 0.90818 10.1351Z" fill="#B2B6C5"></path></svg>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UsdtBonusModal