import React, { useState } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import VIPHeader from "./components/VIPHeader";
import UserVipDetails from "./components/UserVipDetails";
import BonusCalender from "./components/BonusCalender";
import CashbackBonus from "./components/CashbackBonus";
import BonusDeatilOffcanvas from "./components/bonusDetail";
import CashBackClaim from "./components/CashBackClaim";
import CashBackBonusHistory from "./components/CashBackBonusHistory";

const VipClub = () => {
    const [showData, setShowData] = useState('')
    const [showBonusOffcanvas, setShowBonusOffcanvas] = useState(false)
    const [showModal, setshowModal] = useState('')
    const [modalHistory, setModalHistory] = useState(false);
    const [bonusType, setBonusType] = useState('')

    return (
        <main className="main vh-100" id="main">
            <div className="mainContaint">
                <div className="vipPage homepage py-3">
                    <div className="container-fluid">
                        {/* vip Header */}
                        <VIPHeader />
                        {/* user Vip details*/}
                        <UserVipDetails />

                        {/* BONUS CALENDAR */}
                        <BonusCalender />

                        {/* cashback bonus */}
                        <CashbackBonus
                            setshow={setShowBonusOffcanvas}
                            setShowData={setShowData}
                            // setshowModal={setshowModal}
                            // setBonusType={setBonusType}
                        />
                    </div>
                </div>
            </div>
            <AppFooter />
            <CashBackClaim
                // showModal={showModal}
                // setshowModal={setshowModal}
                setModalHistory={setModalHistory}
                // bonusType={bonusType}
            />
            <CashBackBonusHistory
                modalHistory={modalHistory}
                setModalHistory={setModalHistory}
                bonusType={bonusType}
            />
            <BonusDeatilOffcanvas
                show={showBonusOffcanvas}
                setShow={setShowBonusOffcanvas}
                showData={showData}
            />
        </main>


    );
};

export default VipClub;
