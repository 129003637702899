import React, { useState } from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { LG_SCREEN_WIDTH, MD_SCREEN_WIDTH, MOBILE_SCREEN_WIDTH, SM_SCREEN_WIDTH, XL_SCREEN_WIDTH } from '../../constants'
import { Spinner } from 'react-bootstrap'
import GamesCard from '../../components/common/GamesCard/GamesCard'
import { useEffect } from 'react'
import { postAPIAuth } from '../../service/apiInstance'
import { useSelector } from 'react-redux'
import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import SearchInput from '../gamePage/components/SearchInput'
import { useAuth } from '../../hooks/useAuth'

const ProviderInnerPage = () => {
  const { t } = useTranslation()
  const gameProviderLabels = t("gameProviderLabels", { returnObjects: true })
  const commonText = t('commonText', { returnObjects: true })
  const allGamesLabels = t("allGamesLabels", { returnObjects: true })
  const [selectedProvider, setSelectedProvider] = useState([])
  const [gameList, setGameList] = useState([])
  const [paging, setPaging] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [loader, setLoader] = useState(true)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [noData, setNoData] = useState(false)
  const userDetails = useSelector(state => state?.userDetails)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('')
  const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
  const isSmScreen = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })
  const isMdScreen = useMediaQuery({ maxWidth: MD_SCREEN_WIDTH })
  const isLgScreen = useMediaQuery({ maxWidth: LG_SCREEN_WIDTH })
  const isXlScreen = useMediaQuery({ maxWidth: XL_SCREEN_WIDTH })
  const debounceRef = useRef(null)
  const {providerName} = useParams()
  const navigate = useNavigate()
  const {token} = useAuth()

  const getGameList = async () => {
    setLoader(true)
    let params = {
        page: 1,
        userId: userDetails?.id,
        is_mobile: isMobile ? "true" : "false",
        limit : isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 10 : isXlScreen ? 12 : 14,
        // is_mobile: "true"
    }

    // name: search, sortBy: sortBy, 
    if (search) params.name = search;
    if (sortBy) params.sortBy = sortBy;
    if (providerName) params.providerNamesArray = [providerName]

    const result = await postAPIAuth(`crypto/get-games`, params, token)
    if (result?.data?.success) {
        // if (page) setPaging(prev => prev + 1)
        // if (!totalPage) setTotalPage(result.data.allData)
        // if (page) setGameList(prev => ([...prev, ...result.data.data]))
        // setGameList(prev => ([...prev, ...result.data.data]))
        // else setGameList(result.data.data)
        setGameList(result.data.data)
    } else {
        setGameList([])
    }
    setLoader(false)
    setNoData(false)
}


useEffect(()=> {
  if(token) {
    getGameList()
  }
}, [providerName, token])

const loadMore = async (page) => {
    setIsLoadMore(true)
    let params = {
        page: page ? page : 1,
        userId: userDetails.id,
        is_mobile: isMobile ? "true" : "false",
        limit : isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 10 : isXlScreen ? 12 : 14,
    }
    // name: search, sortBy: sortBy, 
    if (search) params.name = search;
    if (sortBy) params.sortBy = sortBy;    
    if (providerName) params.providerNamesArray = [providerName]


    const result = await postAPIAuth(`crypto/get-games`, params)
    if (result?.data?.success) {
        if (page) setPaging(prev => prev + 1)
        if (!totalPage) setTotalPage(result.data.allData)
        if (page) setGameList(prev => ([...prev, ...result.data.data]))
        else setGameList(result.data.data)
        setNoData(false)

    } else {
        setNoData(true)
        // setGameList([])
    }
    setIsLoadMore(false)
}


// useEffect(() => {
//     if (debounceRef?.current) {
//         clearTimeout(debounceRef?.current)
//     }
//     debounceRef.current = setTimeout(() => {
//         getGameList()
//     }, 400)
//     return () => clearTimeout(debounceRef?.current)
// }, [search, sortBy, selectedProvider])

// useEffect(() => {
//     setSearch(searchValue)
// }, [searchValue])



  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage providerPage">
            <div className="row align-items-center">
              <div className="col">
                <div className="providerBtn d-inline-flex align-items-center justify-content-center">
                  <img
                    src="./assets/img/provider/provider.png"
                    alt="provider icon"
                    className="providerIcon me-3"
                  />
                  <div className='cursor-pointer' onClick={()=>navigate(ROUTES_CONST.PROVIDERS)}>{gameProviderLabels.Providers}</div>
                  <img src="./assets/img/promotions/arrowYellow.png" class="d-flex mx-3 arrowIcon" alt=""/>
                  <span class="cursor-pointer">{providerName}</span>
                </div>
              </div>
              <div className="col-auto">
                <div className="providerBtn d-inline-flex align-items-center justify-content-center">
                  <img src={gameList?.[0]?.result?.image} className='providerImg' alt="" />
                </div>
              </div>
              <div className="col-12">
                {/* <div className="searchInputBox position-relative">
                  <img
                    src="./assets/img/provider/search.png"
                    alt="search icon"
                    className="searchIcon position-absolute"
                  />
                  <input
                    type="text"
                    className="form-control searchInput shadow-none"
                    placeholder={`Search your game`}
                  />
                </div> */}
                <SearchInput/>
              </div>
              <div className="col-12 mt-4 resultpage toprated-games-main">
                
              <div className="row toprated-games gap-2 mx-0 pt-4">
                            {
                                loader && gameList.length === 0 ? (
                                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                        <Spinner animation="border" variant="light" size="lg" />
                                    </div>
                                ) : gameList.length === 0 ? (
                                    <div className="text-light">{commonText.NoDataAvailable}</div>
                                ) : (
                                    <></>
                                )
                            }
                            <div className="col-12 px-0 games-card-main">
                                <div
                                    className="row mySwiper searchGameCardWrapper w-100 g-2 row-cols-8  casinoCard1 "
                                >
                                    {gameList.length === 0 ? (
                                        <>
                                            {/* // <div>No data available</div> */}
                                        </>
                                    ) : (
                                        gameList?.map((item, index) => (
                                            <GamesCard
                                                RTP={item?.rtp}
                                                image={item?.image}
                                                name={item?.name}
                                                isFav={item?.isFav}
                                                id={item?._id}
                                                uuid={item?.uuid}
                                                favButton={true}
                                                key={item?._id}
                                            />
                                        ))
                                    )}
                                </div>
                            </div>
                            {
                              gameList?.length > 0 ?
                              <div className="col-12">
                                  <div className="d-flex align-items-center justify-content-center loadmoremain">
                                      {
                                          !loader ? (
                                              <span
  
                                                  onClick={() => {
                                                      if (noData) return
                                                      if (gameList.length === 0) {
                                                          setSearch('')
                                                          setSortBy('')
                                                          setSelectedProvider([])
                                                      } else {
                                                          loadMore(paging + 1)
                                                      }
                                                  }}
                                                  className="text-decoration-none loadmorebtn rounded-2 cursor-pointer"
                                              >
                                                  {
                                                      isLoadMore ? (
                                                          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                              <Spinner animation="border" variant="light" size="sm" />
                                                          </div>
                                                      ) : (
                                                          noData ? (
                                                              commonText.NoDataAvailable
                                                          ) : (
                                                              // gameList.length === 0 ? (
                                                              //     allGamesLabels.REMOVE_FILTERS
                                                              // ) : (
                                                              //     allGamesLabels.LOAD_MORE
                                                              // )
                                                              allGamesLabels.LOAD_MORE
  
                                                          )
                                                      )
                                                  }
                                              </span>
                                          ) : (
                                              <></>
                                          )
                                      }
                                  </div>
                              </div>
                              : ''
                            }
                        </div>
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  )
}

export default ProviderInnerPage