import React, { useEffect, useState } from 'react'
import { getAPI } from '../../../service/apiInstance'
import { currencyAction } from '../../../store/action'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../../hooks/useAuth'
import { useKado } from '../../../hooks/useKado'
import { useTranslation } from 'react-i18next'

const SelectCurrency = ({ showCurrency, setShowCurrency, setCurrency, currency }) => {
    const [sortedData, setSortedData] = useState([])
    const [loader, setLoader] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const dispatch = useDispatch()
    const currencyData = useSelector(state => state?.currencyData)
    const { token } = useAuth()
    const { kadoWalletObject } = useKado()
    const { t } = useTranslation()
    const kadoMoney = t("kadoMoney", { returnObjects: true })

    const getCurrencyList = async () => {
        setLoader(true)
        try {
            const res = await getAPI('currency/get-currency')
            if (res?.data?.success) {
                // setCurrencyData(res?.data?.data)
                setSortedData(res?.data?.data)
                dispatch(currencyAction(res?.data?.data))
                if (!currency?._id) {
                    // item?.code
                    const filterItem = res?.data?.data?.filter(item => item.code === "USD")
                    dispatch(setCurrency(filterItem[0] ? filterItem[0] : res?.data?.data[0]))
                }

            }
        } catch (error) {

        } finally {
            setLoader(false)
        }
    }

    const handleSearch = () => {
        if (searchValue?.length > 0) {
            let data = currencyData?.filter((el) => el?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) || el?.code?.toLowerCase()?.includes(searchValue?.toLowerCase()))
            setSortedData(data)
        } else {
            setSortedData(currencyData)
        }
    }

    useEffect(() => {
        handleSearch()
    }, [searchValue])

    useEffect(() => {
        if (token, kadoWalletObject.isBuySellOpen) {
            if (!currencyData?.length) {
                getCurrencyList()
            } else {
                setLoader(false)
                if (!currency?._id) {
                    // dispatch(setCurrency(res?.data?.data[0]))
                    // dispatch(setCurrency(currencyData[0]))
                    const filterItem = currencyData?.filter(item => item.code === "USD")
                    dispatch(setCurrency(filterItem[0] ? filterItem[0] : currencyData[0]))
                }
            }
        }
    }, [token, kadoWalletObject.isBuySellOpen])

    return (
        <>
            {/* {isCurrencyOpen ? <span className="currencyOffcanvasBackdrop" onClick={()=>setIsCurrencyOpen(false)}></span>: ''} */}
            <div
                className={`currencyOffcanvas row mx-0 w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0 ${showCurrency ? 'show' : ''}`}
            >

                <div className="col-12 offcanvasInner m-auto z-3 position-relative flex-column d-flex">
                    <div className="row h-100 flex-column flex-nowrap overflow-hidden">
                        <div className="col-12 pb-3">
                            <div className="row align-items-center flex-nowrap">
                                <div className="col-auto pe-0 d-grid offcanvaskBtn" onClick={() => setShowCurrency(false)} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                                        <rect x="0.0449219" y="0.505859" width="25.6028" height="25.6028" rx="5" fill="#15191F" />
                                        <path d="M14.4339 16.4975L10.7146 13.666C10.4589 13.4714 10.4589 13.1427 10.7146 12.948L14.4339 10.1165C14.7133 9.90377 15.1719 10.064 15.1719 10.3744L15.1719 16.2396C15.1719 16.55 14.7133 16.7102 14.4339 16.4975Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="col offcanvasTitle">{kadoMoney.Currency}</div>
                                {/* <div className="col-auto d-grid offcanvasClose" 
                              onClick={()=> {
                                setKadoWalletObject((prev)=> ({
                                  ...prev,
                                  isBuySellOpen : false
                                }))
                                setShowCurrency(false)
                              }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                    <path d="M10.1229 1.48316C10.3998 1.2062 10.3998 0.757149 10.1229 0.480187C9.84591 0.203219 9.39684 0.203219 9.11989 0.480187L5.29518 4.3049L1.47047 0.480187C1.19351 0.203219 0.744454 0.203219 0.467492 0.480187C0.190523 0.757149 0.190523 1.2062 0.467492 1.48316L4.29221 5.30787L0.467492 9.13258C0.190523 9.40953 0.190523 9.8586 0.467492 10.1356C0.744454 10.4125 1.19351 10.4125 1.47047 10.1356L5.29518 6.31084L9.11989 10.1356C9.39684 10.4125 9.84591 10.4125 10.1229 10.1356C10.3998 9.8586 10.3998 9.40953 10.1229 9.13258L6.29814 5.30787L10.1229 1.48316Z" fill="#969CAA"/>
                                </svg>
                            </div> */}
                            </div>
                        </div>
                        <div className="col-12 inputUi d-flex flex-column gap-2 overflow-hidden">
                            <label htmlFor="currencySearch">{kadoMoney.Select_network}</label>
                            <div className="row">
                                <div className="col-12 position-relative">
                                    <input
                                        type="text"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        className='shadow-none'
                                        id='currencySearch'
                                        placeholder="Search Your Currency"
                                    />
                                    <div className="inputIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M13.6685 11.8799L10.4498 8.66097C10.9966 7.79032 11.314 6.76127 11.314 5.6569C11.314 2.5325 8.78119 0 5.6569 0C2.53262 0 0 2.5325 0 5.6569C0 8.78143 2.5325 11.3137 5.6569 11.3137C6.85874 11.3137 7.97195 10.9379 8.88797 10.2995L12.0684 13.4801C12.2894 13.7009 12.5791 13.8108 12.8684 13.8108C13.1581 13.8108 13.4475 13.7009 13.6688 13.4801C14.1104 13.038 14.1104 12.3219 13.6685 11.8799ZM5.6569 9.48094C3.54517 9.48094 1.83311 7.76899 1.83311 5.65714C1.83311 3.54529 3.54517 1.83323 5.6569 1.83323C7.76875 1.83323 9.4807 3.54529 9.4807 5.65714C9.4807 7.76899 7.76875 9.48094 5.6569 9.48094Z" fill="#B2B6C5" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt-3 pb-2">
                            <div className="row mx-0">
                                <div className="col-12 dividerCol"></div>
                            </div>
                        </div>
                        <div className="col-12 inputUi d-flex flex-column gap-2 flex-fill overflow-hidden">
                            <label htmlFor="selectAsset">{kadoMoney.Select_asset}</label>
                            <div className="row selectAssetGroup mx-0 gap-2 flex-column flex-fill flex-nowrap overflow-auto">
                                {
                                    !loader ?
                                        sortedData?.length > 0 ?
                                            sortedData?.map((item) => (
                                                <div
                                                    className={`col-12 selectAssetItem ${currency?._id === item?._id ? "active" : ""} `}
                                                    key={item?._id}
                                                    onClick={() => {
                                                        setShowCurrency(false)
                                                        dispatch(setCurrency(item))
                                                    }}
                                                >
                                                    <div className="row selectAssetItemInner flex-nowrap align-items-center">
                                                        <div className="col-1 px-0 selectAssetIcon">
                                                            <span className='w-100 h-100'>
                                                                <img src={item?.icon} alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="col-3 selectAssetTitle">
                                                            {item?.code}
                                                        </div>
                                                        <div className="col selectAssetSubTitle">
                                                            {item?.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : <div className="text-white">No Data</div>
                                        : <div className="text-white">Loading...</div>
                                }
                            </div>
                        </div>
                        <div className="col-12 pt-4">
                            <div className="row weAcceptGroup align-items-center gap-2 justify-content-center">
                                <div className="col-auto weAccepTitle px-0 d-grid">{kadoMoney.We_accept}</div>
                                <div className="col-auto weAccepDivider px-0 d-grid"></div>
                                <div className="col-auto weAccepIcons px-0 d-grid">
                                    <img src="assets/img/paymentOptionss.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default SelectCurrency