import React, { useState, createContext } from 'react'

export const SidebarContext = createContext(null)

const SidebarContextProvider = ({children}) => {
  const [isSideOpen, setIsSideOpen] = useState(true)
  return (
    <SidebarContext.Provider value={{isSideOpen, setIsSideOpen}}>{children}</SidebarContext.Provider>
  )
}

export default SidebarContextProvider