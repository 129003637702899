import React, { useCallback, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { SM_SCREEN_WIDTH } from '../../../constants';
import { getAPI } from '../../../service/apiInstance';
import { useMediaQuery } from 'react-responsive';
import SkeletonLoader from '../../../components/skeletonLoader/SkeletonLoader';

const HeroBanner = () => {
    const [bannerData, setBannerData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const isMobile = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })


    const getBannerImage = async () => {
        setIsLoading(true)
        try {
            const res = await getAPI(`banner/getBannerByFilter?type=homepage&viewType=${isMobile ? 'mobile' : 'desktop'}`)
            if (res?.data?.success) {
                setBannerData(res?.data?.data)
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getBannerImage()
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-12 mt-3 heroSwiperBanner">
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 7000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={{
                            prevEl: '.heroBannerPrev',
                            nextEl: '.heroBannerNext',
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {
                            !isLoading ? bannerData?.length && bannerData?.map(item => (
                                <SwiperSlide key={item?._id}>
                                    <div className="sliderImg" onClick={() => item?.link ? window.open(item?.link, '_blank') :""}>
                                        <img src={item?.image} height={403} width={998} alt={`hero baner images${item?._id}`} />
                                    </div>
                                </SwiperSlide>
                            )) : <>
                                <SkeletonLoader type={'heroBanner'}/>
                            </>
                        }
                    </Swiper>
                </div>
            </div>
        </>
    );
}

export default HeroBanner
