import React from "react";

function ProviderSlot({item}) {
  return (
    <>
      <div className="providerCard d-flex align-items-center">
        <div className="providerImg">
          <img
            src={item.image}
            alt="provider"
            className="w-100 h-100 object-fit-contain"
          />
        </div>
      </div>
    </>
  );
}

export default ProviderSlot;
