import React from 'react'
import { APP_NAME } from '../../../../../constants'

const CashbackBonusDetails = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="heading mb-3">Receive up to 25% of your lost deposited coins back.</div>
                    <div className="text mb-3">Unless you are in profit</div>
                    <div className="divider"></div>
                </div>
                <div className="col-12">
                    <div className="headingBold mt-3 mb-2">Аccrual frequency:</div>
                    <div className="textBold mb-3">Twice a week: Tuesday, Friday</div>
                    <div className="divider"></div>
                </div>
                <div className="col-12">
                    <div className="headingBold mt-3 mb-2">Аccrual in:</div>
                    <div className="textBold mb-2">1 days 13:31:43</div>
                    <div className="noteTxt mb-3"><span>ℹ️</span> The bonus expires if you do not withdraw it before the next one is accrued.</div>
                    <div className="divider"></div>
                </div>
                <div className="col-12">
                    <div className="headingBold mt-3 mb-2">Withdrawal distribution:</div>
                    <div className="headingBold mb-2">Rank 1-5:</div>
                    <div className="textBold mb-2">50% of the amount goes to your balance 50% of the amount goes to the calendar for 3 days</div>
                    <div className="headingBold mb-2">Rank 6-10:</div>
                    <div className="textBold mb-3">75% of the amount goes to your balance 25% of the amount goes to the calendar for 3 days</div>
                    <div className="noteTxtBorder mb-3">
                        <div className="vip">For VIP users:</div>
                        <div className="balance">100% to your balance</div>
                    </div>
                    <div className="text mb-3">1- The minimum amount for distribution to the 
                        calendar is $0.003 per day. The smaller amount is
                        credited to the balance in full.
                    </div>
                    <div className="headingBold mb-1">What is the bonus calculated for?</div>
                    <div className="headingBold mb-3">- Casino bets <span className='text-white'>(Slots, {APP_NAME} Originals)</span></div>
                    <div className="divider"></div>
                </div>
                <div className="col-12 mt-3">
                    <div className="rankBox">
                        <div className="row mx-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover active">Rank 1 : <span className='text-white'> 5%</span></div>
                                <div className="headingBold headingBoldHover">Rank 11 : <span className='text-white'> 12%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 2 : <span className='text-white'> 5%</span></div>
                                <div className="headingBold headingBoldHover">Rank 12 : <span className='text-white'> 14%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 3 : <span className='text-white'> 5%</span></div>
                                <div className="headingBold headingBoldHover">Rank 13 : <span className='text-white'> 16%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 4 : <span className='text-white'> 5%</span></div>
                                <div className="headingBold headingBoldHover">Rank 14 : <span className='text-white'> 18%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 5 : <span className='text-white'> 5%</span></div>
                                <div className="headingBold headingBoldHover">Rank 15 : <span className='text-white'> 20%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 6 : <span className='text-white'> 6%</span></div>
                                <div className="headingBold headingBoldHover">Rank 16 : <span className='text-white'> 21%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 7 : <span className='text-white'> 7%</span></div>
                                <div className="headingBold headingBoldHover">Rank 17 : <span className='text-white'> 22%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 8 : <span className='text-white'> 8%</span></div>
                                <div className="headingBold headingBoldHover">Rank 18 : <span className='text-white'> 23%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 9 : <span className='text-white'> 9%</span></div>
                                <div className="headingBold headingBoldHover">Rank 19 : <span className='text-white'> 24%</span></div>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="headingBold headingBoldHover">Rank 10 : <span className='text-white'> 10%</span></div>
                                <div className="headingBold headingBoldHover">Rank 20 : <span className='text-white'> 25%</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CashbackBonusDetails