import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSpin } from '../../../hooks/useSpin';
import { AUTH_MODAL_TABS, SPIN_MODAL_OPTIONS, SPIN_STRING, SPIN_TOKEN } from '../../../constants';
import { useSearchParams } from 'react-router-dom';
import { useAuthModal } from '../../../hooks/useAuthModal';

const SpinWheelWinnerModal = () => {
  const { spinObject, setSpinObject } = useSpin()
  const { setAuthModalObject } = useAuthModal();
  // const [searchParams, setSearchParams] = useSearchParams();

  const claimBonus = () => {
    const isTrue = localStorage.getItem(SPIN_TOKEN)

    const encodedSN = btoa(JSON.stringify({ bonus: true }))
    if (!!isTrue) {
        // setSearchParams({ bonus: encodedSN })
        localStorage.setItem(SPIN_STRING, encodedSN)
        setSpinObject({
          selectedModal : SPIN_MODAL_OPTIONS.SPIN_MODAL,
          isSpinOpen: false,
      })
        setAuthModalObject(pre => ({
          selectedTab: AUTH_MODAL_TABS.SIGN_UP,
          isAuthOpen: true
        }))
    }
}


  return (
    <>
      <Modal className='spinWinnerModal' show={spinObject.isSpinOpen} centered>
        <Modal.Body className='d-flex align-items-center justify-content-center'>
            <div className="winnerInnerBox">
              <div className="row">
                <div className="col-12 congTxt">Congratulations!</div>
                <div className="col-12 congMsg">You’ve got free money to bet on our games!</div>
                <div className="col-12 congPrz">100 KSH</div>
                <div className="col-12">
                  <button className='claimBtn' onClick={claimBonus}>Claim rewards</button>
                </div>
              </div>
              <img className='coinGroupImage' src="assets/img/coinGroup1.png" alt="" />
              <img className='coinGroupImage2' src="assets/img/coinGroup2.png" alt="" />
            </div>
            <div className="winnerBackImg">
              <img src="assets/img/spinBackgroundImage.png" className='w-100 h-100 object-fit-contain' alt="" />
            </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SpinWheelWinnerModal