import React, { useEffect, useState } from 'react'
import { useWalletModal } from '../../hooks/useWallet'
import Deposit from './components/Deposit'
import Withdraw from './components/Withdraw'
import BuyCrypto from './components/BuyCrypto'
import { KADO_WALLET_TABS, WALLET_MODAL_TABS } from '../../constants'
import { Link, useSearchParams } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import { useTranslation } from 'react-i18next'
import { useKado } from '../../hooks/useKado'
import RublePay from './components/RublePay'
import Fiat from './components/Fiat'
import Lottery from '../../pages/dgnToken/Lottery'
import { postAPIAuth } from '../../service/apiInstance'
import { errorToaster, succesToaster } from '../../utils/toaster'
import { Spinner } from 'react-bootstrap'

const NewWallet = () => {
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const { t } = useTranslation()
  const walletLabels = t("wallet", { returnObjects: true })
  const { setKadoWalletObject } = useKado()
  const [query, setQuery] = useSearchParams()

  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)
  const [withdrawError, setWithdrawError] = useState('')


  useEffect(() => {
    window?.dataLayer?.push({
      'event': 'GA4_event',
      'event_name': 'game_click_dep'
    });
  }, [])

  const handleWithdraw = async ()=> {
    if(!amount?.length) {
      setWithdrawError('Enter valid deposit amount')
      return
    } else {
      setWithdrawError('')
        try {
          setLoading(true)
          const payload = {
            amount : Number(amount)
          }
          const res = await postAPIAuth('paymentProcessRequest', payload)
          if(res?.data?.success) {
            if(res?.data?.data?.ResponseDescription) {
              succesToaster(res?.data?.data?.ResponseDescription)
            } else if (res?.data?.data?.errorMessage) {
              errorToaster(res?.data?.data?.errorMessage)
              setWithdrawError(res?.data?.data?.errorMessage)
            }
          }
        } catch (error) {
          // console.log('errrrrrrrrrrrrrrrrrrrrrrrrrrr', error)
          // errorToaster(error?.message)
        } finally {
          setLoading(false)
          setAmount('')
          
          setWalletModalObject((prev) => ({
            isWalletOpen: false,
          }))
          setQuery({ action: "" })
        }
    }
  }
  return (
    <>
      <div className={`modal fade bankModal ${walletModalObject?.isWalletOpen ? 'show d-block' : ''}`} id="bankModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog bankdialog modal-dialog-centered modal-dialog-scrollable"
      
        >
          {/* <div className="modal-content bankContent"
            style={{
              height:"90vh"
            }}
          >
            {
              walletModalObject?.selectedTab !== WALLET_MODAL_TABS?.BUY_CRYPTO || walletModalObject?.selectedTab !== WALLET_MODAL_TABS?.BUY_RAMPER ?
                <>
                  <div className="modal-header bankHeader border-0 flex-wrap">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="title">{WALLET_MODAL_TABS.FIAT === walletModalObject?.selectedTab ? "Fiat" :"Crypto" }</div>
                      <div className="ps-3">
                        <button type="button"
                          onClick={() => {
                            setWalletModalObject((prev) => ({
                              isWalletOpen: false,
                            }))
                            setQuery({ action: "" })
                          }
                          }
                          className="btn-close p-0 shadow-none border-0 d-flex align-items-center justify-content-center">
                          <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                        </button>
                      </div>
                    </div>
                    <nav className="overflow-x-auto py-1 w-100">
                      <div className="nav nav-tabs border-0 flex-nowrap text-nowrap bankTab">
                        <button
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.DEPOSIT
                          }))}
                          className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.DEPOSIT ? 'active' : ''}`}
                        >{walletLabels.Deposit}</button>

                        <button
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.FIAT
                          }))}
                          className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.FIAT ? 'active' : ''}`}
                        >Fiat</button>
                        <button
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.BUY_RAMPER
                          }))}
                          className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_RAMPER ? 'active' : ''}`}
                        >Buy Crypto</button>
                      </div>
                    </nav>
                  </div>
                </>
                : ''
            }
            <div className={`modal-body bankbody pb-4 ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_CRYPTO ? 'showBuyCrypto' : ''} ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.RUBLE_PAY ? 'rubleOpen' : ''}`}>
              <div className={`tab-content banktabContent ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_RAMPER ? 'h-100' : ''}`}>
                {walletModalObject?.selectedTab === WALLET_MODAL_TABS?.DEPOSIT ?
                  <Deposit /> :
                  walletModalObject?.selectedTab === WALLET_MODAL_TABS?.WITHDRAW ?
                    <Withdraw /> :
                    walletModalObject?.selectedTab === WALLET_MODAL_TABS?.RUBLE_PAY ?
                      <RublePay />
                      :
                      walletModalObject?.selectedTab === WALLET_MODAL_TABS?.FIAT ?
                        <Fiat /> : 
                      walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_RAMPER ? <>
                        <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="comingSoon" style={{color: '#EFB90B', fontSize: '24px'}}>Comming Soon</div>
                        </div>
                      </> : ''
                }
              </div>
            </div>
          </div> */}

          <div className="modal-content bankContent">
          <div className="modal-header bankHeader border-0 flex-wrap">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="title">Deposit</div>
                      <div className="ps-3">
                        <button type="button"
                          onClick={() => {
                            setWalletModalObject((prev) => ({
                              isWalletOpen: false,
                            }))
                            setQuery({ action: "" })
                          }
                          }
                          className="btn-close p-0 shadow-none border-0 d-flex align-items-center justify-content-center">
                          <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body bankbody newDepositUi">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          <input 
                            type="text" 
                            placeholder='Enter amount' 
                            className="form-control shadow-none"
                            value={amount}
                            onChange={(e)=>{
                              if (!isNaN(Number(e.target.value))) {
                                setAmount(e.target.value)
                                setWithdrawError('')
                            }
                            }}
                          />
                        </div>
                        <div className="col-12">
                          {withdrawError ? <div className="text-danger errorTxt mt-1" style={{fontSize: 14}}>{withdrawError}</div> : ''}
                        </div>
                        <div className="col-auto mt-3">
                          <button onClick={handleWithdraw} className='depBtn'>
                            {
                              loading ? <>
                                <Spinner size="sm" color="dark" />
                              </> : 'Deposit'
                            }
                            
                          </button>
                        </div>
                      </div>
                  </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewWallet