import React from "react";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "../context/authContext";
import ModalContextProvider from "../context/registerModalContext";
import { ToastContainer } from "react-toastify";
import WalletModalContextProvider from "../context/walletModalContext";
import { Provider as ReduxProvider } from "react-redux";
import store from "../store";
import AuthModalContextProvider from "../context/AuthModalContext";
import DownloadModalContextProvider from "../context/downloadModalContext";
import LogoutModalContextProvider from "../context/LogoutContext";
import ReferralModalContextProvider from "../context/ReferralContext";
import GameContextProvider from "../context/gameContext";
import ResultModalContextProvider, { ResultModalContext } from "../context/resultModalContext";
import KadoWalletContextProvider from "../context/KadoWalletContext";
import AuthBtContextProvider from "../context/authBtContext";
import { MetaMaskProvider } from '@metamask/sdk-react';
import SpinContextProvider from "../context/spinWheelContext";
import NotificationProvider from './../context/notificationContext'
import BonusContextProvider from "../context/bonusContext";
import SpinModalContextProvider from "../context/freeSpinModalContext";
import ClaimBonusContextProvider from "../context/ClaimBonusContext";
import SubscribeModalCOntextProvider from "../context/subscribeModalContext";
import SidebarContextProvider from "../context/sidebarContext";
const Providers = ({ children }) => {
  return (
    <BrowserRouter basename="/">
      <ReduxProvider store={store}>
        <ReferralModalContextProvider>
          <AuthBtContextProvider>
            {/* <MetaMaskProvider
              debug={false}
              sdkOptions={{
                logging: {
                  developerMode: false,
                },
                // communicationServerUrl: process.env.REACT_APP_COMM_SERVER_URL,
                checkInstallationImmediately: false, // This will automatically connect to MetaMask on page load
                dappMetadata: {
                  name: "Demo React App",
                  url: window.location.host,
                }
              }}> */}
              <AuthModalContextProvider>
                <LogoutModalContextProvider>
                  <AuthContextProvider>
                    <ModalContextProvider>
                      <WalletModalContextProvider>
                        <DownloadModalContextProvider>
                          <GameContextProvider>
                            <ResultModalContextProvider>
                              <KadoWalletContextProvider>
                                <SpinContextProvider>
                                  <NotificationProvider>
                                    <BonusContextProvider>
                                      <SpinModalContextProvider>
                                        <ClaimBonusContextProvider>
                                        <SubscribeModalCOntextProvider>
                                          <SidebarContextProvider>
                                            {children}
                                          </SidebarContextProvider>
                                        </SubscribeModalCOntextProvider>
                                        </ClaimBonusContextProvider>
                                      </SpinModalContextProvider>
                                    </BonusContextProvider>
                                  </NotificationProvider>
                                </SpinContextProvider>
                              </KadoWalletContextProvider>
                            </ResultModalContextProvider>
                          </GameContextProvider>
                        </DownloadModalContextProvider>
                      </WalletModalContextProvider>
                      <ToastContainer />
                    </ModalContextProvider>
                  </AuthContextProvider>
                </LogoutModalContextProvider>
              </AuthModalContextProvider>
            {/* </MetaMaskProvider> */}
          </AuthBtContextProvider>
        </ReferralModalContextProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default Providers;
