import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import bonusIcon from './../../../assets/img/modalIcon.png'
import { getAPIAuth } from '../../../service/apiInstance';
import moment from 'moment';
import { useAuth } from '../../../hooks/useAuth';
import { useSelector } from 'react-redux';

const UsdtBonusHistory = ({showBonusHistoryModal, setShowBonusHistoryModal}) => {
  const handleClose = () => setShowBonusHistoryModal(false);
  const [historyData, setHistoryData] = useState([])
  const {token} = useAuth()
  const userBalance = useSelector(state => state.userBalance);


  const getBonusHistory = async ()=> {
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
    try {
        const res = await getAPIAuth(`user/get-bonus-history?type=lockedBonus`, token)
        if(res?.data?.status) {
            setHistoryData(res?.data?.data)
        } else {
            setHistoryData({})
        }
    } catch (error) {
        setHistoryData({})
    }
}

useEffect(()=>{
    if(token && showBonusHistoryModal) {
      getBonusHistory()
    }
},[token, showBonusHistoryModal])

  return (
    <Modal className='usdtBonusModal' show={showBonusHistoryModal} centered scrollable onHide={handleClose}>
    <Modal.Header>
      <div className="row mx-0 w-100 align-items-center">
        <div className="col modalTitle ps-0">Bonus history</div>
        <div className="col-auto pe-0">
          <img onClick={handleClose} className='modalCloseBtn' src="assets/img/closeWhite.png" alt="" />
        </div>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="table-responsive">
        <table className='table bonusHistoryTable'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {
              historyData?.length ? 
                historyData?.map((item)=>(
                  <tr>
                    <td className='dateTd'>
                      <div>{moment(item?.createdAt).format('h:mm:ss A')}</div>
                      <div>{moment(item?.createdAt).format('L')}</div>
                    </td>
                    <td className='typeTd'>{item?.type}</td>
                    <td className='amountTd'>
                      <div className="d-flex align-items-center justify-content-end">
                        <img src="assets/img/usdt.png" className='me-2' alt="" />
                        +{item?.bonus_amt}{userBalance?.setCurrencyData ? userBalance?.setCurrencyData?.symbol ?.toUpperCase(): ''} 
                      </div>
                    </td>
                  </tr>
                ))
              : <tr className="">
                <td></td>
                <td className='typeTd text-center py-3'>No Data Found</td>
                <td></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default UsdtBonusHistory