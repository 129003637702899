import React from "react";
import { Routes, Route } from "react-router-dom";
import { ROUTES_CONST } from "../constants/routeConstant";
import Sports from "../pages/sportsPage";
import Promotion from "../pages/promotions";
import OurPartners from "../pages/ourPartners";
import AboutPartnerPage from "../pages/AboutPartnerPage";
import VipClub from "../pages/VipClub";
import Casino from "../pages/casinoPage";
// import SlotPage from "../pages/SlotPage";
import PlaySlot from "../pages/playSlot";
import TermsAndConditions from "../pages/termsAndConditions";
import ResponsibleGamble from "../pages/gamingWithResponsibility";
import NewAmlPolicy from "../pages/newAmlPolicy";
import DownloadApp from "../pages/DownloadApp/Index";
import Protected from "./protected/Protected";
import Favourite from "../pages/favourite";
import RecentGames from "../pages/recent";
import AffilliatePage from "../pages/affilliatePage";
import SportsPolicy from "../pages/sportsPolicy";
import { Spinner } from "react-bootstrap";
import GameProviders from "../pages/gameProviders";
import PromotionDetail from "../pages/promotionDetailPage";
import AccountPage from "../pages/accountPage";
import FaqPage from "../pages/faqPage/FaqPage";
import GamePage from "../pages/gamePage";
import ProviderInnerPage from "../pages/gameProviders/ProviderInnerPage";
// import DgnToken from "../pages/dgnToken/DgnToken";
// import DgwStaking from "../pages/dgnToken/DgwStaking";
import Lottery from "../pages/dgnToken/Lottery";
import GameErrorPage from "../pages/gameErrorPage/GameErrorPage";
import PrivacyPolicy from "../pages/privacyPolicy";
import CommingSoon from "../pages/commingSoon/CommingSoon";
// import Poker from "../pages/poker";
// import PokerDetail from "../pages/pokerDetailPage";
// import CryptoStacking from "../pages/cryptoStacking/CryptoStacking";
// import AboutDgwToken from "../pages/cryptoStacking/AboutDgwToken";
// import AboutStacking from "../pages/cryptoStacking/AboutStacking";
// import DGWStacking from "../pages/cryptoStacking/DGWStacking";

const RoutesComp = ({ socket }) => {
  return (
    <>
      <Routes
        fallbackElement={<Spinner color="red" />}
      >
        <Route path={ROUTES_CONST.INDEX} element={<Casino socket={socket} />} />
        {/* <Route path={ROUTES_CONST.CASINO} element={<Casino socket={socket} />} /> */}
        <Route path={ROUTES_CONST.SPORTS} element={<CommingSoon />} />
        {/* <Route path={ROUTES_CONST.SPORTS} element={<Sports />} /> */}
        <Route path={ROUTES_CONST.ACCOUNT} element={<Protected Component={AccountPage} />} />
        <Route path={ROUTES_CONST.PROMOTIONS} element={<Promotion />} />
        <Route path={ROUTES_CONST.SPONSERSHIP_PAGE} element={<OurPartners />} />
        <Route path={`${ROUTES_CONST.SPONSERSHIP_PAGE}/:id`} element={<AboutPartnerPage />} />
        <Route path={ROUTES_CONST.VIP_CLUB} element={<Protected Component={VipClub} />} />
        {/* <Route path={ROUTES_CONST.SLOT_PAGE} element={<SlotPage />} /> */}
        <Route path={`${ROUTES_CONST.FAVOURITE_PAGE}`} element={<Protected Component={Favourite} />} />
        <Route path={`${ROUTES_CONST.Recent_Games}`} element={<Protected Component={RecentGames} />} />
        <Route path={`${ROUTES_CONST.SLOT_PAGE}/:gameId`} element={<Protected Component={PlaySlot} />} />
        <Route path={ROUTES_CONST.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
        <Route path={ROUTES_CONST.RESPONSIBLE_GAMBLING} element={<ResponsibleGamble />} />
        <Route path={ROUTES_CONST.NEW_AML_POLICY} element={<NewAmlPolicy />} />
        <Route path={ROUTES_CONST.DOWNLOAD_APP} element={<DownloadApp />} />
        <Route path={ROUTES_CONST.AFFILIATE_PAGE} element={<AffilliatePage />} />
        <Route path={ROUTES_CONST.SPORTS_POLICY} element={<SportsPolicy />} />
        <Route path={ROUTES_CONST.PROVIDERS} element={<GameProviders />} />
        <Route path={`${ROUTES_CONST.PROVIDERS}/:providerName`} element={<ProviderInnerPage />} />
        <Route path={`${ROUTES_CONST.PROMOTIONS}/:promotionId`} element={<PromotionDetail />} />
        <Route path={ROUTES_CONST.FAQ_PAGE} element={<FaqPage />} />
        <Route path={ROUTES_CONST.GAME_PAGE} element={<GamePage />} />
        {/* <Route path={ROUTES_CONST.DGN_TOKEN} element={<DgnToken />} /> */}
        {/* <Route path={ROUTES_CONST.DGW_STAKING} element={<DgwStaking />} /> */}
        <Route path={ROUTES_CONST.LOTTERY} element={<Lottery />} />
        <Route path={ROUTES_CONST.GAME_ERROR} element={<GameErrorPage />} />
        <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />

        {/* <Route path={ROUTES_CONST.POKER} element={<Poker/>} /> */}

        {/*uncomment this in dev */}
        {/* <Route path={ROUTES_CONST.POKER} element={<PokerDetail />} />  */}
        {/* <Route path={ROUTES_CONST.CRYPTO_STACKING} element={<Lottery />} /> */}
        {/* <Route path={ROUTES_CONST.CRYPTO_STACKING} element={<CryptoStacking />} /> */}
        {/* <Route path={'/aboutDgwToken'} element={<AboutDgwToken />} /> */}
        {/* <Route path={'/aboutStacking'} element={<AboutStacking />} /> */}
        {/* <Route path={'/dgwStacking'} element={<DGWStacking />} /> */}


        
        {/* comment this in dev */}
        {/* <Route path={ROUTES_CONST.POKER} element={<Lottery />} />  */}

        {/* <Route path={`${ROUTES_CONST.POKER}/:pokerId`} element={<PokerDetail/>} /> */}



      </Routes>
    </>
  );
};

export default RoutesComp;
