export const reducerConst = {
  USER_DETAILS: "USER_DETAILS",
  FAV_CRYPTO: "FAV_CRYPTO",
  FAV_FIAT: "FAV_FIAT",
  Fav_LOADING: "Fav_LOADING",
  NEWS_CATEGORIES: "NEWS_CATEGORIES",
  PROFILE: "PROFILE",
  WALLET: "WALLET",
  WITHDRAW: "WITHDRAW",
  TRANSACTIONS: "TRANSACTIONS",
  SETTING: "SETTING",
  DEPOSITS: "DEPOSITS",
  AFFILIATE: "AFFILIATE",
  CRYPTO_LIST: "CRYPTO_LIST",
  IP_ADDRESS: "IP_ADDRESS",
  COUNTRY_NAME: "COUNTRY_NAME",
  BT_RENDERER: "BT_RENDERER",
  BT_URL: "BT_URL",
  BUY_CURRENCY: "BUY_CURRENCY",
  BUY_NETWORK: "BUY_NETWORK",
  BUY_ASSET: "BUY_ASSET",
  SELL_CURRENCY: "SELL_CURRENCY",
  SELL_NETWORK: "SELL_NETWORK",
  SELL_ASSET: "SELL_ASSET",
  SWAP_NETWORK_FROM: "SWAP_NETWORK_FROM",
  SWAP_ASSET_FROM: "SWAP_ASSET_FROM",
  SWAP_NETWORK_TO: "SWAP_NETWORK_TO",
  SWAP_ASSET_TO: "SWAP_ASSET_TO",
  CURRENCY_DATA: "CURRENCY_DATA",
  NETWORK_DATA: "NETWORK_DATA",
  ASSET_DATA: "ASSET_DATA",
  METAMASK_ACC: "METAMASK_ACC",
  METAMASK_ACC_BUY: "METAMASK_ACC_BUY",
  METAMASK_ACC_SELL: "METAMASK_ACC_SELL",
  WALLET_ACC_BUY:"WALLET_ACC_BUY",
  WALLET_ACC_SELL:"WALLET_ACC_SELL",
  USER_BALANCE:"USER_BALANCE",
  USER_BONUS_DETAIL: "USER_BONUS_DETAIL",
  SPORT_BOOK: "SPORT_BOOK",
  TOP_RATED_GAMES: "TOP_RATED_GAMES",
  CATEGORY_PILL_OPTIONS: "CATEGORY_PILL_OPTIONS",
  SELECTED_CRYPTO: "SELECTED_CRYPTO",
  SELECTED_FIAT: "SELECTED_FIAT",
  RUBLE_AMOUNT: "RUBLE_AMOUNT",

};

export const sagaConst = {
  FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
};
