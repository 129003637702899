import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { DOMAIN_NAME } from '../../constants'
import { Link } from 'react-router-dom'

const TermsAndConditions = () => {
  return (
    <>
      <main className='main vh-100' id='main'>
        <div className="mainContaint">
          <div className="policypage">
                        <div className="row policymain">
                            <div className="col-12 ">
                                <div className="policyHeading">
                                    Terms and Conditions 
                                </div>
                            </div>
                            <div className="col-12 pt-sm-3">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                        Introduction
                                    </div>
                                    <div className="col-12 policyDetail">Welcome to Shinda Ki Mfalme ("we", "our", or "us"). These Terms and Conditions ("Terms")
govern your use of our website, www.shindakimfalme.com ("Website"), and all associated
services provided by Shinda Ki Mfalme (collectively, "Services"). By accessing or using our
Website and Services, you agree to be bound by these Terms. If you do not agree to these Terms,
please do not use our Website or Services.</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    1. Eligibility
                                    </div>
                                    <div className="col-12 policyDetail ps-4">1.1 You must be at least 18 years old to use our Services.</div>
                                    <div className="col-12 policyDetail ps-4">1.2 You must be legally capable of entering into a binding contract under Kenyan law.</div>
                                    <div className="col-12 policyDetail ps-4">1.3 By using our Services, you represent and warrant that you meet all eligibility requirements.</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    2. Registration and Account Security
                                    </div>
                                    <div className="col-12 policyDetail ps-4">2.1 To participate in betting, you must create an account on our Website.</div>
                                    <div className="col-12 policyDetail ps-4">
                                    2.2 You are responsible for maintaining the confidentiality of your account information,
                                    including your password.</div>
                                    <div className="col-12 policyDetail ps-4">2.3 You agree to notify us immediately of any unauthorized use of your account</div>
                                    <div className="col-12 policyDetail ps-4">
                                    2.4 You are responsible for all activities that occur under your account.</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    3. Betting Rules
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">3.1 All bets must be placed through our Website</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">3.2 Bets are only accepted if placed in accordance with our betting rules and guidelines available
                                    on the Website</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">3.3 We reserve the right to refuse or limit any bet, for any reason.</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">3.4 Bets once placed cannot be cancelled or altered.
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    4. Payments and Withdrawals
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">4.1 All deposits must be made through the payment methods available on the Website</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">4.2 Withdrawals are subject to verification procedures and may take up to 72 hours to process.</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">4.3 We reserve the right to refuse or withhold payments and withdrawals if we suspect fraudulent
                                    activity or breach of these Terms.</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    5. Responsible Gambling
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">5.1 We are committed to promoting responsible gambling.</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">5.2 We provide tools and resources to help you manage your gambling, including self-exclusion
                                    options and deposit limits.</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">5.3 If you believe you have a gambling problem, we encourage you to seek help from
                                    professional organizations.</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    6. Intellectual Property
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">6.1 All content on our Website, including text, graphics, logos, and software, is the property of
                                    Shinda Ki Mfalme or its licensors and is protected by intellectual property laws.</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">6.2 You may not use any content from our Website without our prior written consent.</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    7. Privacy
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">7.1 We are committed to protecting your privacy. Our Privacy Policy, available on our Website,
                                    explains how we collect, use, and protect your personal information</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    8. Limitation of Liability
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">8.1 To the fullest extent permitted by law, Shinda Ki Mfalme shall not be liable for any direct,
                                        indirect, incidental, special, or consequential damages arising out of or in connection with your
                                        use of our Services.</div>
                                        <div className="col-12 policyDetail pb-1 ps-4">8.2 We do not guarantee that our Website will be uninterrupted or error-free.
                                        </div>
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    9. Indemnification
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">9.1 You agree to indemnify and hold harmless Shinda Ki Mfalme, its directors, officers,
                                    employees, and agents from any claims, damages, liabilities, costs, and expenses arising out of
                                    your use of our Services or breach of these Terms.</div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    10. Governing Law
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">10.1 These Terms shall be governed by and construed in accordance with the laws of Kenya.</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">10.2 Any disputes arising out of or in connection with these Terms shall be subject to the
                                    exclusive jurisdiction of the courts of Kenya.</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    11. Compliance with BCLB Regulations
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">11.1 Shinda Ki Mfalme is fully licensed and regulated by the Betting Control and Licensing
                                    Board (BCLB) of Kenya.</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">11.2 By using our Services, you agree to comply with all BCLB regulations and guidelines.</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row policyInner">
                                    <div className="col-12 policysubHeading">
                                    12. Changes to Terms
                                    </div>
                                    <div className="col-12 policyDetail pb-1 ps-4">12.1 We may revise these Terms from time to time. The most current version will always be
                                    posted on our Website.</div>
                                    <div className="col-12 policyDetail pb-1 ps-4">12.2 By continuing to use our Services after any changes to these Terms, you agree to be bound
by the revised Terms.
</div>
                                </div>
                            </div>
                            <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">13. Contact Information
                                         </div>
                                        <div className="col-12 policyDetail">If you have any questions about these Terms, please contact us at:</div>
                                        <div className="col-12 policyDetail">
                                            Email: <Link className='policyLink' to={'mailto:support@shindakimfalme.com'}>support@shindakimfalme.com</Link>
                                        </div>
                                    </div>
                                </div>





                            </div>
                    </div>
   </div>
   <AppFooter/>
   </main>
   </>
  )
}

export default TermsAndConditions