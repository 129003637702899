import { Formik } from 'formik';
import React, { useState } from 'react'
import * as Yup from "yup";
import ErrorMessage from '../../../common/ErrorMessage/ErrorMessage';
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import { useAuth } from '../../../../hooks/useAuth';
import { useAuthModal } from '../../../../hooks/useAuthModal';
import { useSelector } from 'react-redux';
import { useBtAuth } from '../../../../hooks/useBtAuth';
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../config/firebase';
import { USER_LANG } from '../../../../constants';
import { postAPI } from '../../../../service/apiInstance';
import { Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object({
  password: Yup.string().required("This field is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      "Invalid email"
    ),
});

const validationForgotPassword = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      "Invalid email"
    ),
});

const SignInEmail = ({
  browserName,
  isForgotPassword,
  setIsForgotPassword,
}) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)
  const { setToken } = useAuth();
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState({
    value: null,
    timestamp: null,
  });
  const { setBtToken } = useBtAuth()
  const [query, setQuery] = useSearchParams()
  const { t } = useTranslation()
  const authLabels = t("AuthLabels", { returnObjects: true }) //string has to be same as language json key 

  async function signInWEAP(values) {
    setIsLoading(true)
    const currentTime = new Date().getTime();
    // const { value, timestamp } = recaptchaResponse;
    // if (value && currentTime - timestamp < 5 * 60 * 1000) {

    try {
      if (values.email) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );

        // Signed in
        const lang = localStorage.getItem(USER_LANG);
        const body = {
          email: values.email,
          password: values.password,
          ipAddress: ipAddress ? ipAddress : "",
          country: country ? country : "",
          language: lang,
          browser: browserName,
          isLoginWithEmail: true
        };
        const user = userCredential.user.auth.email;
        const res = await postAPI("user/login-user", body);
        if (res?.data?.success) {
          setToken(res?.data?.data?.auth_token);
          succesToaster("Login successful");
          setBtToken(res?.data?.data?.betByToken)
          // setEmailPassword(false);
          // setLogOn(true);
          setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
          localStorage.removeItem('clickid')
          // setQuery()
          setQuery({ action: "" })

        } else {
          errorToaster(res?.data?.message);
        }
      }
    } catch (error) {
      errorToaster(error.message);
    } finally {
      setIsLoading(false);
    }
    // } else {
    //   // Display an error message or take appropriate action
    //   setIsLoading(false)
    //   errorToaster('Please verify that you are not a robot.');
    // }

  }


  const handleSendLink = async (values) => {
    try {
      const res = await sendPasswordResetEmail(auth, values.email);
      succesToaster('Link sent Succesfully!!')
    } catch (error) {
      errorToaster("Something went wrong")
      console.error("Error occurred during password reset:", error);
    } finally {
      // setIsLoading(false)
    }
  };

  return (
    <>
      {
        isForgotPassword ? (
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationForgotPassword}
            onSubmit={(values) => {
              // setIsLoading(true);
              // onSubmit(values);
              // signInWEAP(values)
              handleSendLink(values)
            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <form>
                {/* <div className='text-light mb-3'>Forgot Password</div> */}
                <div className="col-12 mt-3">
                  <input
                    className='inp'
                    type="email"
                    // placeholder='Enter Your Email'
                    placeholder={authLabels.Email}
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <ErrorMessage>
                  {errors.email && touched.email && errors.email}
                </ErrorMessage>
                <div className="col-12 mt-3">
                  <button
                    className='emailBtn active'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault()
                      handleSubmit()
                    }}
                  >
                    {/* Send Link */}
                    {authLabels.Send_Link}
                  </button>
                </div>
              </form>
            )}

          </Formik>
        ) : (
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // setIsLoading(true);
              // onSubmit(values);
              signInWEAP(values)

            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <form>
                <div className="col-12">
                  <input
                    className='inp'
                    type="email"
                    // placeholder='Email'
                    placeholder={authLabels.Email}
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <ErrorMessage>
                  {errors.email && touched.email && errors.email}
                </ErrorMessage>
                <div className="col-12 mt-2">
                  <div className="inpWrapper position-relative">
                    <input
                      className='inp'
                      // type="mail"
                      type={`${isPasswordHidden ? "password" : "text"}`}

                      // placeholder='Login Password'
                      placeholder={authLabels.Password}
                      name="password"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {
                      isPasswordHidden ? (
                        <span
                          onClick={() => setIsPasswordHidden(false)}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34444 4.41401C4.34444 2.6747 5.75918 1.25937 7.50037 1.25937C9.23972 1.25937 10.6544 2.67466 10.6544 4.41401C10.6544 6.15372 9.23972 7.56843 7.50037 7.56843C5.75915 7.56843 4.34444 6.15372 4.34444 4.41401ZM0.507401 4.11304C0.398575 4.30988 0.398575 4.51813 0.507401 4.71515C1.92027 7.25228 4.5996 8.82838 7.50037 8.82838C10.3992 8.82838 13.0786 7.25231 14.4915 4.71515C14.6022 4.51813 14.6022 4.30988 14.4915 4.11304C13.0786 1.57592 10.3993 0 7.50037 0C4.5996 0 1.92027 1.57592 0.507401 4.11304ZM7.50037 2.29322C6.32957 2.29322 5.37826 3.2445 5.37826 4.41401C5.37826 5.58349 6.32954 6.53479 7.50037 6.53479C8.6693 6.53479 9.62058 5.58352 9.62058 4.41401C9.62058 3.2445 8.6693 2.29322 7.50037 2.29322Z" fill="#B2B6C5" />
                          </svg>
                        </span>
                      ) : (
                        <span
                          onClick={() => setIsPasswordHidden(true)}
                        >
                          <svg stroke="currentColor" fill="#B2B6C5" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448zM248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69 64.11 64.11 0 0 0 53.49 53.49 2 2 0 0 0 1.69-3.39zm16-119.7L315.87 248a2 2 0 0 0 3.4-1.69 64.13 64.13 0 0 0-53.55-53.55 2 2 0 0 0-1.72 3.39z"></path><path d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 0 0-71.82 11.79 4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05 96 96 0 0 1 116 116 4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24 343.81 343.81 0 0 0 67.24-77.4zM256 352a96 96 0 0 1-93.3-118.63 4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0 0 72.64-11.55 4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352z"></path></svg>
                        </span>
                      )
                    }
                    {/* <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34444 4.41401C4.34444 2.6747 5.75918 1.25937 7.50037 1.25937C9.23972 1.25937 10.6544 2.67466 10.6544 4.41401C10.6544 6.15372 9.23972 7.56843 7.50037 7.56843C5.75915 7.56843 4.34444 6.15372 4.34444 4.41401ZM0.507401 4.11304C0.398575 4.30988 0.398575 4.51813 0.507401 4.71515C1.92027 7.25228 4.5996 8.82838 7.50037 8.82838C10.3992 8.82838 13.0786 7.25231 14.4915 4.71515C14.6022 4.51813 14.6022 4.30988 14.4915 4.11304C13.0786 1.57592 10.3993 0 7.50037 0C4.5996 0 1.92027 1.57592 0.507401 4.11304ZM7.50037 2.29322C6.32957 2.29322 5.37826 3.2445 5.37826 4.41401C5.37826 5.58349 6.32954 6.53479 7.50037 6.53479C8.6693 6.53479 9.62058 5.58352 9.62058 4.41401C9.62058 3.2445 8.6693 2.29322 7.50037 2.29322Z" fill="#B2B6C5" />
                  </svg>
                </span> */}
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <div
                    className='text-light cursor-pointer forgetPasswordTxt'
                    onClick={() => {
                      setIsForgotPassword(true)
                      setQuery({ action: "forgotPassword" })
                    }}
                  >
                    {authLabels.Forgot_your_password}
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <button
                    className='emailBtn active'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault()
                      handleSubmit()
                    }}

                  >{
                      isLoading ? <Spinner size="sm" color="dark" /> : authLabels.Sign_In
                    }</button>
                </div>
              </form>
            )}

          </Formik>
        )
      }

    </>
  )
}

export default SignInEmail