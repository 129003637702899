import React from "react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES_CONST } from "../../../constants/routeConstant";

const OurPartnerCard = ({ cardInfo, index, key }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const ourPartners = t("ourPartners", { returnObjects: true })

    return (
        <div className={`col-12 py-2 py-sm-3 py-md-5`} key={key}>
            <div className="sponsorCard">
                <div className="row">
                    <div className="col-md-7 col-12">
                        <div className="box">
                            <img
                                src={cardInfo?.image}
                                className="boxImg d-md-none d-block w-100 object-fit-contain mb-3"
                                alt="Pineapple"
                            />
                            <div className="sponsorName">
                                {cardInfo?.title}
                            </div>
                            <div className="discription">
                                {parse(
                                    cardInfo?.description
                                        ? cardInfo?.description
                                        : "No Data"
                                )}
                            </div>
                            <div className="bottom">
                                <div className="row align-items-center">
                                    <div className="col">
                                        {/* <div className="oneSection" >
                                            <a href={cardInfo?.instagram} target="_blank" >
                                                <img src="assets/img/sponsorship/instagram.png" alt="" />
                                            </a>
                                            <span>
                                                <img src="assets/img/sponsorship/one.png" alt="" />
                                            </span>
                                        </div> */}
                                    </div>
                                    <div
                                        onClick={() => {
                                            navigate(`${ROUTES_CONST.SPONSERSHIP_PAGE}/${cardInfo?._id}`)
                                        }}
                                        className="col-auto"
                                    >
                                        <button
                                            className="readMoreBtn"
                                        >
                                            {ourPartners.Read_more}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 d-md-block d-none position-relative">
                        <div className="sponsorImg">
                            <img src={cardInfo?.image} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OurPartnerCard;
